<template>
	<div class="row mx-0" :style="{'color' : getThisColor()}">
		<div class="col-4 d-flex">
			<div class="my-auto">
				<h6 v-if="!question || (question && !question.open_ended)" class="font-weight-bold my-1">{{term}}</h6>
				<h6
					v-if="term_data.comparison_data && (!question || (question && question.rating))"
					class="font-weight-bold my-1"
					v-bind:class="compareValues(term_data.avg, term_data.comparison_data.avg).class"
				>{{compareValues(term_data.avg, term_data.comparison_data.avg, 1).label}}</h6>
				<h6
					v-else-if="term_data.comparison_data && question && question.yes_no_question"
					class="font-weight-bold my-1"
					:style="{color: term_data.yes_no_count ? primary_color : '#c3c3c3'}"
				>{{compareValues(term_data.yes_no_percentage, term_data.comparison_data.yes_no_percentage).difference_in_percentage}}% change</h6>
			</div>
		</div>

		<!--Rating System-->
		<div v-if="!question || (question && question.rating)" class="col-8">
			<div v-if="term_data.comparison_data" class="d-flex">
				<div class="progress-bar dark mt-3">
					<div
						class="progress-bar--progress my-auto"
						:style="{'background-color': term_data.is_self ? primary_color_faded : secondary_color_faded, width: term_data.comparison_data.percentage + '%'}"
					></div>
				</div>
				<div class="ml-4 my-auto">
					<h5 class="my-auto" :style="{'color': term_data.is_self ? primary_color_faded : secondary_color_faded}">{{term_data.comparison_data.avg}}</h5>
				</div>
			</div>
			<div class="d-flex">
				<div class="progress-bar dark my-3">
					<div
						class="progress-bar--progress my-auto"
						:style="{'background-color': term_data.is_self ? primary_color : secondary_color, width: term_data.percentage + '%'}"
					></div>
				</div>
				<div class="ml-4 my-auto">
					<h5 class="my-auto" :style="{'color': term_data.is_self ? primary_color : secondary_color}">{{term_data.avg}}</h5>
				</div>
			</div>
		</div>
		<!--INLINE OPEN ENDED-->
		<div v-else-if="question.open_ended" class="col-12">
			<div v-if="term_data.comparison_data && term_data.comparison_data.answer_count" class="row px-0">
				<!--Comparison Report-->
				<div class="col-12 d-flex">
					<h6 class="my-auto font-weight-bold">{{term}} - </h6>
					<h6 class="my-auto font-weight-bold font-italic">{{__('reporting.feedback.previous_report')}}</h6>
				</div>
				<div class="col-12 my-2" v-for="answer in term_data.comparison_data.answers">
					<sub style="font-size: .9rem; word-wrap: break-word;">"{{answer}}"</sub>
				</div>
				<div class="col-12">
					<hr/>
				</div>

				<!--Current Report-->
				<div class="col-12 d-flex">
					<h6 class="my-auto font-weight-bold" :style="{color: primary_color}">{{term}} - </h6>
					<h6 class="my-auto font-weight-bold font-italic" :style="{color: primary_color}">{{__('reporting.feedback.current_report')}}</h6>
				</div>
				<div class="col-12 my-2" v-for="answer in term_data.answers">
					<sub style="font-size: .9rem; word-wrap: break-word;" :style="{color: primary_color}">"{{answer}}"</sub>
				</div>
				<div class="col-12">
					<hr/>
				</div>
			</div>

			<div v-else class="row px-0">
				<div class="col-12 d-flex">
					<h6 class="my-auto font-weight-bold">{{term}}</h6>
				</div>
				<div class="col-12 my-2" v-for="answer in term_data.answers">
					<sub style="font-size: .9rem; word-wrap: break-word;">"{{answer}}"</sub>
				</div>

				<div class="col-12">
					<hr/>
				</div>
			</div>
		</div>
		<!--YES NO ANSWERS-->
		<div v-else-if="question.yes_no_question && term_data.yes_no_count" class="col-8 d-flex">
			<div class="mr-4 mb-1 mt-auto">
				<div v-if="term_data.comparison_data" class="d-flex mb-4">
					<h6 class="mt-auto font-weight-bold" :style="{'color': secondary_color_faded}">{{__('place_holders.no')}}</h6>
				</div>
				<div class="d-flex">
					<h6 class="mt-auto font-weight-bold" :style="{'color': secondary_color}">{{__('place_holders.no')}}</h6>
				</div>
			</div>
			<div class="w-100 my-auto">
				<div v-if="term_data.comparison_data" class="d-flex">
					<div v-if="term_data.comparison_data.yes_no_percentage < 100" class="px-2 text-left  mb-3" :style="{width: -(term_data.comparison_data.yes_no_percentage - 100) + '%'}">
						<span :style="{color: secondary_color_faded}">{{-(term_data.comparison_data.yes_no_percentage - 100)}}%</span>
						<div class="progress-bar dark">
							<div class="progress-bar--progress" :style="{'background-color': secondary_color_faded, width: '100%'}"></div>
						</div>
					</div>
					<div v-if="term_data.comparison_data.yes_no_percentage > 0" class="px-2 text-right mb-3" :style="{width: term_data.comparison_data.yes_no_percentage + '%'}">
						<span :style="{color: primary_color_faded}">{{term_data.comparison_data.yes_no_percentage}}%</span>
						<div class="progress-bar dark">
							<div class="progress-bar--progress" :style="{'background-color': primary_color_faded, width: '100%'}"></div>
						</div>
					</div>
				</div>
				<div class="d-flex">
					<div v-if="term_data.yes_no_percentage < 100" class="px-2 text-left  mb-3" :style="{width: -(term_data.yes_no_percentage - 100) + '%'}">
						<span :style="{color: secondary_color}">{{-(term_data.yes_no_percentage - 100)}}%</span>
						<div class="progress-bar dark">
							<div class="progress-bar--progress" :style="{'background-color': secondary_color, width: '100%'}"></div>
						</div>
					</div>
					<div v-if="term_data.yes_no_percentage > 0" class="px-2 text-right mb-3" :style="{width: term_data.yes_no_percentage + '%'}">
						<span :style="{color: primary_color}">{{term_data.yes_no_percentage}}%</span>
						<div class="progress-bar dark">
							<div class="progress-bar--progress" :style="{'background-color': primary_color, width: '100%'}"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="ml-4 mb-1 mt-auto">
				<div v-if="term_data.comparison_data" class="d-flex mb-4">
					<h6 class="mt-auto font-weight-bold" :style="{'color': primary_color_faded}">{{__('place_holders.yes')}}</h6>
				</div>
				<div class="d-flex">
					<h6 class="mt-auto font-weight-bold" :style="{'color': primary_color}">{{__('place_holders.yes')}}</h6>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ParticipantsRespondentsBars',
		props: [
			'term',
			'term_data',
			'question',

			'primary_color',
			'secondary_color',

			'primary_color_faded',
			'secondary_color_faded',
		],
		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},
			compareValues(a, b, toFixed = null){
				const val = a - b;
				if (val > 0)
					return {
						class: 'text-success',
						different: true,
						difference_in_percentage: Math.round(val),
						label: '+' + (toFixed !== null ? val.toFixed(toFixed) : val)
					};
				else if (val < 0)
					return {
						class: 'text-danger',
						different: true,
						difference_in_percentage: Math.round(100 - val),
						label: (toFixed !== null ? val.toFixed(toFixed) : val)
					};
				else
					return {
						class: 'text-grey',
						different: false,
						difference_in_percentage: 0,
						label: (toFixed !== null ? (0).toFixed(toFixed) : '0')
					};
			},
			getThisColor(){
				if (!this.question)
					return this.term_data.answer_count ? '' : '#c3c3c3 !important';


				return this.term_data.answer_count || (this.question.open_ended && this.term_data.answers && this.term_data.answers.length) ||
					(this.question.yes_no_question && this.term_data.yes_no_count) ? '' : '#c3c3c3 !important';
			}
		}
	}
</script>