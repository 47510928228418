<template>
	<div class="reporting-page--section" data-section="leaderboard">
		<collapsable :title="__('translation.reporting_section_leaderboard')">
			<div v-if="loading" class="loaders--standard override"></div>

			<!--data-->
			<div class="row mt-4" v-else>

				<div class="col-12 my-2">
					<leaderboard-item-card
						v-for="(item, index) in data.list"
						:item="item"

						:key="index"

						:primary_color="primary_color"
						:secondary_color="secondary_color"
						:primary_color_faded="primary_color_faded"
						:secondary_color_faded="secondary_color_faded"
					></leaderboard-item-card>
				</div>
			</div>
		</collapsable>
		<hr v-if="!loading" />
	</div>
</template>

<script>
	import Collapsable from "./Collapsable";
	import LeaderboardItemCard from "./LeaderboardItemCard";
	export default {
		name: "Leaderboard",
		components: {LeaderboardItemCard, Collapsable},
		props: [
			'load_url',
			'token',

			'primary_color',
			'secondary_color',
			'primary_color_faded',
			'secondary_color_faded'
		],


		data(){
			return {
				loading: true,
				pre_loading: true,

				data: {},

			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			loadData(){
				let _this = this;

				_this.loading = true;

				axios.post(_this.load_url, {
					token: this.token,
					code: 'leaderboard'
				})
					.then(function (response){
						_this.loading = false;

						_this.data = response.data;
						//Parsing PDF DATA
						let pdf_data = {
							primary_color: _this.primary_color,
							secondary_color: _this.secondary_color,
							primary_color_faded: _this.primary_color_faded,
							secondary_color_faded: _this.secondary_color_faded,
							list: _this.data.list,
						};
						_this.$emit('loaded', pdf_data);
					})
					.catch(function (){
						//@todo
					})
			}
		},

		watch: {
			token() {
				this.$emit('unloaded');
				if (this.token !== null) {
					this.pre_loading = false;
					this.loading = false;
					this.loadData();
				}
				else {
					this.pre_loading = true;
					this.loading = true;
				}
			}
		}
	}
</script>