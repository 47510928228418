<template>
	<div class="row">
		<div class="col-12">
			<h5 class="text-primary text-center font-weight-bold font-italic">{{options[selected].value}}</h5>
		</div>

		<div class="col-12 mt-3">
			<vue-slider-component
				v-model="selected"
				:max="options.length - 1"
				:dotSize="20"

				@drag-end="pre_select"
				@callback="select"

				:lazy="true"
				:piecewise="true"

				:processStyle="{
					backgroundColor: '#f0612e',
  					backgroundImage: '-webkit-linear-gradient(left, #fdaa8d, #f0612e)',
					padding: '5px',
					top: '-1px'
				}"
				:sliderStyle="{
					position: 'relative',
					top: '-6px',
    				left: '-6px',
					width: '32px',
					height: '32px',
					border: '2px solid #e5e5e5'
				}"
				:piecewiseStyle="{
					backgroundColor: '#ccc',
					visibility: 'visible',
					left: '-1px',
					width: '2px',
					height: '10px',
				}"
				:piecewiseActiveStyle="{
					backgroundColor: '#fff'
				}"

				tooltip="none"
				width="280"
				height="8"
			></vue-slider-component>
		</div>
	</div>
</template>

<script>
	import VueSliderComponent from "vue-slider-component/example/src/vue-slider/vue2-slider";
	export default {
		name: "SliderRating",
		components: {VueSliderComponent},

		props: [
			'options',
			'answer'
		],

		data() {
			return {
				selected: 0,
				timer: false
			}
		},

		beforeMount(){
			this.selected = Math.floor(this.options.length / 2);
		},

		methods: {
			pre_select(){
				let _this = this;
				_this.timer = window.setTimeout(function (){
					_this.$emit('select', _this.options[_this.selected]);
				}, 500);
			},
			select(){
				window.clearTimeout(this.timer);
				this.$emit('select', this.options[this.selected]);
			},

			reset(){
				this.selected = Math.floor(this.options.length / 2);
				this.$nextTick(function () {
					if (this.answer){
						for (let i = 0; i < this.options.length ; i ++){
							if (this.options[i].number === this.answer.rating){
								this.selected = i;
								return;
							}
						}
					}
				});
			}
		}
	}
</script>