<template>
	<div class="container questionnaire_edit_table" v-on:mouseleave="mouseOverTable = null">
		<div class="row border-bottom questionnaire_edit_table--header">
			<div class="col-5">{{campaign_type_id === 1 || campaign_type_id === 2 ? __('translation.self_assessment') : __('translation.participant')}}</div>
			<div v-if="campaign_type_id !== 6" class="col-3" >{{__('translation.department')}}</div>
			<div v-if="campaign_type_id === 1 || campaign_type_id === 2" class="col-3">{{__('translation.respondents_completed')}}</div>
			<div v-else class="col-3"></div>
			<div class="col-1"><!--action--></div>
		</div>
		<div class="questionnaire_edit_table--body">
			<div
				class="row questionnaire_edit_table--body-row"
				v-for="(participant, index) in participants_data"
				v-bind:class="campaign_type_id !== 1 && campaign_type_id !== 2 ? 'no-hover' : ''"
				v-on:mouseover="mouseOverTable = index"
				v-on:click="selectRow(index)"
			>
				<div class="col-1 questionnaire_edit_table--cell d-flex justify-content-end">
					<img class="d-block my-auto" src="/svg/orange-tick.svg" alt="Tick" v-if="participant.answered">
				</div>
				<div class="col-4 questionnaire_edit_table--cell">
					<span class="d-block w-100 font-weight-bold">{{participant.user.full_name}}</span>
					<span class="">{{participant.user.email}}</span>
				</div>
				<div class="col-3 questionnaire_edit_table--cell my-auto">{{participant.user.department ? participant.user.department.name : ''}}</div>
				<div v-if="campaign_type_id === 1 || campaign_type_id === 2" class="col-3 questionnaire_edit_table--cell my-auto">{{participant.total_answered + ' ' + __('place_holders.of') + ' ' + participant.total}}</div>
				<div v-else class="col-3 questionnaire_edit_table--cell my-auto">
					<a href="javascript:void(0)" v-on:click="confirmDeleteParticipant(participant.user.id)" class="text-primary">Remove</a>
				</div>
				<!--360 and 180-->
				<div v-if="campaign_type_id === 1 || campaign_type_id === 2" class="col-1 questionnaire_edit_table--cell my-auto">
					<img v-if="mouseOverTable === index" src="/svg/chevron-active.svg" />
					<img v-else src="/svg/chevron-inactive.svg" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CampaignShowParticipantsTable",
		props: [
			'campaign_type_id',
			'participants',
			'show_url',
			'remove_url'
		],

		data(){
			return {
				mouseOverTable: null,

				participants_data: [],
			}
		},

		beforeMount(){
			let _participants = [], _respondents = [], _this = this;

			_this.participants.forEach(function (campaign_participant){
				if (campaign_participant.participant){
					let _participant = campaign_participant.participant;
					_participant.percentage_completed = campaign_participant.percentage_completed;
					_participants.push(campaign_participant.participant);
				}
				else {
					let _respondent = campaign_participant.respondent;
					_respondent.campaign_participant = campaign_participant;
					_respondents.push(_respondent);
				}
			});

			_participants.forEach(function (participant){
				let _answered = participant.percentage_completed >= 100, _total_answered = 0, _total = 0;

				_respondents.forEach(function (respondent){
					if (respondent.participant_id === participant.id){
						_total ++;

						if (respondent.campaign_participant.percentage_completed >= 100){
							_total_answered ++;
						}
					}
				});

				_this.participants_data.push({
					id: participant.id,
					user: participant.user,
					total: _total,
					answered: _answered,
					total_answered: _total_answered
				})
			});
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			selectRow(i){
				if (this.campaign_type_id === 1 || this.campaign_type_id === 2){
					window.location.href = this.show_url.replace('?', this.participants_data[i].id);
				}
			},

			confirmDeleteParticipant(id){
				let _this = this;
				window.confirm(_this.__('messages.confirm_delete_participant'), function(confirmed){
					if (!confirmed) return;

					//Lets make the update (delete) request
					_this.deleteParticipant(id);
				});
			},


			deleteParticipant(id){
				let _this = this;

				if (_this.deleting) {
					return;
				}

				_this.deleting = true;

				axios.delete(_this.remove_url.replace('?', id))
					.then(function (){
						window.location.reload();
					})
					.catch(function (error) {
						for (var i in error.response.data.errors) {
							if (error.response.data.errors.hasOwnProperty(i)) {
								toastr.error(error.response.data.errors[i][0]);
							}
						}
						_this.deleting = false;
					});
			}
		}
	}
</script>
