<template>
	<div class="container p-0">
		<csv-upload-modal
			id="csv-upload-participants-modal"
			prefix="participants"

			:fields="participants_csv_fields"
			:upload_url="participants_csv_upload_url"
			:submit_url="participants_submit_url"
			@submitted="submitted"
		></csv-upload-modal>

		<!--360 and 180-->
		<add-respondent-to-participant-modal
			id="add-respondent-modal"

			:create_url="participants_create_url"
			:load_participants_url="load_participants_url"
			:participants="participants"
			:relations="allTerminologies()"
			:selected_index="selected_participant"
			@created="addedRespondent"
		></add-respondent-to-participant-modal>

		<!--Just participants-->
		<add-just-participant-modal
			id="add-just-participant-modal"

			:create_url="single_participant_create_url"
			:load_participants_url="load_participants_url"
			:no_department="campaign.campaign_type_id === 6"
			:no_select="campaign.campaign_type_id === 6"
			@created="addedJustParticipant"
		></add-just-participant-modal>

		<!--If no participants-->
		<div v-if="!participants.length" class="row mt-5">
			<div class="col-12 mt-2 text-center">
				<button class="btn btn-outline btn-lg" v-on:click="openParticipantsUploadCSVModal()">
					<img src="/svg/upload-csv.svg" class="btn-img">
					<span>Upload CSV</span>
				</button>
			</div>
			<div class="col-12 text-center">
				<div class="mt-3 mx-auto d-flex" style="max-width: 265px">
					<div class="w-100 h-25 my-auto" style="border-bottom: 1px solid #b1b2c1"></div>
					<span style="color: #b1b2c1" class="px-3">or</span>
					<div class="w-100 h-25 my-auto" style="border-bottom: 1px solid #b1b2c1"></div>
				</div>
			</div>
			<div class="col-12 mt-3 mb-5 text-center">
				<button class="btn btn-outline btn-lg" v-if="campaign.campaign_type_id === 1 || campaign.campaign_type_id === 2" v-on:click="openAddRespondentModal()">
					<img src="/svg/profile.svg" class="btn-img">
					<span class="">{{__('buttons.add_participant')}}</span>
				</button>
				<button class="btn btn-outline btn-lg" v-else v-on:click="openAddJustParticipantModal()">
					<img src="/svg/profile.svg" class="btn-img">
					<span class="">{{__('buttons.add_participant')}}</span>
				</button>
			</div>
			<div class="col-12 mb-5 mx-auto" style="max-width: 225px;">
				<a :href="participants_csv_download_template_url" target="_blank" class="d-block btn btn-outline rounded text-primary px-0">{{__('buttons.download_template')}}</a>
                <a :href="participants_csv_download_guid_url" target="_blank" class="d-block mt-2 btn btn-outline rounded text-primary px-0">{{__('buttons.download_guide')}}</a>
            </div>
		</div>
		<!--Else-->
		<div v-else class="row mt-4">
			<!--Participant Respondent Table (for 360 and 180)-->
			<div v-if="campaign.campaign_type_id === 1 || campaign.campaign_type_id === 2" class="col-md-10 order-1 order-md-0">
				<div class="container">
					<div class="row">
						<div class="col-lg-4 pl-0">
							<ul class="list-group">
								<li
									class="list-group-item"
									v-for="(participant, index) in participants"
								>
									<a href="javascript:void(0)" v-on:click="selectParticipant(index)">
										<span v-bind:class="index === selected_participant ? 'text-primary' : ''">{{participant.full_name}}</span>
										<img v-if="index === selected_participant" src="/svg/chevron-active.svg" />
										<img v-else src="/svg/chevron-inactive.svg" />
									</a>
								</li>
							</ul>
						</div>
						<div class="col-lg-8" v-if="participants.length">
							<!--group of respondents by relationship-->
							<div class="" v-for="(terminology, term_index) in participants[selected_participant].terminologies">
								<ul class="list-group">
									<li class="list-group-item">
										<div>
											<span class="text-grey">{{getTerminology(terminology.id)}}</span>
										</div>
									</li>
									<li class="list-group-item d-inline-flex w-100 justify-content-between" v-for="(respondent,respondent_index) in terminology.respondents">
										<div class="w-100 d-block">
											<span class="d-block font-weight-bold">{{respondent.full_name}}</span>
											<small class="d-block">{{respondent.email}}</small>
										</div>
										<div class="w-100 d-block">
											<span>{{respondent.department_id ? respondent.department.name : __('place_holders.no_department')}}</span>
										</div>
										<div class="w-50 d-block">
											<a href="javascript:void(0)" class="text-primary" v-on:click="deleteRespondent(respondent.campaign_participant_id, participants[selected_participant].terminologies, term_index, respondent_index)">Remove</a>
										</div>
									</li>
									<li class="list-group-item"></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-else class="col-md-10">
				<div class="container">
					<div class="row">
						<div class="col-12" v-if="participants.length">
							<ul class="list-group">
								<li class="list-group-item d-inline-flex w-100 justify-content-between" v-for="(campaign_participant, participant_index) in participants">
									<div class="w-100 d-block">
										<span class="d-block font-weight-bold">{{campaign_participant.participant.user.full_name}}</span>
										<small class="d-block">{{campaign_participant.participant.user.email}}</small>
									</div>
									<div class="w-100 d-block">
										<span>{{campaign_participant.participant.user.department_id ? campaign_participant.participant.user.department.name : ''}}</span>
									</div>
									<div class="w-50 d-block">
										<a href="javascript:void(0)" class="text-primary" v-on:click="deleteParticipant(participant_index)">Remove</a>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>


			<div class="col-md-2 order-0 order-md-1 right-side-list">
				<a href="javascript:void(0)" v-on:click="openParticipantsUploadCSVModal()" class="right-side-list--item">{{__('buttons.upload_csv')}}</a>
				<!--Campaign 360 and 180-->
				<a href="javascript:void(0)" v-if="campaign.campaign_type_id === 1 || campaign.campaign_type_id === 2" v-on:click="openAddRespondentModal()" class="right-side-list--item">{{__('buttons.add_respondent')}}</a>
				<a href="javascript:void(0)" v-else v-on:click="openAddJustParticipantModal()" class="right-side-list--item">{{__('buttons.add_participant')}}</a>
				<a href="javascript:void(0)" v-if="campaign.campaign_type_id === 1 || campaign.campaign_type_id === 2" v-on:click="deleteParticipant()" class="right-side-list--item">{{__('buttons.remove_participant')}}</a>
				<hr  v-if="campaign.campaign_type_id === 1" />
				<div v-if="campaign.campaign_type_id === 1" class="d-block">
					<span class="right-side-list--item">{{__('translation.how_many_respondents')}}</span>
					<div class="right-side-list--item d-inline-flex" v-for="min_respondent in campaign.min_respondents">
						<span class="right-side-list--item mr-2">{{getTerminology(min_respondent.terminology_term_id)}}</span>
						<input v-model="min_respondent.number" type="number" class="form-control right-side-list--input w-75" step="1" min="0">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CSVUploadModal from "../modals/CSVUploadModal"
	import AddRespondentToParticipantModal from "../modals/AddRespondentToParticipantModal";
	import AddJustParticipantModal from "../modals/AddJustParticipantModal";
	import Select2AjaxSearch from "../general_components/Select2AjaxSearch";

	export default {
		name: "CampaignParticipantsTable",
		components: {
			Select2AjaxSearch,
			AddJustParticipantModal,
			AddRespondentToParticipantModal,
			'csv-upload-modal': CSVUploadModal
		},
		props: [
			'participants_csv_fields',
			'participants_csv_upload_url',
			'participants_submit_url',
			'participants_create_url',
			'participants_delete_url',
			'single_participant_create_url',
			'participants_delete_participant_url',
			'participants_csv_download_template_url',
            'participants_csv_download_guid_url',
			'load_participants_url',

			'company_terms',
			'terms',

			'campaign'
		],

		data(){
			return {
				selected_participant: 0,
				participants: []
			}
		},

		mounted(){
			if (this.campaign.grouped_participants.length){
				this.filterArray();
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			openParticipantsUploadCSVModal(){
				openModal('#csv-upload-participants-modal');
			},

			openAddJustParticipantModal(){
				openModal('#add-just-participant-modal');
			},

			submitted(data){
				this.$emit('submitted', data);
			},


			openAddRespondentModal(){
				openModal('#add-respondent-modal');
			},

			addedRespondent(participant_data){
				participant_data.respondent.user.campaign_participant_id = participant_data.id;

				for (let pi = 0; pi < this.participants.length; pi++){
					if (this.participants[pi].id === participant_data.participant_user_id){

						for (let ti = 0; ti < this.participants[pi].terminologies.length; ti++){

							if (this.participants[pi].terminologies[ti].id === participant_data.respondent.term.id){
								this.participants[pi].terminologies[ti].respondents.push($.extend(true, {}, participant_data.respondent.user));

								this.$emit('submitted', this.participants);
								return;
							}
						}

						//We didn't found the terminology so we need to create it
						let _term_data = participant_data.respondent.term;

						_term_data['respondents'] = [participant_data.respondent.user];
						this.participants[pi].terminologies.push($.extend(true, {}, _term_data));

						this.$emit('submitted', this.participants);
						return;
					}
				}

				//We didn't found the participant so we need to create it
				let _term_data = participant_data.respondent.term, _participant_data = participant_data.respondent.participant.user;
				_term_data.respondents = [participant_data.respondent.user];
				_participant_data.terminologies = [_term_data];

				this.selected_participant = (this.participants.push($.extend(true, {}, _participant_data))) - 1;
				this.$emit('submitted', this.participants);
			},

			addedJustParticipant(participant_data){
				this.selected_participant = (this.participants.push($.extend(true, {}, participant_data))) - 1;
				this.$emit('submitted', this.participants);
			},


			//Terminology

			getTerminology(id){
				for (let i = 0; i < this.company_terms.length; i ++){
					if (this.company_terms[i].terminology_term_id === id)
						return this.company_terms[i].terminology;
				}

				for (let i = 0; i < this.terms.length; i ++){
					if (this.terms[i].id === id)
						return this.terms[i].name;
				}
			},

			allTerminologies(){
				let _terms = [];

				for (let i = 0; i < this.terms.length ; i++){
					_terms.push(this.getTerminology(this.terms[i].id));
				}

				return _terms;
			},


			deleteRespondent(id, terminologies, term_index, respondent_index){
				let _this = this;

				axios.delete(this.participants_delete_url.replace('?', id))
					.then(function (response){
						//Delete respondent
						terminologies[term_index].respondents.splice(respondent_index, 1);
						//Check and delete the terminology if its empty
						if (!terminologies[term_index].respondents.length)
							terminologies.splice(term_index, 1);

						_this.$emit('submitted', _this.participants);
					})
					.catch(function (error){
						for (var i in error.response.data.errors) {
							if (error.response.data.errors.hasOwnProperty(i)) {
								toastr.error(error.response.data.errors[i][0]);
							}
						}
					});
			},


			//Participants

			selectParticipant(index){
				this.selected_participant = index;
			},

			deleteParticipant(index = null){
				let _this = this;

				//360 and 180
				if (_this.campaign.campaign_type_id === 1 || _this.campaign.campaign_type_id === 2){
					window.confirm(_this.__('csv.are_you_sure_delete_participant'), function (confirmed){
						if (!confirmed) return;

						let _selected = _this.selected_participant;

						axios.delete(_this.participants_delete_participant_url.replace('?', _this.participants[_selected].id))
							.then(function (response){
								_this.participants.splice(_selected, 1);
								_this.selected_participant = _this.participants.length -1;
								if (!_this.participants[_this.selected_participant]){
									_this.selected_participant = 0;
								}

								_this.$emit('submitted', _this.participants);
							})
							.catch(function (error){
								for (var i in error.response.data.errors) {
									if (error.response.data.errors.hasOwnProperty(i)) {
										toastr.error(error.response.data.errors[i][0]);
									}
								}
							});
					});
				}
				else {
					window.confirm(_this.__('csv.are_you_sure_delete_participant'), function (confirmed){
						if (!confirmed) return;

						axios.delete(_this.participants_delete_participant_url.replace('?', _this.participants[index].participant.user_id))
							.then(function (response){
								_this.participants.splice(index, 1);
								_this.$emit('submitted', _this.participants);
							})
							.catch(function (error){
								for (var i in error.response.data.errors) {
									if (error.response.data.errors.hasOwnProperty(i)) {
										toastr.error(error.response.data.errors[i][0]);
									}
								}
							});
					});
				}
			},

			filterArray(){
				let _this = this, participants_in = [];

				_this.campaign.grouped_participants.forEach(function (participant){
					if (participants_in.indexOf(participant.id) === -1){
						_this.participants.push(participant);
						participants_in.push(participant.id);
					}
				});
			},
		},

		watch: {
			campaign: {
				handler: function (){
					this.participants = [];
					this.filterArray();
				},

				deep: true
			},
		}
	}
</script>
