<template>
	<select class="form-control" :id="'select-' + _uid"></select>
</template>

<script>
	export default {
		name: "Select2AjaxSearch",
		props: [
			'placeholder',
			'data',

			'url',
			'paginated',
			'id_prop',
			'label_prop',

			'null_option',

			'value'
		],

		data(){
			return {
				select: null,
			}
		},

		mounted(){
			let _this = this;
			_this.select = $('#select-' + _this._uid)
				.select2(_this.generateSelect())
				.on('change', function (){
					let _val = _this.select.val();
					_this.$emit('input', _val === 'OPTION_NULL' ? null : _val);
				});

			// if (_this.url && _this.value){
			// 	axios.get(_this.url + '?user_id=' + _this.value)
			// 		.then(function (response){
			// 			let option = new Option(response.data.full_name, response.data.id, true, true);
			// 			_this.select.append(option).trigger('change');
			//
			// 			_this.select.trigger({
			// 				type: 'select2:select',
			// 				params: {
			// 					data: response.data
			// 				}
			// 			})
			// 		})
			// }
			_this.select
				.val(_this.value)
				.trigger('change');
		},

		methods: {
			generateSelect(){
				let _this = this;
				if (_this.url){
					return {
						placeholder: _this.placeholder,
						width: '100%',
						theme: 'flat',
						ajax: {
							url: _this.url,
							delay: 250,
							data: _this.requestData,
							processResults: _this.processResults
						}
					}
				}
				return {
					placeholder: this.placeholder,
					width: '100%',
					theme: 'flat',
					data: this.generateData()
				};
			},

			requestData(params){
				let q = {
					search: params.term
				};

				if (this.paginated) {
					q.page = params.page || 1;
				}

				return q;
			},

			processResults(data, params) {
				let
					_this = this,
					results = [],
					response_data = data;

				if (_this.paginated){
					response_data = data.data;
				}

				params.page = params.page || 1;

				response_data.forEach(function (item){
					results.push({
						id: item[_this.id_prop] || 'OPTION_NULL',
						text: item[_this.label_prop],
					})
				});

				if (_this.paginated){
					if (_this.null_option && params.page === 1){
						results = [{ id: 'OPTION_NULL', text: _this.null_option}, { id: null, text: '-------------'}].concat(results);
					}
					return {
						results: results,
						pagination: {
							more: data.current_page < data.last_page
						}
					}
				}
				else {
					return {
						results: results
					};
				}
			},

			generateData(){
				return this.null_option
					? [{id: null, text: this.null_option}].concat(this.data)
					: this.data;
			}
		},

		watch: {
			value(value) {
				let _this = this;
				if (_this.url && _this.value){
					// axios.get(_this.url + '?user_id=' + _this.value)
					// 	.then(function (response){
					// 		let option = new Option(response.data.full_name, response.data.id, true, true);
					// 		_this.select.append(option).trigger('change');
					//
					// 		_this.select.trigger({
					// 			type: 'select2:select',
					// 			params: {
					// 				data: response.data
					// 			}
					// 		})
					// 	})
				}
				else {
					_this.select
						.val(value)
						.trigger('change.select2');
				}
			}
		},

		destroyed: function () {
			$(this.$el).off().select2('destroy')
		}
	}
</script>