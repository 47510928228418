<template>

	<system-users-table v-if="type === 'system_administrators'"
		:users="users"
		:edit_url="edit_url"
		:update_url="update_url"
	></system-users-table>

	<company-users-table v-else-if="type === 'company_administrators'"
		:users="users"
		:edit_url="edit_url"
		:update_url="update_url"
	></company-users-table>

	<campaign-managers-table v-else-if="type === 'campaign_managers'"
		:users="users"
		:edit_url="edit_url"
		:update_url="update_url"
		:campaign_types="campaign_types"
        :company_campaign_type_filters="company_campaign_type_filters"
	></campaign-managers-table>

	<participant-users-table v-else-if="type === 'participants'"
		 :users="users"
		 :edit_url="edit_url"
		 :update_url="update_url"
	></participant-users-table>

</template>

<script>
	import CompanyUsersTable from "./tables/CompanyUsersTable";
	import SystemUsersTable from "./tables/SystemUsersTable";
	import DepartmentUsersTable from "./tables/DepartmentUsersTable";
	import ParticipantUsersTable from "./tables/ParticipantUsersTable";
	import CampaignManagersTable from "./tables/CampaignManagersTable";

	export default {
		components: { CampaignManagersTable, ParticipantUsersTable, DepartmentUsersTable, SystemUsersTable, CompanyUsersTable},
		props: [
			'type',
			'this_url',
			'users',
			'campaign_types',
            'company_campaign_type_filters'
		],
		computed: {
			edit_url (){
				return this.this_url + '/?/edit';
			},
			update_url (){
				return this.this_url + '/?';
			}
		}
	}
</script>
