<template>
	<table class="custom-table">
		<thead>
		<tr>
			<th>{{__('translation.manager')}}</th>
			<th v-if="PermissionsController.can('crud departments')">{{__('translation.department')}}</th>
			<th v-if="PermissionsController.can('crud companies')">{{__('translation.company')}}</th>
			<th v-for="type in campaign_types">{{__('place_holders.campaign_types.' + type.id)}}</th>
			<th><!--actions--></th>
		</tr>
		</thead>
		<tbody>
		<tr v-for="user in users_edit">
			<td>
				<div class="w-100">{{user.full_name}}</div>
				<div class="w-100">{{user.email}}</div>
			</td>
			<td v-if="PermissionsController.can('crud departments')">{{user.department ? user.department.name : ''}}</td>
			<td v-if="PermissionsController.can('crud companies')">{{user.company.name}}</td>
			<th v-for="filter_data in user.filters_data">
				<div class="checkbox w-auto my-auto">
					<label class="customer_checkbox">
						<input class="form-check-input no-stretch" v-bind:disabled="filter_data.disabled" type="checkbox" @change="saveFilters(user)" v-model="filter_data.active">
						<span class="checkmark"></span>
					</label>
				</div>
			</th>
			<td>
				<!--actions-->
				<button v-if="PermissionsController.can('impersonate') && !PermissionsController.isMe(user.id)" class="btn btn-link" v-on:click="impersonateUser(user.id)">Impersonate</button>
				<a v-if="!PermissionsController.isMe(user.id)" :href="edit_url.replace('?', user.id)">edit</a>
			</td>
		</tr>
		</tbody>
	</table>

</template>
<script>
	export default {
		props: [
			'type',
			'users',
			'update_url',
			'edit_url',
			'campaign_types',
            'company_campaign_type_filters'
		],

		data(){
			return {
				users_edit: []
			}
		},

		beforeMount(){
			this.PermissionsController = window.PermissionsController;
			this.users_edit = this.users;
			this.getCampaignTypeFilters();
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			getCampaignTypeFilters(){
				let
					_this = this;

				_this.users_edit.forEach(function (user){
					user.filters_data = [];

					_this.campaign_types.forEach(function (type){
						let _active = true, _disabled = false;

						for (let i = 0; i < user.campaign_type_filters.length; i++){
							if (user.campaign_type_filters[i].campaign_type_id === type.id){
								_active = false;
							}
						}

						for (let i = 0; i < _this.company_campaign_type_filters.length; i++){
							if (_this.company_campaign_type_filters[i].campaign_type_id === type.id){
								_disabled = true;
							}
						}

						user.filters_data.push({
							id: type.id,
							active: _disabled ? false : _active,
                            disabled: _disabled
						})
					});
				});
			},

			saveFilters(user){
				let _this = this, filters = [];

				user.filters_data.forEach(function (filter) {
					if (!filter.active)
						filters.push(filter.id);
				});

				axios.patch(_this.update_url.replace('?', user.id), {
					first_name: user.first_name,
					last_name: user.last_name,
					campaign_type_filters: filters
				});
			},

			impersonateUser (user_id){
				axios.post('/dev/impersonate/' + user_id)
					.then(function (){
						window.location.reload();
					})
			}
		}
	}
</script>
