<template>
	<div>
		<div v-if="comparison_participants" class="reporting-page--summary-card">
			<div v-if="has_respondents" class="reporting-page--summary-card-row comparison">
				<div class="d-flex justify-content-between">
					<div class="w-100">
						<span :style="{color: primary_color, 'white-space': 'nowrap'}">{{__('translation.respondents')}}</span>
						<h5 :style="{color: primary_color}" class="reporting-page--summary-card-result">{{comparison_percentage_respondents}}%</h5>
						<span :style="{color: primary_color}" class="d-block">{{__('translation.completed')}}</span>
					</div>
					<div class="pl-4 w-100">
						<span :style="{color: secondary_color, 'white-space': 'nowrap'}">{{__('translation.respondents')}}</span>
						<h5 :style="{color: secondary_color}" class="reporting-page--summary-card-result">{{percentage_respondents}}%</h5>
						<span :style="{color: secondary_color}" class="d-block">{{__('translation.completed')}}</span>
					</div>
				</div>
				<div class="d-flex justify-content-between">
					<div class="w-100">
						<h5 :style="{color: primary_color}" class="reporting-page--summary-card-result">{{comparison_score_respondents}}</h5>
						<span :style="{color: primary_color}" class="d-block">{{__('translation.avg_score')}}</span>
					</div>
					<div class="pl-4 w-100">
						<h5 :style="{color: secondary_color}" class="reporting-page--summary-card-result">{{score_respondents}}</h5>
						<span :style="{color: secondary_color}" class="d-block">{{__('translation.avg_score')}}</span>
					</div>
				</div>
			</div>
			<hr v-if="has_respondents">

			<div class="reporting-page--summary-card-row comparison">
				<div class="d-flex justify-content-between">
					<div class="w-100">
						<span :style="{color: primary_color, 'white-space': 'nowrap'}">{{has_respondents ? __('translation.self_assessment') : __('translation.participants') + ' ' + __('translation.before')}}</span>
						<h5 :style="{color: primary_color}" class="reporting-page--summary-card-result">{{comparison_percentage_participants}}%</h5>
						<span :style="{color: primary_color}" class="d-block">{{__('translation.completed')}}</span>
					</div>
					<div class="pl-4 w-100">
						<span :style="{color: secondary_color, 'white-space': 'nowrap'}">{{has_respondents ? __('translation.self_assessment') : __('translation.participants') + ' ' + __('translation.after')}}</span>
						<h5 :style="{color: secondary_color}" class="reporting-page--summary-card-result">{{percentage_participants}}%</h5>
						<span :style="{color: secondary_color}" class="d-block">{{__('translation.completed')}}</span>
					</div>
				</div>
				<div class="d-flex justify-content-between">
					<div class="w-100">
						<h5 :style="{color: primary_color}" class="reporting-page--summary-card-result">{{comparison_score_participants}}</h5>
						<span :style="{color: primary_color}" class="d-block">{{__('translation.avg_score')}}</span>
					</div>
					<div class="pl-4 w-100">
						<h5 :style="{color: secondary_color}" class="reporting-page--summary-card-result">{{score_participants}}</h5>
						<span :style="{color: secondary_color}" class="d-block">{{__('translation.avg_score')}}</span>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="reporting-page--summary-card">
			<div v-if="has_respondents" class="reporting-page--summary-card-row">
				<span :style="{color: secondary_color}">{{__('translation.respondents')}}</span>
				<div class="d-flex justify-content-between">
					<div class="w-100">
						<h5 :style="{color: secondary_color}" class="reporting-page--summary-card-result">{{percentage_respondents}}%</h5>
						<span :style="{color: secondary_color}" class="d-block">{{__('translation.completed')}}</span>
					</div>
					<div class="pl-4 w-100">
						<h5 :style="{color: secondary_color}" class="reporting-page--summary-card-result">{{score_respondents}}</h5>
						<span :style="{color: secondary_color}" class="d-block">{{__('translation.avg_score')}}</span>
					</div>
				</div>
				<hr>
			</div>

			<div class="reporting-page--summary-card-row">
				<span class="" :style="{color: primary_color}">{{has_respondents ? __('translation.self_assessment') : __('translation.participants')}}</span>
				<div class="d-flex justify-content-between">
					<div class="w-100">
						<h5 :style="{color: primary_color}" class="reporting-page--summary-card-result">{{percentage_participants}}%</h5>
						<span :style="{color: primary_color}" class="d-block">{{__('translation.completed')}}</span>
					</div>
					<div v-if="campaign_type !== 4" class="pl-4 w-100">
						<h5 :style="{color: primary_color}" class="reporting-page--summary-card-result">{{score_participants}}</h5>
						<span :style="{color: primary_color}" class="d-block">{{__('translation.avg_score')}}</span>
					</div>
				</div>
			</div>

			<div v-if="has_respondents" class="reporting-page--summary-card-row">
				<hr>
				<span class="d-block">{{__('translation.score_difference')}}</span>
				<h5 class="reporting-page--summary-card-result">{{score_difference}}</h5>
			</div>
		</div>

		<div v-if="comparison_participants" class="row mt-4">
			<div class="d-flex col-5">
				<div class="dot" :style="{'background-color': primary_color}"></div>
				<span class="d-block my-auto pl-2" :style="{'color': primary_color}">{{__('translation.before')}}</span>
			</div>
			<div class="d-flex col-7">
				<div class="dot" :style="{'background-color': secondary_color}"></div>
				<span class="d-block my-auto pl-2" :style="{'color': secondary_color}">{{__('translation.after')}}</span>
			</div>
		</div>
		<div v-else class="row mt-4">
			<div v-if="campaign_type !== 4" class="d-flex" v-bind:class="has_respondents ? 'col-5' : 'col-12'">
				<div class="dot" :style="{'background-color': primary_color}"></div>
				<span class="d-block my-auto pl-2" :style="{'color': primary_color}">{{has_respondents ? __('translation.self') : __('translation.participant')}}</span>
			</div>
			<div v-if="has_respondents" class="col-7 d-flex">
				<div class="dot" :style="{'background-color': secondary_color}"></div>
				<span class="d-block my-auto pl-2" :style="{'color': secondary_color}">{{__('translation.respondent')}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import _ from 'underscore/underscore';

	export default {
		name: "ReportingSummaryCard",
		props: [
			'participants',
            'campaign_type',

			'comparison_participants',

			'primary_color',
			'secondary_color'
		],

		data(){
			return {

            }
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			//Filters
			percentage_of_campaign_participants(campaign_participants){
				if (_.filter(campaign_participants, (participant) => participant.percentage_completed >= 100).length){
					return (_.filter(campaign_participants, (participant) => participant.percentage_completed >= 100).length / campaign_participants.length * 100).toFixed(0);
				}
				return 0;
			},
			avg_score_of_campaign_participants(campaign_participants){
				let
					_all_ratings = _.pluck(_.flatten(_.pluck(campaign_participants, 'questionnaire_answers')), 'rating'),
					_sum = 0,
					_length = 0,
					_na = true;

				_all_ratings.forEach(function (val){
					if (val){
						_na = false;
						_length ++;
						_sum += val;
					}
				});

				return _na ? this.__('place_holders.na') : (_sum / _length).toFixed(1);
			},

			updateData(){
				let data = {
					respondents_completed: this.percentage_respondents,
					respondents_avg_score: this.score_respondents,
					participants_completed: this.percentage_participants,
					participants_avg_score: this.score_participants,
					score_difference: this.score_difference
				};

				if (!!this.comparison_participants) {
					data.comparison_data = {
						respondents_completed: this.comparison_percentage_respondents,
						respondents_avg_score: this.comparison_score_respondents,
						participants_completed: this.comparison_percentage_participants,
						participants_avg_score: this.comparison_score_participants,
						score_difference: this.comparison_score_difference
					}
				}

				this.$emit('loaded', data);
			}
		},

		computed: {
			percentage_respondents(){
				return this.percentage_of_campaign_participants(_.where(this.participants, {participant: null}));
			},
			comparison_percentage_respondents(){
				return this.percentage_of_campaign_participants(_.where(this.comparison_participants, {participant: null}));
			},
			score_respondents(){
				return this.avg_score_of_campaign_participants(_.filter(this.participants, (participant) => participant.participant == null && participant.percentage_completed >= 100));
			},
			comparison_score_respondents(){
				return this.avg_score_of_campaign_participants(_.filter(this.comparison_participants, (participant) => participant.participant == null && participant.percentage_completed >= 100));
			},

			percentage_participants(){
				return this.percentage_of_campaign_participants(_.where(this.participants, {respondent: null}));
			},
			comparison_percentage_participants(){
				return this.percentage_of_campaign_participants(_.where(this.comparison_participants, {respondent: null}));
			},
			score_participants(){
				return this.avg_score_of_campaign_participants(_.filter(this.participants, (participant) => participant.respondent == null && participant.percentage_completed >= 100));
			},
			comparison_score_participants(){
				return this.avg_score_of_campaign_participants(_.filter(this.comparison_participants, (participant) => participant.respondent == null && participant.percentage_completed >= 100));
			},

			score_difference(){
				let
					_participants = this.score_participants,
					_respondents = this.score_respondents;

				if (_participants === null || _participants === this.__('place_holders.na') || _respondents === null || _respondents === this.__('place_holders.na')) {
					return this.__('place_holders.na');
				}
				return (_respondents - _participants) > 0 ? '+' + (_respondents - _participants).toFixed(1) : (_respondents - _participants ).toFixed(1);
			},

			comparison_score_difference(){
				let
					_participants = this.comparison_score_participants,
					_respondents = this.comparison_score_respondents;

				if (_participants === null || _participants === this.__('place_holders.na') || _respondents === null || _respondents === this.__('place_holders.na')) {
					return this.__('place_holders.na');
				}
				return (_respondents - _participants) > 0 ? '+' + (_respondents - _participants).toFixed(1) : (_respondents - _participants ).toFixed(1);
			},

			has_respondents(){
				return !!_.where(this.participants, {participant: null}).length;
			}
		},

		watch: {
			percentage_respondents(){
				this.updateData();
			},
			percentage_participants() {
				this.updateData();
			},
			score_participants() {
				this.updateData();
			},
			score_respondents(){
				this.updateData();
			}
		}
	}
</script>
