(function (){
	window.closeModal = function (target = null){
		if (target == null){
			$('.feedo-modal.active').removeClass('active');
		}
		else {
			$(target).removeClass('active');
		}

		$(document.body).removeClass('feedo-modal--no-scroll');
	};

	window.openModal = function (target){
	    if (!(target instanceof jQuery)){
	        target = $(target);
        }
	    target.addClass('active');
	    let input = target.find('input[type=text]')[0];
	    if (input){input.focus();}

		$(document.body).addClass('feedo-modal--no-scroll');
	};
})();
