<template>
	<div class="row mt-4">
		<div class="col-md-10 order-1 order-md-0">
			<div class="container p-0">
				<div class="row">
					<!--Section Select-->
					<div class="col-lg-4">
						<ul class="list-group">
							<li v-for="(default_section, index) in default_sections" class="list-group-item">
								<a href="javascript:void(0)" v-on:click="selectDefaultSection(index)">
									{{default_section.title}}
									<img v-if="matches(index)" src="/svg/orange-tick.svg" />
								</a>
							</li>
						</ul>
					</div>

					<!--List of reports-->
					<div class="col-lg-8">
						<a
							v-for="(section, index) in sections"
							href="javascript:void(0)"
							v-on:click="toggleSection(index)"
							class="questionnaire_edit_table--question-select-row justify-content-between"
						>
							<span>{{__('translation.reporting_section_' + section.code)}}</span>
							<img v-show="section.value" alt="tick" src="/svg/orange-tick.svg">
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="col-md-2 order-0 order-md-1 right-side-list">
			<!--<a href="javascript:void(0)" class="right-side-list&#45;&#45;item">{{__('translation.preview')}}</a>-->
		</div>
	</div>
</template>

<script>
	export default {
		name: "CampaignEditReporting",
		props: [
			'value',
			'reporting_update_url'
		],

		data(){
			return {
				campaign_edit: {},

				default_sections: [],

				sections: [],
				lite_defaults: ['feedback', 'spider', 'summary', 'open_ended'],
			}
		},

		beforeMount() {
			let
				_this = this,
				selected_lite_defaults = [];

			_this.campaign_edit = _this.value;
			_this.campaign_edit.reporting_sections.forEach(function (section){
				_this.sections.push({
					id: section.id,
					code: section.code,
					value: !!section.value
				});
			});

			_this.sections.forEach(function (section){
				if (_this.lite_defaults.indexOf(section.code) !== -1){
					selected_lite_defaults.push(_this.lite_defaults[_this.lite_defaults.indexOf(section.code)]);
				}
			});

			_this.default_sections = [
				{
					title: _this.__('translation.reporting_lite'),
					defaults: selected_lite_defaults
				},
				{
					title: _this.__('translation.reporting_standard')
				}
			];
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			matches(index){
				let _selected_sections = [];

				this.sections.forEach(function (section){
					if (section.value){
						_selected_sections.push(section.code);
					}
				});

				if (!this.default_sections[index].defaults){
					return this.sections.length === _selected_sections.length;
				}
				else {
					if (this.default_sections[index].defaults.length === _selected_sections.length){
						for (let i = 0; i < this.default_sections[index].defaults.length; i ++){
							if (this.default_sections[index].defaults[i] !== _selected_sections[i]){
								return false;
							}
						}

						return true;
					}
					return false;
				}
			},

			save(){
				let _this = this;

				_this.campaign_edit.reporting_sections = _this.sections;
				_this.$emit('input', _this.campaign_edit);

				axios.post(_this.reporting_update_url, {
					sections: _this.sections
				});
			},

			selectDefaultSection(index){
				let _this = this;
				_this.sections.forEach(function (section){
					if (!_this.default_sections[index].defaults){
						section.value = true;
					}
					else {
						section.value = _this.default_sections[index].defaults.indexOf(section.code) !== -1;
					}
				});

				this.save();
			},

			toggleSection(index){
				let _this = this, section = _this.sections[index];

				section.value = !section.value;
				this.save();
			}
		}
	}
</script>
