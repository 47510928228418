<template>
	<div class="row">
		<div class="col-12 d-flex flex-wrap justify-content-center">
			<img
				class="d-block m-1"
				width="40"
				height="40"
				v-for="(option, index) in options" :src="selected !== false && index <= selected ? '/svg/star.svg' : '/svg/star-inactive.svg'"
				alt="Star"
				v-on:click="select(index)"
                v-on:mouseenter="number_highlighted = index"
                v-on:mouseleave="number_highlighted = -1"
			>
		</div>

		<div class="col-12 mt-3" style="min-height: 29px">
			<h5 class="text-primary text-center font-weight-bold font-italic">{{displayValue}}</h5>
		</div>
	</div>
</template>

<script>
	export default {
		name: "StarRating",
		props: [
			'options',
			'answer'
		],

		data() {
			return {
				selected: -1,
                number_highlighted: -1
			}
		},

		methods: {
			select(i){
				if (this.selected === i)
					return ;
				this.selected = i;
				this.$emit('select', this.options[i]);
			},

			reset(){
				this.selected = this.number_highlighted = -1;
				this.$nextTick(function () {
					if (this.answer){
						for (let i = 0; i < this.options.length ; i ++){
							if (this.options[i].number === this.answer.rating){
								this.selected = i;
								return;
							}
						}
					}
				});
			}
		},

        computed: {
			displayValue(){
				if (this.number_highlighted > -1){
					return this.options[this.number_highlighted].value;
				}
				if (this.selected > -1){
					return this.options[this.selected].value
				}

				return '';
            }
        }
	}
</script>
