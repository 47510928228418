<template>
	<div class="reporting-page--section" data-section="percentage_score">
		<collapsable :title="__('translation.reporting_section_percentage_score')">

			<div v-if="loading" class="loaders--standard override"></div>

			<div v-else class="">
				<hr />

				<!--Has respondents-->
				<div v-if="data.sections_data[0].terms && Object.keys(data.sections_data[0].terms).length > 1" class="reporting-page--section-table">
					<div class="row">
						<div class="col-4">
							<span class="text-secondary font-weight-bold">{{__('translation.area')}}</span>
						</div>
						<div class="col-2 text-right">
							<span class="text-secondary font-weight-bold">{{__('translation.self_score')}}</span>
						</div>
						<div class="col-3 text-right">
							<span class="text-secondary font-weight-bold">{{__('translation.avg_respondents')}}</span>
						</div>
						<div class="col-3 text-right">
							<span class="text-secondary font-weight-bold">{{__('translation.difference')}}</span>
						</div>
					</div>
					<div v-for="section in data.table" class="row py-2">
						<div class="col-4 font-weight-bold">{{section.name}}</div>
						<div class="col-2 text-right my-auto">
							{{section.participants.avg}}

							<small
								v-if="comparison"
								class="ml-1"
								style="min-width: 24px; display: inline-block"
								v-bind:class="compareValues(section.participants.avg, section.comparison_data.participants.avg).class"
							>
								{{compareValues(section.participants.avg, section.comparison_data.participants.avg, 2).label}}
							</small>
						</div>
						<div class="col-3 text-right my-auto">
							{{section.respondents.avg}}

							<small
								v-if="comparison"
								class="ml-1"
								style="min-width: 24px; display: inline-block"
								v-bind:class="compareValues(section.respondents.avg, section.comparison_data.respondents.avg).class"
							>
								{{compareValues(section.respondents.avg, section.comparison_data.respondents.avg, 2).label}}
							</small>
						</div>
						<div class="col-3 text-right my-auto">
							{{diffValue(section.participants.avg, section.respondents.avg)}}

							<small
								v-if="comparison"
								class="ml-1"
								style="min-width: 24px; display: inline-block"
								v-bind:class="compareValues(
									diffValue(section.participants.avg, section.respondents.avg),
									diffValue(section.comparison_data.participants.avg, section.comparison_data.respondents.avg)).class"
							>
								{{compareValues(
									diffValue(section.participants.avg, section.respondents.avg),
									diffValue(section.comparison_data.participants.avg, section.comparison_data.respondents.avg), 2).label}}
							</small>
						</div>
					</div>
				</div>
				<!--There's more than one section-->
				<div v-else-if="data.sections_data.length > 1" class="reporting-page--section-table">
					<div class="row">
						<div class="col-6">
							<span class="text-secondary font-weight-bold">{{__('translation.area')}}</span>
						</div>
						<div class="col-6 text-right">
							<span class="text-secondary font-weight-bold">{{__('translation.avg_score')}}</span>
						</div>
					</div>
					<div v-for="section in data.table" class="row py-2">
						<div class="col-6 font-weight-bold">{{section.name}}</div>
						<div class="col-6 text-right my-auto">
							{{section.participants.avg}}
							<small
								v-if="comparison"
								class="ml-1"
								style="min-width: 24px; display: inline-block"
								v-bind:class="compareValues(section.participants.avg, section.comparison_data.participants.avg, 0).class"
							>
								{{compareValues(section.participants.avg, section.comparison_data.participants.avg, 1).label}}
							</small>
						</div>
					</div>
				</div>

				<!--Each Competency Breakdown-->
				<div class="mt-4">
                    <div class="col-12 px-0">
                        <div>{{__('reporting.feedback.breakdown_description')}}</div>
                        <div class="font-italic"><span class="text-grey mr-2">0 = question not answered</span><span class="text-primary">0 = question was answered</span></div>
                    </div>
					<competency-breakdown-card
						v-for="(section, index) in data.sections_data"
						:key="section.id"
						:section="section"
						:index="index + 1"
						:show="data.sections_data.length === 1"

						:primary_color="primary_color"
						:secondary_color="secondary_color"
						:primary_color_faded="primary_color_faded"
						:secondary_color_faded="secondary_color_faded"
					></competency-breakdown-card>
				</div>
			</div>

		</collapsable>
		<hr v-if="!loading" />
	</div>
</template>

<script>
	import _ from "underscore/underscore"
	import Collapsable from "./Collapsable";
	import CompetencyBreakdownCard from "./CompetencyBreakdownCard";
	export default {
		name: "ScoreByCompetency",
		components: {CompetencyBreakdownCard, Collapsable},
		props: [
			'load_url',
			'token',
            'campaign_type_id',

			'primary_color',
			'secondary_color',
			'primary_color_faded',
			'secondary_color_faded',
		],

		data(){
			return {
				loading: true,
				pre_loading: true,

				comparison: false,

				data: []

			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			loadData(){
				let _this = this;

				_this.loading = true;

				axios.post(_this.load_url, {
					token: this.token,
					code: 'percentage_score'
				})
					.then(function (response){
						_this.loading = false;

						_this.data = response.data;

						const table_data = _this.data.table;
						_this.data.table = table_data.spider_data;

						if (table_data.comparison_report_data){
							_this.comparison = true;
							for (let i = 0; i < _this.data.table.length; i ++){
								const section_name = _this.data.table[i].name;

								for (let n = 0; n < table_data.comparison_report_data.length; n ++){
									if (table_data.comparison_report_data[n].name === section_name){
										_this.data.table[i].comparison_data = table_data.comparison_report_data[n];
										break;
									}
								}
							}
						}

						//Parsing PDF DATA
						let pdf_data = {
							sections: _this.data.sections_data,
							table_rows: [],
                            campaign_type_id: _this.campaign_type_id,
							primary_color: _this.primary_color,
							secondary_color: _this.secondary_color,
							primary_color_faded: _this.primary_color_faded,
							secondary_color_faded: _this.secondary_color_faded,
                            comparison_report: !!table_data.comparison_report_data
						};

						_this.data.table.forEach(function (row){
							let table_row = {
								section: row.name,
								participant_score_percentage: row.participants.percentage,
								participant_score_avg: row.participants.avg,
                                respondent_score_percentage: row.respondents.percentage,
								respondent_score_avg: row.respondents.avg,
								difference: _this.diff(row.participants.percentage, row.respondents.percentage).toString(),
								difference_value: _this.diffValue(row.participants.avg, row.respondents.avg)
							};

							if (_this.comparison){
								table_row.comparison_participant_score_percentage = row.comparison_data.participants.percentage;
								table_row.comparison_participant_score_avg = row.comparison_data.participants.avg;
								table_row.comparison_respondent_score_percentage =  row.comparison_data.respondents.percentage;
								table_row.comparison_respondent_score_avg =  row.comparison_data.respondents.avg;
								table_row.comparison_difference = _this.diff(row.comparison_data.participants.percentage, row.comparison_data.respondents.percentage).toString()
								table_row.comparison_difference_value = _this.diffValue(row.comparison_data.participants.avg, row.comparison_data.respondents.avg)
							}
							pdf_data.table_rows.push(table_row);
						});

						_this.$emit('loaded', pdf_data);
					})
					.catch(function (){
						//@todo
					})
			},

			compareValues(a, b, toFixed = null){
				const val = a - b;
				if (val > 0)
					return {
						class: 'text-success',
						different: true,
						label: '+' + (toFixed !== null ? val.toFixed(toFixed) : val)
					};
				else if (val < 0)
					return {
						class: 'text-danger',
						different: true,
						label: (toFixed !== null ? val.toFixed(toFixed) : val)
					};
				else
					return {
						class: 'text-grey',
						different: false,
						label: (toFixed !== null ? (0).toFixed(toFixed) : '0')
					};
			},

			diff(_participants, _respondents){
				return (_respondents - _participants) > 0
					? '+' + Math.round(_respondents - _participants)
					: Math.round(_respondents - _participants);
			},

			diffValue(_participants, _respondents){
				return (_respondents - _participants) > 0
					? '+' + parseFloat(_respondents - _participants).toFixed(2)
					: parseFloat(_respondents - _participants).toFixed(2);
			}
		},

		watch: {
			token() {
				this.$emit('unloaded');
				if (this.token !== null) {
					this.pre_loading = false;
					this.loading = false;
					this.loadData();
				}
				else {
					this.pre_loading = true;
					this.loading = true;
				}
			}
		}
	}
</script>
