<template>
	<div class="row">

		<div class="col-12">
            <!--Desktop-->
			<div class="d-block">
				<div class="rating-cards--wrapper numbers">
					<div
						class="rating-cards--numbers-tag"
						v-for="(option, index) in options"
						v-on:click="select(index)"
                        v-on:mouseenter="number_highlighted = index"
                        v-on:mouseleave="number_highlighted = -1"
						v-bind:class="number_selected === index ? 'active' : ''"
					>{{ option.number }}</div>
				</div>
			</div>
		</div>

		<div class="col-12 mt-3" style="height: 21px;">
			<h5 class="text-primary text-center font-weight-bold font-italic">{{displayValue}}</h5>
		</div>
	</div>
</template>

<script>
	export default {
		name: "NumbersRating",
		props: [
			'options',
			'answer'
		],

		data(){
			return {
				selected: false,
				number_highlighted: -1,
				number_selected: -1,
				numbers_reversed: [],
				dragging: false,

				posY: null,
			}
		},

		beforeMount(){

		},

		methods: {
			select(i){
				if (this.number_selected === i)
					return ;
				this.number_selected = i;
				this.number_highlighted = -1;
				this.selected = true;
				this.$emit('select', this.options[i]);
			},

			reset(){
				this.selected = false;
				this.number_selected = this.number_highlighted = -1;

				this.$nextTick(function () {
					if (this.answer){
						for (let i = 0; i < this.options.length ; i ++){
							if (this.options[i].number === this.answer.rating){
								this.selected = true;
								this.number_selected = i;
								return;
							}
						}
					}
				});
			}
		},

		computed: {
			displayValue(){
				if (this.number_highlighted > -1){
					return this.options[this.number_highlighted].value;
                }
				if (this.number_selected > -1){
					return this.options[this.number_selected].value
                }

				return '';
            }
		}
	}
</script>
