<template>
	<textarea ref="redactor" :name="name" :placeholder="placeholder" :value="value"></textarea>
</template>

<script>
	export default {
		name: "Redactor",
		redactor: false,
		props: {
			value: {
				default: '',
				type: String
			},
			placeholder: {
				type: String,
				default: null
			},
			name: {
				type: String,
				default: null
			},
			config: {
				default: {},
				type: Object
			}
		},

		mounted(){
			this.init()
		},

		methods: {
			init() {
				let
					_this = this,
					callbacks = {
						changed: function (html) {
							_this.handleInput(html);
							return html;
						}
					};

				Vue.set(this.config, 'callbacks', callbacks);

				let app = $R(this.$refs.redactor,this.config);

				this.redactor = app;
				this.$parent.redactor = app;

				$('.redactor-box:has(.redactor-box-grey)').addClass('redactor-box-grey');
				$('.redactor-box .redactor-box-grey').removeClass('redactor-box-grey');
			},

			handleInput(val){
				this.$emit('input',val);
			}
		},

		watch: {
			value(newValue, oldValue) {
				if(!this.redactor.editor.isFocus()){
					this.redactor.source.setCode(newValue)
				}
			}
		}
	}
</script>