<template>
	<div class="container-fluid">
		<div v-if="loading" class="container px-0 my-4">
			<div class="row">
				<div class="col-12">
					<div class="loaders--standard"></div>
				</div>
			</div>
		</div>
		<div v-else class="container px-0 my-4">
			<div class="row">
				<div class="col-12">
					<h5 style="font-weight: 800;">{{__('translation.completed')}}</h5>

					<div class="answer-page--cards-wrapper">
						<div v-for="campaign_participant in completed_questionnaires" class="answer-page--card">
							<div class="answer-page--label answer-page--label-card mb-1">
								{{campaign_participant.campaign.is_tna ? __('translation.tna_title') : campaign_participant.campaign.campaign_type.name}}
							</div>
							<h3>{{campaign_participant.participant_full_name}}</h3>
							<h6 class="font-weight-bold text-grey">{{campaign_participant.relationship}}</h6>
							<h5 class="answer-page--campaign-title">{{campaign_participant.campaign.name}}</h5>
							<div class="position-relative">
								<vue-circle
									:progress="campaign_participant.percentage_completed"
									class="mt-2"
									:size="65"
									:fill="{color:'#f0612e'}"
									:animation="{duration: 0}"
									:reverse="false"
									line-cap="round"
									empty-fill="rgba(0, 0, 0, .1)"
									:animation-start-value="0.0"
									:start-angle="4.7"
									insert-mode="append"
									:thickness="5"
									:show-percent="false"
								></vue-circle>
								<img
									style="position: absolute; bottom: 27px; left: 15px; width: 35px;"
									v-if="campaign_participant.percentage_completed >= 100"
									src="/svg/orange-tick.svg"
								/>
							</div>
							<div class="d-flex justify-content-between mt-2">
								<div class="d-block my-auto">
									<span class="d-none d-md-inline text-primary">
										{{__('translation.completed_on')}}
									</span>
									<span class=" text-primary">
										{{campaign_participant.updated_at_formatted}}
									</span>
								</div>
								<a
									v-if="campaign_participant.participant &&
										campaign_participant.participant.user_id === user.id &&
										campaign_participant.campaign.allow_managers_see_feedback &&
										(Date.parse(campaign_participant.campaign.finish_date) < Date.parse(Date()) || campaign_participant.campaign.allow_while_working_progress)"
									:href="show_url.replace('?', campaign_participant.id)"
									class="btn btn-sm btn-outline-primary border my-auto">
									{{__('buttons.view')}}
								</a>
							</div>
						</div>
					</div>
                    <h4 v-if="!completed_questionnaires.length">{{__('translation.no_completed_questionnaires')}}</h4>
				</div>
			</div>
			<div class="row mt-5" v-if="missed_questionnaires.length">
				<div class="col-12">
					<h5 style="font-weight: 800;">{{__('translation.missed')}}</h5>

					<div class="answer-page--cards-wrapper">
						<div v-for="campaign_participant in missed_questionnaires" class="answer-page--card">
							<div class="answer-page--label answer-page--label-card mb-1">
								{{campaign_participant.campaign.is_tna ? __('translation.tna_title') : campaign_participant.campaign.campaign_type.name}}
							</div>
							<h3>{{campaign_participant.participant_full_name}}</h3>
							<h6 class="font-weight-bold text-grey">{{campaign_participant.relationship}}</h6>
							<h5>{{campaign_participant.campaign.name}}</h5>
							<vue-circle
								:progress="campaign_participant.percentage_completed"
								class="mt-4"
								:size="65"
								:fill="{color:'#f0612e'}"
								:animation="{duration: 0}"
								:reverse="false"
								line-cap="round"
								empty-fill="rgba(0, 0, 0, .1)"
								:animation-start-value="0.0"
								:start-angle="4.7"
								insert-mode="append"
								:thickness="5"
								:show-percent="false"
							></vue-circle>
							<div class="d-flex justify-content-between mt-2">
								<div class="d-block">
									<span class="d-block my-auto text-primary">{{__('translation.finished_at')}}</span>
									<span class="d-block my-auto text-primary">{{campaign_participant.campaign.finish_date}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CompletedMissedAnswersIndex",
		props: [
			'load_url',
			'show_url',
			'user'
		],

		data(){
			return {
				loading: false,
				reload: false,

				completed_questionnaires: [],
				missed_questionnaires: [],
			}
		},

		beforeMount(){
			this.loadData();
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			loadData(){
				let _this = this;

				if (_this.loading){
					_this.reload = true;
					return;
				}

				_this.loading = true;
				axios.get(_this.load_url)
					.then(function (response){
						_this.loading = false;

						if (_this.reload){
							_this.reload = false;
							_this.loadData();
							return;
						}

						_this.completed_questionnaires = response.data.completed_questionnaires;
						_this.missed_questionnaires = response.data.missed_questionnaires;
					})
					.catch(function (){
						toastr.error('Something went wrong, please contact the administrator');
					})
			},
		}
	}
</script>
