<template>
	<div class="feedo-modal" v-on:click="closeModal($event)">
		<div class="feedo-modal--card" style="min-width: 300px">
			<div class="feedo-modal--card-header">
				<div class="feedo-modal--card-header-title">
					<h4>{{__('translation.upload_csv_title')}}</h4>
				</div>
				<a href="javascript:void(0)" class="feedo-modal--card-header-close" v-on:click="closeModal($event, true)">
					<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch -->
						<title>Icon/delete-question</title>
						<desc>Created with Sketch.</desc>
						<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="Table/Question" transform="translate(-618.000000, -14.000000)" fill="#b1b2c1">
								<g id="Icon/Delete" transform="translate(618.000000, 14.000000)">
									<path d="M9.75832409,8.34411053 L6.70710678,5.29289322 C6.31658249,4.90236893 5.68341751,4.90236893 5.29289322,5.29289322 C4.90236893,5.68341751 4.90236893,6.31658249 5.29289322,6.70710678 L8.34411053,9.75832409 L5.29289322,12.8095414 C4.90236893,13.2000657 4.90236893,13.8332307 5.29289322,14.223755 C5.68341751,14.6142793 6.31658249,14.6142793 6.70710678,14.223755 L9.75832409,11.1725377 L12.8095414,14.223755 C13.2000657,14.6142793 13.8332307,14.6142793 14.223755,14.223755 C14.6142793,13.8332307 14.6142793,13.2000657 14.223755,12.8095414 L11.1725377,9.75832409 L14.223755,6.70710678 C14.6142793,6.31658249 14.6142793,5.68341751 14.223755,5.29289322 C13.8332307,4.90236893 13.2000657,4.90236893 12.8095414,5.29289322 L9.75832409,8.34411053 Z M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z" id="Combined-Shape-Copy"></path>
								</g>
							</g>
						</g>
					</svg>
				</a>
			</div>
			<div class="feedo-modal--card-body p-3">
				<div class="row mt-4">
					<div class="col-12 text-center">
						<div class="" v-show="!file || !rows.length && !failed_rows.length">
							<img class="d-block mx-auto" src="/svg/csv-upload.svg" alt="File">
						</div>
						<div v-show="rows.length && !failed_rows.length">
							<img class="d-block mx-auto" src="/svg/csv-upload-success.svg" alt="Success">
							<span class=""><b>{{rows.length}}</b> {{placeholder ? placeholder : __('csv.entries')}}</span>
						</div>
						<div v-show="failed_rows.length">
							<img class="d-block mx-auto" src="/svg/csv-upload-failed.svg" alt="Failed">
							<span class="text-danger"><b>{{failed_rows.length}}</b> {{placeholder ? placeholder : __('csv.entries')}} {{__('csv.failed')}}</span>
						</div>
					</div>
				</div>
				<div class="row mt-4">
					<div class="col-12 text-center">
						<input
							v-for="file_input in file_inputs"
							:id="prefix + '_csv_upload_' + file_input"
							type="file"
							v-on:change="fileSelected"
							style="display: none"
						/>
						<input
							type="text"
							class="form-control d-block w-75 mx-auto mb-3 bg-white shadow"
							v-if="extra_name_field"
							v-model="name_field"
							:placeholder="__('place_holders.questionnaire_name_title')"
							v-bind:class="focus ? 'border border-danger' : ''"
						/>
						<div class="col-12 mt-3" v-if="folders">
							<select v-model="folder_selected" class="form-control w-75 mx-auto mb-3">
								<option v-for="(folder, index) in folders" :value="index">{{folder.name}}</option>
							</select>
						</div>
						<button
							class="btn btn-outline"
							v-on:click="openFileDialog()"
							v-bind:disabled="uploading"
							v-bind:class="focus ? 'border border-danger' : ''"
						>{{file ? __('buttons.change_file') : __('buttons.choose_file')}}</button>
					</div>
				</div>
				<div v-if="uploading" class="row">
					<div class="col-12 text-center">
						Uploading
					</div>
				</div>
				<div v-if="failed_rows.length" class="row mt-4 justify-content-center">
					<span class="csv-table--error-display" v-bind:class="current_error ? 'text-danger' : ''">{{current_error ? current_error : __('csv.hover_text')}}</span>
					<div class="csv-table--wrapper">
						<table class="csv-table">
							<thead>
							<tr>
								<th>#</th>
								<th v-for="field in fields">{{field}}</th>
							</tr>
							</thead>
							<tbody>
								<tr v-for="(row, index) in failed_rows" v-bind:class="row.error_type ? 'error' : ''">
									<td class="text-center">{{row.row_number}}</td>
									<td
										v-for="(column, field) in row.data"
										v-bind:class="row.error_type === 'validation' && row.errors[field] ? 'error' : ''"
										v-on:mouseover="showError(row, field)"
									>{{column}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-else-if="rows.length" class="row mt-3">
					<div class="col-12 text-center">
						<button class="btn btn-outline-primary" v-bind:disabled="saving" v-on:click="submitValid()">{{__('buttons.save')}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CSVUploadModal",
		props: [
			'upload_url',
			'submit_url',
			'fields',
			'questionnaire_id',
			'extra_name_field',

			'folders',
			'type',
			'campaign_id',

			'placeholder',
			'prefix'
		],

		data(){
			return {
				file: null,
				focus: false,
				uploading: false,

				saving: false,

				file_inputs: [0],

				name_field: '',
				folder_selected: 0,

                cache_key: null,

				failed_rows: [],
				rows: [],
				current_error: null,
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			closeModal(e, force = false){
				if (!force){
					if ($(e.target).closest('.feedo-modal--card').length){
						return;
					}
				}

				window.closeModal();
			},

			openFileDialog(){
				document.getElementById(this.prefix + '_csv_upload_' + this.file_inputs[this.file_inputs.length -1]).click();
				this.file_inputs.push(this.file_inputs.length);
			},

			fileSelected(event){
				this.file = event.target.files.length ? event.target.files[0] : null;
				this.validateFile();
			},

			showError(row, field){
				if (row.error_type === 'validation'){
					if (row.errors[field]){
						this.current_error = row.errors[field][0];
					}
					else {
						this.current_error = null;
					}
				}
				else if (row.error === 'fields') {
					this.current_error = '"Fields"';
				}
				else {
					this.current_error = null;
				}
			},

			validateFile(){
				if (!this.file){
					this.focus = true;
					return;
				}

				this.uploading = true;
				this.focus = false;

				let _this = this, formData = new FormData();
				formData.append('csv', this.file);

				axios.post(_this.upload_url, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
					.then(function (response){
						_this.uploading = false;

						_this.rows = [];
						_this.failed_rows = [];
						_this.cache_key = response.data.cache_key;
						response.data.results.forEach(function (row, index){
							_this.rows.push(row.data);
							if (!row.success){
								_this.failed_rows.push({
									row_number: index + 1,
									data: row.data,
									error_type: row.error,
									errors: row.validation
								});
							}
						});
					})
					.catch(function (){
						console.log('error');
						_this.focus = true;
						_this.uploading = false;
					});
			},


			submitValid(){
				if (this.rows.length <= 0 || this.uploading || this.saving){
					return;
				}

				let _this = this, data = _this.cache_key ? {cache_key: _this.cache_key} : {data: _this.rows};

				if (_this.questionnaire_id){
					data['questionnaire_id'] = _this.questionnaire_id;
					confirm(_this.__('messages.confirm_replace_current_questionnaire'), function (response){
						if (!response){
							return;
						}

						_this.request(data);
					})
				}
				else {
					if (_this.extra_name_field){
						if (_this.name_field.trim() === ''){
							_this.focus = true;
							return;
						}

						data['name'] = _this.name_field;
					}

					if (_this.folders){
						data['folder_id'] = _this.folders[_this.folder_selected].id;
					}

					if (_this.type){
						data['type'] = _this.type;
					}

					if (_this.campaign_id){
						data['campaign_id'] = _this.campaign_id;
					}

					_this.request(data);
				}
			},

			request(data){
				if (this.rows.length <= 0 || this.uploading || this.saving){
					return;
				}

				let _this = this;

				_this.saving = true;


				axios.post(_this.submit_url, data)
					.then(function (response) {
						_this.$emit('submitted', response.data);
						_this.rows = [];
						_this.failed_rows = [];
						_this.file = null;
						_this.saving = false;
						_this.closeModal(null, true);
					})
					.catch(function (error){
						_this.saving = false;

						for (var i in error.response.data.errors) {
							if (error.response.data.errors.hasOwnProperty(i)) {
								toastr.error(error.response.data.errors[i][0]);
							}
						}
					});
			},

			sortByFailed(a, b) {
				if (a.error_type && b.error_type){
					if (a.row_number < b.row_number)
						return -1;
					if (a.row_number > b.row_number)
						return 1;
					return 0;
				}
				else if (a.error_type && !b.error_type){
					return -1;
				}
				else {
					return 1;
				}
			},
		}
	}
</script>
