<template>
	<div class="reporting-page--section" data-section="high_low">
		<h4 class="reporting-page--section-header-title">{{__('translation.reporting_section_high_low')}}</h4>

		<div v-if="loading" class="loaders--standard override"></div>

		<!--data-->
		<div class="row mt-4" v-else>

			<div class="col-12 my-2">
				<!--High leaderboard-->
				<collapsable :title="__('translation.high')">
					<hr>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{__('reporting.insights.high_description')}}
                        </div>
                    </div>

					<leaderboard-item-card
						v-for="(high, index) in data.high"
						:item="high"

						:key="index"

						:primary_color="primary_color"
						:secondary_color="secondary_color"
						:primary_color_faded="primary_color_faded"
						:secondary_color_faded="secondary_color_faded"
					></leaderboard-item-card>
				</collapsable>
			</div>
			<div class="col-12 my-2">
				<!--Low leaderboard-->
				<collapsable :title="__('translation.low')">
					<hr>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{__('reporting.insights.low_description')}}
                        </div>
                    </div>

					<leaderboard-item-card
						v-for="(low, index) in data.low"
						:item="low"

						:key="index"

						:primary_color="primary_color"
						:secondary_color="secondary_color"
						:primary_color_faded="primary_color_faded"
						:secondary_color_faded="secondary_color_faded"
					></leaderboard-item-card>
				</collapsable>
			</div>
		</div>
		<hr v-if="!loading" />
	</div>
</template>

<script>
	import Collapsable from "./Collapsable";
	import LeaderboardItemCard from "./LeaderboardItemCard";

	export default {
		name: "HighLowComparison",
		components: {LeaderboardItemCard, Collapsable},
		props: [
			'load_url',
			'token',

			'primary_color',
			'secondary_color',
			'primary_color_faded',
			'secondary_color_faded'
		],


		data(){
			return {
				loading: true,
				pre_loading: true,

				data: {},

			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			loadData(){
				let _this = this;

				_this.loading = true;

				axios.post(_this.load_url, {
					token: this.token,
					code: 'high_low'
				})
					.then(function (response){
						_this.loading = false;

						_this.data = response.data;
						//Parsing PDF DATA
						let pdf_data = {
							primary_color: _this.primary_color,
							secondary_color: _this.secondary_color,
							primary_color_faded: _this.primary_color_faded,
							secondary_color_faded: _this.secondary_color_faded,
							high: _this.data.high,
							low: _this.data.low,
						};
						_this.$emit('loaded', pdf_data);
					})
					.catch(function (){
						//@todo
					})
			}
		},

		watch: {
			token() {
				this.$emit('unloaded');
				if (this.token !== null) {
					this.pre_loading = false;
					this.loading = false;
					this.loadData();
				}
				else {
					this.pre_loading = true;
					this.loading = true;
				}
			}
		}
	}
</script>
