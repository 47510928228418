<template>
	<div class="w-100 d-inline-flex color-input">
		<input type="text" class="form-control color-input--input" :name="name" v-model="color">
		<div class="color-input--button" :style="{'background-color': color}" :id="'color_picker_' + _uid"></div>
	</div>
</template>

<script>
	export default {
		name: "ColorPickerInputComponent",

		props: [
			'value',
			'name'
		],

		data(){
			return {
				color: '',
			}
		},

		mounted(){
			let _this = this;

			_this.color = _this.value;

			$('#color_picker_' + _this._uid).ColorPicker({
				color: _this.color,
				onChange: function (hsb, hex, rgb) {
					SaveProtection.lockWindow();
					_this.color = '#' + hex.toUpperCase();
				}
			})
		}
	}
</script>