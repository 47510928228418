<template>
    <div class="container mt-4">
        <div class="row">
            <!--Main Body-->
            <div v-if="help_sections_edit.length" class="col-md-10 order-1 order-md-0">
                <div class="container p-0">
                    <div class="row">
                        <!--Folder Select-->
                        <div class="col-lg-4">
                            <ul class="list-group">
                                <draggable v-model="help_sections_edit" @end="orderSection">
                                    <transition-group name="list-transition">
                                        <li v-for="(section, index) in help_sections_edit" class="list-group-item" :key="section.id">
                                            <a href="javascript:void(0)" v-on:click="selectSection(index)">
                                                <span v-bind:class="index === selected_section ? 'text-primary' : ''">{{section.name}}</span>
                                                <img v-if="index === selected_section" class="d-block my-auto" src="/svg/chevron-active.svg" />
                                                <img v-else class="d-block my-auto" src="/svg/chevron-inactive.svg" />
                                            </a>
                                        </li>
                                    </transition-group>
                                </draggable>
                            </ul>
                        </div>

                        <!--Table-->
                        <div class="col-lg-8">
                            <draggable v-if="help_sections_edit[selected_section].help_tips.length" v-model="help_sections_edit[selected_section].help_tips" @end="orderTip">
                                <transition-group name="list-transition">
                                    <div
                                        v-for="(help_tip, index) in help_sections_edit[selected_section].help_tips"
                                        class="questionnaire_edit_table--question-select-row list-transition-item"
                                        :key="help_tip.id"
                                    >
                                        <div class="questionnaire_edit_table--question-select-row-question">
                                            <p class="">
                                                {{help_tip.name}}
                                            </p>
                                            <pre style="white-space: normal; font-size: 75%; overflow: hidden;">{{help_tip.text}}</pre>
                                        </div>
                                        <a href="javascript:void(0)" v-on:click="editHelpTip(index)" class="questionnaire_edit_table--question-select-row-button">
                                            <img src="/svg/edit_icon.svg">
                                        </a>
                                        <a href="javascript:void(0)" v-on:click="removeHelpTip(index)" class="questionnaire_edit_table--question-select-row-button pl-0">
                                            <img src="/svg/delete-question.svg">
                                        </a>
                                    </div>
                                </transition-group>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="col-md-5 offset-md-3 order-5 mt-5 pt-5 text-center">
                <img src="/svg/questionnaire_folder.svg" alt="Questionnaire Folder">
                <button
                    v-on:click="openCrudSection"
                    class="d-block px-3 mx-auto btn btn-outline mt-4"
                >{{__('buttons.create_help_section')}}</button>
                <h6 class="mt-4 text-primary">{{__('translation.make_help_tip')}}</h6>
            </div>


            <!--Side Actions-->
            <div v-if="help_sections_edit.length" class="col-md-2 order-0 order-md-1 right-side-list">
                <a
                    v-on:click="openCrudSection"
                    href="javascript:void(0)"
                    class="right-side-list--item"
                >{{__('buttons.create_questionnaire_section_button')}}</a>
                <hr />
                <a
                    v-on:click="changeSectionName"
                    href="javascript:void(0)"
                    class="right-side-list--item"
                >{{__('buttons.rename_section_button')}}</a>
                <a
                    v-on:click="deleteSection"
                    href="javascript:void(0)"
                    class="right-side-list--item"
                >{{__('buttons.delete_section_button')}}</a>
                <hr />
                <a
                    v-on:click="openCreateHelpTip"
                    href="javascript:void(0)"
                    class="right-side-list--item"
                >{{__('buttons.create_help_tip_button')}}</a>
            </div>
        </div>

        <crud-section-modal
            id="crud-section-modal"
            :section="help_section_edit"
            :update_url="update_url"
            @saved="reload"
            @closed="help_section_edit = null"
        ></crud-section-modal>

        <crud-help-tip-modal
            v-if="help_sections_edit.length && help_sections_edit[selected_section]"

            id="crud-help-modal"
            :help_tip="help_tip_edit"

            :create_edit_url="create_edit_url.replace('?', help_sections_edit[selected_section].id)"
            @saved="savedHelpTip"
            @closed="help_tip_edit = null"
        ></crud-help-tip-modal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
	import CrudSectionModal from "../modals/CrudSectionModal";
    import CrudHelpTipModal from "../modals/CrudHelpTipModal";
    export default {
		name: "HelpEditPage",
        components: {CrudHelpTipModal, CrudSectionModal, draggable},
        props: [
            'help_sections',

            'update_url',
            'create_edit_url',
            'delete_help_section_url',
            'delete_help_tip_url'
        ],
        data(){
		    return {
                selected_section: 0,
                help_tip_edit: null,
                help_section_edit: null,
                help_sections_edit: []
            }
        },

        beforeMount() {
		    this.help_sections_edit = this.help_sections;
        },

        methods: {
            '__': function(val){
                return window.Lang.exists(val) ? window.Lang.translate(val) : val;
            },

            toggleExpanded(help_tip){
                Vue.set(help_tip, 'expanded', !help_tip.expanded);
            },

            openCrudSection(){
                openModal($('#crud-section-modal'));
            },
            changeSectionName(){
                this.help_section_edit = this.help_sections_edit[this.selected_section];
                this.$nextTick(function (){
                    openModal($('#crud-section-modal'));
                })
            },
            openCreateHelpTip(){
                openModal($('#crud-help-modal'));
            },
            savedHelpTip(help_tip){
                for (let i = 0; i < this.help_sections_edit[this.selected_section].help_tips.length; i ++){
                    if (this.help_sections_edit[this.selected_section].help_tips[i].id === help_tip.id) {
                        this.help_sections_edit[this.selected_section].help_tips[i] = help_tip;
                        return;
                    }
                }

                this.help_sections_edit[this.selected_section].help_tips.push(help_tip);
            },

            selectSection(index){
                this.selected_section = index;
            },

            deleteSection(){
                let _this = this;
                window.confirm(_this.__('messages.confirm_delete_section'), function (confirmed){
                    if (!confirmed) return;

                    axios.delete(_this.delete_help_section_url.replace('?', _this.help_sections_edit[_this.selected_section].id))
                    .then(_this.reload())
                    .catch(_this.reload());
                });
            },

            editHelpTip(index){
                this.help_tip_edit = this.help_sections_edit[this.selected_section].help_tips[index];
                this.$nextTick(function (){
                    openModal($('#crud-help-modal'));
                });
            },

            removeHelpTip(index){
                let help_section =  this.help_sections_edit[this.selected_section];
                axios.delete(this.delete_help_tip_url.replace('?S', help_section.id).replace('?', help_section.help_tips[index].id));
                help_section.help_tips.splice(index, 1);
            },

            orderSection(data){
                let
                    new_index = data.newIndex,
                    moved = data.newIndex !== data.oldIndex,
                    section = this.help_sections_edit[new_index];

                if (!moved) return;

                axios.put(this.update_url.replace('?', section.id), {
                    order: new_index
                });
            },
            orderTip(data){
                let
                    new_index = data.newIndex,
                    moved = data.newIndex !== data.oldIndex,
                    section = this.help_sections_edit[this.selected_section],
                    help_tip = section.help_tips[new_index];

                if (!moved) return;

                axios.post(this.create_edit_url.replace('?', section.id), {
                    id: help_tip.id,
                    order: new_index
                });
            },

            reload(){
                window.location.reload();
            }
        }
	}
</script>
