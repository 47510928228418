<template>
	<div class="container px-0">
		<slider-rating
			ref="rating"
			v-if="rating_system.code === 'slider'"
			:options="rating_system.rating_options"
			:answer="answer"
			@select="select"
		></slider-rating>

		<text-rating
			ref="rating"
			v-else-if="rating_system.code === 'text'"
			:options="rating_system.rating_options"
			:answer="answer"
			@select="select"
		></text-rating>

		<smileys-rating
			ref="rating"
			v-else-if="rating_system.code === 'smileys'"
			:options="rating_system.rating_options"
			:answer="answer"
			@select="select"
		></smileys-rating>

		<star-rating
			ref="rating"
			v-else-if="rating_system.code === 'stars'"
			:options="rating_system.rating_options"
			:answer="answer"
			@select="select"
		></star-rating>

		<numbers-rating
			ref="rating"
			v-else-if="rating_system.code === 'numbers'"
			:options="rating_system.rating_options"
			:answer="answer"
			@select="select"
		></numbers-rating>

		<percentage-rating
			ref="rating"
			v-else-if="rating_system.code === 'percentage'"
			:options="rating_system.rating_options"
			:answer="answer"
			@select="select"
		></percentage-rating>
	</div>
</template>

<script>
	import SliderRating from "./ratings/SliderRating";
	import TextRating from "./ratings/TextRating";
	import SmileysRating from "./ratings/SmileysRating";
	import StarRating from "./ratings/StarRating";
	import NumbersRating from "./ratings/NumbersRating";
	import PercentageRating from "./ratings/PercentageRating";

	export default {
		name: "RatingAnswerComponent",
		components: {PercentageRating, NumbersRating, StarRating, SmileysRating, TextRating, SliderRating},
		props: [
			'rating_system',
			'answer'
		],

		methods: {
			select (data){
				this.$emit('rating', data.number);
			},

			reset(){
				this.$refs.rating.reset();
			}
		}
	}
</script>