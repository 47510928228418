<template>
	<div :id="'card-' + _uid" class="rating-cards--no-selection" v-on:click="openUrl()">
		<div class="rating-cards--saved-card">
			<div class="rating-cards--saved-card-type">
				<img draggable="false" :src="rating.icon_url">
			</div>
			<div class="rating-cards--saved-card-footer">
				<div>
					<span class="d-block">{{__('translation.rating_types.' + rating.code)}}</span>
					<span class="d-block text-grey">{{rating.option_na ? 'N/A, ' : ''}}{{rating.range}}</span>
				</div>
				<div class="position-relative">
					<button v-on:click="showDropdown" class="btn btn-dropdown mt-3">
						<img draggable="false" src="/svg/dropdown_orange.svg">
					</button>
					<div v-if="dropdown_visible" v-on:click="preventPropagation" class="feedo-dropdown">
						<a :href="url">{{__('buttons.edit')}}</a>
						<a href="javascript:void(0)" v-on:click="deleteRating" class="text-primary">{{__('buttons.delete')}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "RatingCard",
		props: [
			'url',
			'delete_url',
			'rating'
		],

		data(){
			return {
				dropdown_visible: false,
			}
		},

		mounted() {
			let _this = this;
			document.addEventListener('click', function (e){
				if (e.target === document.getElementById('card-' + _this._uid)){
					return;
				}
				_this.dropdown_visible = false;
			})
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			openUrl(){
				window.location.href = this.url;
			},

			showDropdown(e){
				e.preventDefault();
				e.stopPropagation();
				this.dropdown_visible = !this.dropdown_visible;
			},

			preventPropagation(e){
				e.preventDefault();
				e.stopPropagation();
			},

			deleteRating(){
				let _this = this;

				confirm(_this.__('messages.are_you_sure_delete_rating_system'), function (response){
					if (!response){
						return;
					}
					axios.delete(_this.delete_url)
						.then(function (){
							window.location.reload();
						})
				})
			}
		}
	}
</script>