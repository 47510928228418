<template>
	<div class="container answer-page--questionnaire-page mt-md-4 px-0 pb-5">
		<div class="row justify-content-center">
			<div class="col-7">
				<h5 class="mb-0">{{getParticipant()}}</h5>
				<span v-if="started && questions[selected_question]" class="text-primary">{{campaign.campaign_type_id === 4 ? campaign.campaign_type.name : 'Section: ' + questions[selected_question].section}}</span>
			</div>
			<div class="col-5 d-flex align-content-end">
                <a v-if="!finished && !completed && back_url" :href="back_url" class="btn btn-primary px-2 my-auto ml-auto">{{__('buttons.close')}}</a>
                <a v-if="completed && back_url" :href="back_url" class="btn btn-primary px-2 my-auto ml-auto">{{__('buttons.complete')}}</a>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-12">
				<div v-if="!started" class="answer-page--questionnaire-page-card no-min-height p-4">
					<div class="text-sm-center">
						<h1 class="text-primary">{{campaign.name}}</h1>
					</div>
					<pre v-html="campaign.instruction"></pre>
				</div>


				<!--Body-->
				<div class="answer-page--questionnaire-page-card" v-else-if="!finished && started">
					<!--Progress-->
					<div class="row">
						<div class="col-6 answer-page--questionnaire-page-card-progress">
							<strong>{{selected_question + 1}}</strong>
							<span>{{__('place_holders.of') + ' ' + questions.length}}</span>
						</div>
						<div class="col-6 text-right">
							<vue-circle
								ref="progress"
								:progress="percentage"
								:size="35"
								:fill="{color:'#f0612e'}"
								:animation="{duration: 0}"
								:reverse="false"
								line-cap="round"
								empty-fill="rgba(0, 0, 0, .1)"
								:animation-start-value="0.0"
								:start-angle="4.7"
								insert-mode="append"
								:thickness="3"
								:show-percent="false"
							></vue-circle>
						</div>
					</div>

					<!--Question-->
					<div class="row mt-2 mt-md-4 answer-page--questionnaire-page-card-question">
						<div class="col-12">
							<p class="d-md-none mb-0">{{questions[selected_question].question}}</p>
							<h4 class="d-none d-md-inline">{{questions[selected_question].question}}</h4>
						</div>
					</div>

					<!--Answer-->
					<div v-if="!questions[selected_question].open_ended && !questions[selected_question].yes_no_question" class="row mt-2">
						<div class="col-12">
							<rating-answer-component
								ref="rating_answer"
								:rating_system="campaign.rating_scale"
								:answer="answers[selected_question]"
								@rating="answer"
							></rating-answer-component>
						</div>
					</div>

					<!--Open Ended Answer-->
					<div v-else-if="questions[selected_question].open_ended" class="row">
						<div class="col-12 mb-5">
							<div class="container-fluid mx-0 px-0">
								<div class="row justify-content-center">
									<div class="col-md-10">
										<textarea
											v-model="open_text"
											class="form-control"
											rows="8"
											style="resize: none"
										></textarea>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!--Yes No-->
					<div v-else-if="questions[selected_question].yes_no_question" class="row">
						<div class="col-12 h-100">
							<div class="container-fluid text-center px-0">
								<div class="row mb-5 justify-content-center align-content-center w-100" style="min-height: 200px;display: -webkit-inline-box;-webkit-box-pack: center;-webkit-box-align: center;">
									<div class="col-md-10 d-flex justify-content-center">
										<button
											class="btn btn-primary btn-yes_no ml-1"
											v-on:click="answerYesNo(false)"
                                            v-bind:disabled="submittingRequest"
											v-bind:class="getYesNoActiveClass(false)"
										>
											<img src="/svg/white-cross.svg"  alt="">
											<span class="pl-1">{{__('buttons.no')}}</span>
											<span></span>
										</button>

										<button
											class="btn btn-primary btn-yes_no mr-1"
											v-on:click="answerYesNo(true)"
                                            v-bind:disabled="submittingRequest"
											v-bind:class="getYesNoActiveClass(true)"
										>
											<img src="/svg/white-tick.svg" alt="">
											<span class="pl-1">{{__('buttons.yes')}}</span>
											<span></span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--Finish Card-->
				<div class="answer-page--questionnaire-page-card" v-else-if="finished">
					<div class="mt-3 mt-md-5 mb-4 text-center">
						<final-animation
                            :answered_percentage="answered_percentage"
                        ></final-animation>
						<h4 v-if="answered_percentage == 100" class="font-weight-bold text-primary">{{__('place_holders.questionnaire_completed')}}</h4>
						<h5 v-if="answered_percentage == 100" class="font-weight-bold text-primary">{{__('place_holders.questionnaire_completed_thank_you')}}</h5>

                        <a
                            href="javascript:void(0)"
                            v-on:click="gotoUnanswered()"
                            v-if="actually_unanswered.length > 0"
                            class="d-block my-auto ml-2"
                        >
                            <span class="text-primary d-none d-sm-block">{{__('buttons.goto_question') + ' ' + (actually_unanswered[0].key + 1)}}</span>
                            <span class="text-primary d-block d-sm-none">{{__('buttons.goto_q') + (actually_unanswered[0].key + 1)}}</span>
                        </a>

						<!--<final-comment-component-->
						<!--	:submit_comment_url="submit_comment_url"-->
						<!--&gt;</final-comment-component>-->
						<a v-if="back_url" :href="back_url" class="btn btn-primary mt-5">{{__('buttons.go_to_workspace')}}</a>
					</div>
				</div>
			</div>
		</div>

		<!--Footer-->
		<div v-if="!finished" class="answer-page--questionnaire-page--footer" v-bind:class="!started ? 'no-bg' : ''">
			<div class="container my-auto" style="max-width: 800px;">
				<div v-if="started" class="w-100 d-flex justify-content-between">
					<div class="d-flex">
						<a
							href="javascript:void(0)"
							v-on:click="gotoUnanswered()"
							v-if="unanswered_questions.length > 0"
							class="d-block my-auto ml-2"
						>
							<span class="text-primary d-none d-sm-block">{{__('buttons.goto_question') + ' ' + (unanswered_questions[0].key + 1)}}</span>
							<span class="text-primary d-block d-sm-none">{{__('buttons.goto_q') + (unanswered_questions[0].key + 1)}}</span>
						</a>
					</div>
					<div class="d-flex">
                        <button
                            v-if="campaign.rating_scale.option_na"
                            v-on:click="skip()"
                            v-bind:disabled="submittingRequest"
                            v-bind:class="getNAActiveClass()"
                            class="d-flex my-auto btn btn-answer na-answer"
                        >
                            <span class="m-auto text-primary">N/A</span>
                        </button>
                        <a
                            v-if="selected_question !== 0"
                            href="javascript:void(0)"
                            v-on:click="prevQuestion()"
                            class="d-flex d-sm-none my-auto btn btn-answer"
                            v-bind:class="selected_question === 0 ? 'disabled' : ''"
                        ><img class="m-auto" src="/svg/chevron-active.svg" style="-webkit-transform: scaleX(-1);transform: scaleX(-1);"></a>
                        <a
                            href="javascript:void(0)"
                            v-on:click="nextQuestion()"
                            class="d-flex d-sm-none my-auto btn btn-answer"
                        ><img class="m-auto" src="/svg/chevron-active.svg"></a>
                        <a
                            v-if="selected_question !== 0"
                            href="javascript:void(0)"
                            v-on:click="prevQuestion()"
                            class="d-none d-sm-flex my-auto btn btn-answer"
                            v-bind:class="selected_question === 0 ? 'disabled' : ''"
                        ><span class="m-auto text-primary">Back</span></a>
                        <a
                            href="javascript:void(0)"
                            v-on:click="nextQuestion()"
                            class="d-none d-sm-flex my-auto btn btn-answer"
                        ><span class="m-auto text-primary">Next</span></a>

					</div>
<!--                    v-if="questions[selected_question].open_ended || (selected_question < questions.length - 1)"-->
<!--                    BEFORE: NEXT BUTTONS HAD THIS. NOW NO LONGER NEEDED -->
				</div>


				<!--Start Questionnaire-->
				<div v-else-if="!started" class="row">
					<div class="col-12 text-center">
						<button
							class="btn btn-primary btn-square mb-4"
							v-on:click="started = true"
						>{{__('buttons.start_questionnaire')}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import RatingAnswerComponent from "./RatingAnswerComponent";
	import FinalAnimation from "./FinalAnimation";
	import FinalCommentComponent from "./FinalCommentComponent";
	export default {
		name: "QuestionnaireAnswersPage",
		components: {FinalCommentComponent, FinalAnimation, RatingAnswerComponent},
		props: [
			'back_url',
			'update_url',
			'finished_url',
			'submit_comment_url',
			'campaign_participant',
			'questionnaire',
			'actualAnswers'
		],

		data(){
			return {
				answers: [],
				questions: [],
                unanswered_questions: [],
                actually_unanswered: [],
				campaign: null,

				open_text: '',
				open_debounce_timer: null,
                savingOpenText: false,

				answered: false,
				skipping: false,

                na_answer: false,
                yes_no_answer: null,
                questions_actually_answered: 0,
                answered_percentage: 0,

				selected_question: 0,

				submit_debounce_timer: null,

                submitting: false,
                submittingRequest: false,

				started: false,

                finished: false,
                completed: false,

                currentAnswer: null
			}
		},

		beforeMount(){
			let _this = this;

			_this.questionnaire.sections.forEach(function (section){
				section.questions.forEach(function (question){
					let _question = Object.assign(question);

					_question.section = section.name;
					_this.questions.push(question);
				});
			});

			_this.answers = _this.actualAnswers;
			_this.campaign = _this.campaign_participant.campaign;

			// Workaround for answers not being saved - prevent finish screen from appearing if 1 question was missing
			// as we will manually insert a blank answer for each missing question for them to populate
			let dontFinish = false;

			_this.questions.forEach((v, k) => {
				if (!_this.answers.find(answer => answer.questionnaire_question_id == v.id)) {
					_this.answers.splice(k, 0, {
						rating: null,
						open_ended_answer: null,
                        yes_no_answer: null,
                        na_answer: null,
						questionnaire_question_id: v.id,
						campaign_participant_id: _this.campaign_participant.id
					});

					_this.unanswered_questions.push({ key: k });

					dontFinish = true;
				}
			});

			_this.handleUnanswered();

			if (
				!_this.campaign.instruction ||
				_this.campaign.instruction.replace('&nbsp;', '').trim() === '' ||
				_this.questions_actually_answered > 0
			) {
				_this.started = true;
			}

			if (!dontFinish) {
				_this.selected_question = _this.answers.length;
				if (_this.selected_question >= _this.questions.length){
					_this.finished = true;
				}
			} else {
				this.resetAnswers(0);
			}
			this.getCurrentAnswer(0);
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			answer(i){
                this.setCurrentAnswer(i)
			},

			prevQuestion(){
                let question_to_save = this.selected_question;
                let question_to_go_to = this.selected_question - 1;
			    this.saveQuestion(question_to_save);

				if (question_to_go_to >= 0 && !this.submitting){
					this.resetAnswers(question_to_go_to);
                    this.getCurrentAnswer(question_to_go_to);
                    this.selected_question = question_to_go_to;
				}
			},

			nextQuestion(){
                let question_to_save = this.selected_question;
                let question_to_go_to = this.selected_question + 1;

                this.saveQuestion(question_to_save);

                if (question_to_go_to !== this.questions.length && !this.submitting){
                    this.resetAnswers(question_to_go_to);
                    this.getCurrentAnswer(question_to_go_to);
                    this.selected_question = question_to_go_to;
                }
                if (question_to_go_to === this.questions.length){
                    this.handleUnanswered();
                    this.finished = true;
                }

                // let _this = this;
                //
				// if (this.questions[this.selected_question].open_ended) {
				// 	if (!this.feedback()) {
				// 		return;
                //     }
				// 	else{
				// 	    //handle question submit is handled elsewhere anyway.
                //     }
				// }
				// else if(this.questions[this.selected_question].yes_no_question){
                //     _this.submittingRequest = true;
                //
                //     axios.put(_this.update_url, {
                //         question_id: _this.questions[_this.selected_question].id,
                //         yes_no_answer: _this.yes_no_answer
                //     })
                //         .then (function (){
                //             _this.submittingRequest = false;
                //
                //             if (_this.selected_question !== _this.answers.length){
                //                 _this.answers[_this.selected_question].yes_no_answer =  _this.yes_no_answer;
                //             }
                //             else {
                //                 _this.answers.push({
                //                     rating: null,
                //                     open_ended_answer: null,
                //                     na_answer: null,
                //                     yes_no_answer:  _this.yes_no_answer,
                //                     questionnaire_question_id: _this.questions[_this.selected_question].id,
                //                     campaign_participant_id: _this.campaign_participant.id
                //                 });
                //             }
                //
                //             _this.next();
                //         })
                //         .catch(function (error) {
                //             toastr.error('Something went wrong: ' + error);
                //
                //             for (var i in error.response.data.errors) {
                //                 if (error.response.data.errors.hasOwnProperty(i)) {
                //                     toastr.error(error.response.data.errors[i][0]);
                //                 }
                //             }
                //         });;
                // }
				// else{
                //
                //     if (!_this.answers.length || _this.selected_question === _this.answers.length){
                //
                //         if (_this.submit_debounce_timer){
                //             clearInterval(_this.submit_debounce_timer);
                //         }
                //
                //         _this.submit_debounce_timer = setTimeout(function (){
                //             _this.submitQuestion(i);
                //         }, 300);
                //
                //         _this.submitting = true;
                //     }
                //
                //     _this.submittingRequest = true;
                //     axios.put(_this.update_url, {
                //         question_id: _this.questions[_this.selected_question].id,
                //         rating: _this.currentAnswer
                //     })
                //         .then(function (response) {
                //             _this.submittingRequest = false;
                //             if (_this.selected_question !== _this.answers.length){
                //                 _this.answers[_this.selected_question].rating = response.data.rating;
                //             }
                //
                //             _this.next();
                //         })
                //         .catch(function (error) {
                //             toastr.error('Something went wrong: ' + error);
                //
                //             for (var i in error.response.data.errors) {
                //                 if (error.response.data.errors.hasOwnProperty(i)) {
                //                     toastr.error(error.response.data.errors[i][0]);
                //                 }
                //             }
                //         });
                //
                // }
                //
				// if (this.selected_question !== this.questions.length && !this.submitting){
				// 	// this.selected_question ++;
				// 	this.resetAnswers();
                //     this.setCurrentAnswer()
				// }
			},
            skip(){
                let _this = this;

                if (!_this.campaign.rating_scale.option_na)
                    return;

                // if (_this.skipping)
                //     return;
                //
                // if (_this.submit_debounce_timer){
                //     clearInterval(_this.submit_debounce_timer);
                // }

                let question_to_save = this.selected_question;
                let question_to_go_to = this.selected_question + 1;

                this.saveQuestion(question_to_save, true);

                if (question_to_go_to !== this.questions.length && !this.submitting){
                    this.resetAnswers(question_to_go_to);
                    this.getCurrentAnswer(question_to_go_to);
                    this.selected_question = question_to_go_to;
                }
                //
                //
                // _this.skipping = true;
                // _this.submittingRequest = true;
                //
                // axios.put(_this.update_url, {
                //     question_id: _this.questions[_this.selected_question].id,
                //     rating: null,
                //     na_answer: true
                // })
                //     .then (function (){
                //         if (_this.answers[_this.selected_question]){
                //             _this.answers[_this.selected_question].rating = null;
                //             _this.answers[_this.selected_question].open_text = null;
                //             _this.answers[_this.selected_question].yes_no_answer = null;
                //             _this.answers[_this.selected_question].na_answer = 1;
                //
                //             _this.yes_no_answer = null;
                //         }
                //         else {
                //             _this.answers.push({
                //                 rating: null,
                //                 open_text: null,
                //                 yes_no_answer: null,
                //                 questionnaire_question_id: _this.questions[_this.selected_question].id,
                //                 campaign_participant_id: _this.campaign_participant.id
                //             });
                //
                //         }
                //
                //         _this.skipping = false;
                //         _this.submittingRequest = false;
                //         _this.next();
                //     })
                //     .catch(function (error) {
                //         toastr.error('Something went wrong: ' + error);
                //
                //         for (var i in error.response.data.errors) {
                //             if (error.response.data.errors.hasOwnProperty(i)) {
                //                 toastr.error(error.response.data.errors[i][0]);
                //             }
                //         }
                //     });
            },

            gotoUnanswered(){

                let question_to_save = this.selected_question;
                let question_to_go_to = null;
                if(this.finished) {
                    question_to_go_to = this.actually_unanswered[0].key;
                } else {
                    question_to_go_to = this.unanswered_questions[0].key;
                    this.saveQuestion(question_to_save);
                }


                this.resetAnswers(question_to_go_to);
                this.getCurrentAnswer(question_to_go_to);
                this.selected_question = question_to_go_to;
                this.finished = false;

                //
                // this.finished= false;
                //
                // if (this.questions[this.selected_question].open_ended) {
                // 	if (!this.feedback()) {
                // 		return;
                // 	}
                // }
                //
                // if (!this.submitting){
                // 	this.selected_question = this.unanswered_questions[0].key;
                //
                // 	this.resetAnswers();
                // }
            },

            async saveQuestion(question_to_save, n_a = false) {
                let _this = this;
                let savedata =  null;

                if(n_a) {
                    savedata = {
                        question_id: _this.questions[_this.selected_question].id,
                        rating: null,
                        yes_no_answer: null,
                        open_ended_answer: null,
                        na_answer: true
                    };

                    _this.answers[question_to_save].rating = null
                    _this.answers[question_to_save].yes_no_answer = null
                    _this.answers[question_to_save].open_ended_answer = null
                    _this.answers[question_to_save].na_answer = true
                    _this.rating = _this.null;
                    _this.na_answer = true

                } else {

                    if(_this.questions[question_to_save].yes_no_question && _this.yes_no_answer !=null) {
                        savedata = {
                            question_id: _this.questions[question_to_save].id,
                            yes_no_answer: _this.yes_no_answer,
                            na_answer: false
                        };
                    } else if (this.questions[question_to_save].open_ended) {
                        let text = _this.open_text;
                        if (!text.trim()){
                            text = null;
                        }
                        if(text != null) {
                            _this.answers[question_to_save].open_ended_answer = text;

                            savedata = {
                                question_id: _this.questions[question_to_save].id,
                                open_ended_answer: text,
                                na_answer: false
                            };
                        }
                    } else if( _this.currentAnswer != null) {

                        _this.answers[question_to_save].rating = _this.currentAnswer
                        _this.rating = _this.currentAnswer;

                        savedata = {
                            question_id: _this.questions[question_to_save].id,
                            rating: _this.currentAnswer,
                            na_answer: false
                        };
                    }
                }

                if(savedata != null) {
                    _this.submittingRequest = true;

                    axios.put(_this.update_url, savedata)
                        .then(function () {
                        })
                        .catch(function (error) {
                            toastr.error('Something went wrong: ' + error);

                            for (var i in error.response.data.errors) {
                                if (error.response.data.errors.hasOwnProperty(i)) {
                                    toastr.error(error.response.data.errors[i][0]);
                                }
                            }
                        });

                    _this.submittingRequest = false;
                }



            },


			resetAnswers(reset_question){
				this.yes_no_answer = null;
				this.open_text = '';

				if (this.answers[reset_question] != null && !this.questions[reset_question].open_ended && !this.questions[reset_question].yes_no_question && !this.questions[reset_question].na_answer) {
					this.$nextTick(function (){
					    if(this.$refs.rating_answer) {
                            this.$refs.rating_answer.reset();
                        }
					})
				}

				if (this.answers[reset_question] != null){
					this.open_text = this.answers[reset_question].open_ended_answer || '';
					this.yes_no_answer = this.answers[reset_question].yes_no_answer;
				}
			},

            setCurrentAnswer(i)
            {
                this.currentAnswer = i;
                let _this = this;
                _this.answers[_this.selected_question].na_answer = false
                _this.na_answer = false;

            },

            getCurrentAnswer(get_answer_for_question)
            {
                if (this.answers[get_answer_for_question]){
                    this.currentAnswer = this.answers[get_answer_for_question].rating;
                    this.open_text = this.answers[get_answer_for_question].open_ended_answer || '';
                    this.yes_no_answer = this.answers[get_answer_for_question].yes_no_answer;
                    this.na_answer = this.answers[get_answer_for_question].na_answer;
                }

            },

			// submitQuestion(i){
			// 	if (this.answers[this.selected_question]){
			// 		this.answers[this.selected_question].rating = i;
			// 	}
			// 	else {
			// 		this.answers.push({
			// 			rating: i,
			// 			open_ended_answer: null,
            //             yes_no_answer: null,
            //             na_answer: null,
			// 			questionnaire_question_id: this.questions[this.selected_question].id,
			// 			campaign_participant_id: this.campaign_participant.id
			// 		});
			// 	}
            //
			// 	this.submitting = false;
            //
			// 	//Let's make sure we've waited for the request to complete, otherwise we might skip questions
            //     (async () => {
            //         //While the request is submitting, we'll wait 100 ms
            //         while (this.submittingRequest) await new Promise(res => setTimeout(res, 100));
            //
            //         this.next();
            //     })();
			// },

            answerYesNo(answer){
                let _this = this;
                _this.answers[_this.selected_question].yes_no_answer = answer
                _this.answers[_this.selected_question].na_answer = false
                _this.yes_no_answer = answer;
                _this.na_answer = false;
            },

            // next(){
            //     //We need to do some validation to make sure there's no skipped questions or missing data
            //     if (!this.answers[this.selected_question] || this.answers[this.selected_question].questionnaire_question_id !== this.questions[this.selected_question].id){
            //         //Something went wrong with the answers and we have either questions with no answers or duplicated answers, let's refresh
            //         window.location.reload();
            //     }
            //
            //     this.answered = true;
            //
            //     if (this.questions.length -1 === this.selected_question){
            //         this.handleUnanswered();
            //         this.unanswered_questions = this.actually_unanswered;
            //         this.finished = true;
            //         axios.post(this.finished_url);
            //     }
            //     else {
            //         this.selected_question ++;
            //         this.answered = false;
            //         this.resetAnswers();
            //     }
            // },

			// feedback(){
			// 	let
			// 		_this = this,
			// 		text = _this.open_text;
            //
			// 	if (!text.trim()){ //if we don't have text we can't submit this field as N/A is a different submission
            //         _this.next();
			// 		return false;
			// 	}
			// 	else if (!text.trim()){
			// 		text = null;
			// 	}
            //
			// 	_this.submittingRequest = true;
            //
			// 	axios.put(_this.update_url, {
			// 		question_id: _this.questions[_this.selected_question].id,
			// 		open_ended_answer: text
			// 	})
            //         .then(function (){
            //             _this.submittingRequest = false;
            //
            //             if (_this.selected_question !== _this.answers.length){
            //                 _this.answers[_this.selected_question].open_ended_answer = text;
            //             }
            //             else {
            //                 _this.answers.push({
            //                     rating: null,
            //                     open_ended_answer: text,
            //                     questionnaire_question_id: _this.questions[_this.selected_question].id,
            //                     campaign_participant_id: _this.campaign_participant.id
            //                 });
            //             }
            //
            //             _this.next();
            //         })
			// 		.catch(function (error) {
            //             toastr.error('Something went wrong: ' + error);
            //
			// 			for (var i in error.response.data.errors) {
			// 				if (error.response.data.errors.hasOwnProperty(i)) {
			// 					toastr.error(error.response.data.errors[i][0]);
			// 				}
			// 			}
			// 		});
			// },




			getParticipant(){
				return this.campaign_participant.participant ? this.campaign_participant.participant.user.full_name : this.campaign_participant.respondent.participant.user.full_name;
			},

            getYesNoActiveClass(button) {
                if (this.yes_no_answer !== null){
                    if (button){
                        return this.yes_no_answer ? 'active' : '';
                    }
                    else {
                        return this.yes_no_answer ? '' : 'active';
                    }
                }
                else {
                    return '';
                }
            },
            getNAActiveClass() {
                if (this.na_answer !== null){
                    return this.na_answer ? 'active' : '';
                }
                else {
                    return '';
                }
            },

			handleUnanswered() {
				let key;

                for (let k in this.unanswered_questions) {
                    if (this.unanswered_questions[k].key === this.selected_question) {
                        key = k;
                        break;
                    }
				}

                if (typeof(key) !== 'undefined') {
                    this.unanswered_questions.splice(key, 1);
                }

                let unanswered_question_array = [];
                this.questions.forEach((v, k) => {
                    if(
                       ! this.answers.find(answer => answer.questionnaire_question_id == v.id && (answer.rating != null || answer.open_ended_answer != null || answer.yes_no_answer != null || answer.na_answer == 1))
                    ) {
                        unanswered_question_array.push({"key": k});
                    }
                });

                this.actually_unanswered = unanswered_question_array;

                // this.questions_actually_answered = this.questions.reduce((carry, item) => {
                //     return carry + (this.answers.find(answer => answer.questionnaire_question_id == item.id && (answer.rating != null || answer.open_ended_answer != null || answer.yes_no_answer != null)) ? 1 : 0);
                // }, 0);

                this.questions_actually_answered = this.questions.length - this.actually_unanswered.length;
                this.answered_percentage = this.questions_actually_answered / this.questions.length * 100;
            }
		},

		computed: {
			percentage: function () {
				return this.questions_actually_answered / this.questions.length * 100;
			},
		},

		watch: {
			selected_question: {
				immediate: true,
				handler: function() {
					this.handleUnanswered();
				}
			},
            open_text: {
                immediate: true,
                handler: function() {
                    if(this.open_text.trim() != null) {
                        this.na_answer = false;
                    }
                }
            },
			percentage: function (val){
			    if(val === 100 ) {
                    this.completed = true;
                }
				if (!this.finished && this.started)
					this.$refs.progress.updateProgress(val);
			},
		}
	}
</script>

<style>
	pre {
		white-space: pre-wrap;       /* Since CSS 2.1 */
		white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
		white-space: -pre-wrap;      /* Opera 4-6 */
		white-space: -o-pre-wrap;    /* Opera 7 */
		word-wrap: break-word;       /* Internet Explorer 5.5+ */

		font-family: 'Source Sans Pro',
		sans-serif; font-size: .9rem;
	}
</style>
