<template>
	<div class="row">
		<div class="col-12">
			<ul class="rating-cards--list">
				<li
					class="rating-cards--list-option"
					v-for="(option, index) in options"
					v-bind:class="selected === index ? 'active' : ''"
					v-on:click="select(index)"
				>
					{{option.value}}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: "TextRating",
		props: [
			'options',
			'answer'
		],

		data(){
			return {
				selected: false
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			select(i){
				this.selected = i;
				this.$emit('select', this.options[this.selected]);
			},

			reset() {
				this.selected = false;
				this.$nextTick(function () {
					if (this.answer){
						for (let i = 0; i < this.options.length ; i ++){
							if (this.options[i].number === this.answer.rating){
								this.selected = i;
								return;
							}
						}
					}
				});
			}
		}
	}
</script>
