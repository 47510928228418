<template>
    <div class="feedo-modal">
        <div class="feedo-modal--card py-4" style="width: 435px">
            <div class="text-center mx-auto pb-2" style="max-width: 326px">
                <img style="height: 56px" class="my-4" :src="image" alt="Feedo">
                <h4 class="mx-auto" style="
                    font-weight: 800 !important;
                    max-width: 215px;"
                >{{__('translation.first_login_' + (campaign ? (step + 11) : (step + 1)) + '_title')}}</h4>
                <p style="font-size: 1rem; font-weight: 500; min-height: 50px;">{{__('translation.first_login_' + (campaign ? (step + 11) : (step + 1)) + '_description')}}</p>
                <button v-on:click="next" class="btn btn-primary">{{button_label}} <span style="color: white; margin-left: 2.5em" v-if="step !== 0 && step !== 3">></span></button>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: "FirstLoginModal",
        props: {
            campaign: {
                type: Boolean,
                default: false
            }
        },
        data() {
		    return {
		        step: 0
            }
        },

        methods: {
            '__': function(val){
                return window.Lang.exists(val) ? window.Lang.translate(val) : val;
            },

            next(){
                if (this.step < 3){
                    this.step ++;
                    return;
                }

                axios.post('', {update_first_login: true});
                this.closeModal(null, true)
            },

            closeModal(e, force = false){
                if (!force){
                    if ($(e.target).closest('.feedo-modal--card').length){
                        return;
                    }
                }

                this.focus = false;

                window.closeModal();
            },
        },

        computed: {
		    image(){
		        if (!this.campaign){
                    switch (this.step) {
                        case 0:
                            return '/svg/carousel/feedo.svg';
                        case 1:
                            return '/svg/carousel/switches.svg';
                        case 2:
                            return '/svg/carousel/team.svg';
                        case 3:
                            return '/svg/carousel/send_campaign.svg';
                    }
                }
		        else {
                    switch (this.step) {
                        case 0:
                            return '/svg/carousel/feedo.svg';
                        case 1:
                            return '/svg/carousel/questionnaire.svg';
                        case 2:
                            return '/svg/carousel/team.svg';
                        case 3:
                            return '/svg/carousel/send_campaign.svg';
                    }
                }
            },

            button_label(){
                if (this.step === 0)
                    return this.__('buttons.get_started');
                else if (this.step === 3)
                    return this.__('buttons.done');
                else
                    return this.__('buttons.next');
            }
        }
	}
</script>
