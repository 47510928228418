(function (){
	let
		object = {},
		user_logged_in = DataController.user_logged_in,
		highest_role = DataController.user_highest_role,
		user_role_permissions = DataController.user_role_permissions,
		user = DataController.user;

	object.highestRole = function(){
		return user_logged_in ? highest_role : null;
	};

	object.can = function(permission){
		if (!user_logged_in) return false;

		for (let rp = 0; rp < user_role_permissions.length; rp ++)
			if (user_role_permissions[rp].name === permission)
				return true;
		for (let up = 0; up < user.permissions.length; up ++)
			if (user.permissions[up].name === permission)
				return true;

		return false;
	};

	object.isMe = function(id){
		return user_logged_in ? user.id === id : null;
	};

	module.exports = object;
	return object;
})();