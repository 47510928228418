<template>
	<table class="custom-table">
		<thead>
		<tr>
			<th>{{__('translation.administrator')}}</th>
			<th v-if="PermissionsController.can('crud companies')">{{__('translation.company')}}</th>
			<th><!--actions--></th>
		</tr>
		</thead>
		<tbody>
		<tr v-for="user in users" v-if="user.company" v-bind:class="PermissionsController.isMe(user.id) ? 'bg-light' : ''">
			<td>
				<div class="w-100">{{user.full_name}}</div>
				<div class="w-100">{{user.email}}</div>
			</td>
			<td v-if="PermissionsController.can('crud companies')">{{user.company.name}}</td>
			<td>
				<!--actions-->
				<button v-if="PermissionsController.can('impersonate') && !PermissionsController.isMe(user.id)" class="btn btn-link" v-on:click="impersonateUser(user.id)">Impersonate</button>
				<a v-if="!PermissionsController.isMe(user.id)" :href="edit_url.replace('?', user.id)">edit</a>
			</td>
		</tr>
		</tbody>
	</table>
</template>

<script>
	export default {
		props: [
			'users',
			'update_url',
			'edit_url'
		],

		beforeMount(){
			this.PermissionsController = window.PermissionsController;
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			impersonateUser (user_id){
				axios.post('/dev/impersonate/' + user_id)
					.then(function (){
						window.location.reload();
					})
			}
		}
	}
</script>