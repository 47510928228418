<template>
	<div class="row mt-4">
		<div class="col-md-3">
			<ul class="list-group">
				<li v-for="(page, index) in pages" class="list-group-item">
					<a href="javascript:void(0)" v-on:click="selectPage(index)">
						<span v-bind:class="index === selected_page ? 'text-primary' : ''">{{page.title}}</span>
						<img v-if="index === selected_page" src="/svg/chevron-active.svg" />
						<img v-else src="/svg/chevron-inactive.svg" />
					</a>
				</li>
			</ul>
		</div>
		<div class="col-md-9">
			<input
				v-for="(page, index) in pages"
				type="hidden"
				:name="page.code"
				v-model="messages[index]"
			>
			<Redactor
				id="redactor-message-board"
				class="message-board"
				v-model="textbox"
				:config="{
					breakline: false,
        			focus: true,
        			plugins: ['variable'],
        			variables: fields,
				}"
			></Redactor>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			'emails_data',
			'company_messages'
		],

		data(){
			return {
				textbox: '',

				pages: [],
				fields: [],

				messages: [],
				saved_messages: {},

				selected_page: 0,
			}
		},

		beforeMount(){
			let _this = this;

			//Setup all email codes in the system and store the default messages;
			for (let email_code in _this.emails_data){
				if(!_this.emails_data.hasOwnProperty(email_code)) continue;

				_this.pages.push({
					title: _this.__('emails.' + email_code + '_title'),
					code: email_code
				});

				_this.saved_messages[email_code] = _this.__('emails.' + email_code);
			}

			//Override the default messages with the company's ones (if any)
			_this.company_messages.forEach(function (message){
				_this.saved_messages[message.code] = message.body;
			});

			//Store all messages in the edit (so the admin can change and submit)
			for (let message_code in _this.saved_messages) {
				if(!_this.saved_messages.hasOwnProperty(message_code)) continue;
				_this.messages.push(_this.saved_messages[message_code]);
			}

			//Gets the fields for the select email (the first one)
			_this.fields = _this.emails_data[_this.pages[_this.selected_page].code];
			//Sets the text box to the editable message
			_this.textbox = _this.messages[_this.selected_page];
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			selectPage(index) {
				this.selected_page = index;
			},
		},

		watch: {
			selected_page(){
				this.fields = this.emails_data[this.pages[this.selected_page].code];
				this.textbox = this.messages[this.selected_page];
			},

			textbox(){
				if (this.messages[this.selected_page] !== this.saved_messages[this.pages[this.selected_page].code]){
					SaveProtection.lockWindow();
				}

				this.messages[this.selected_page] = this.textbox;
			}
		}
	}
</script>
