<template>

	<div class="row">

		<div class="col-12">
			<div class="rating-cards--wrapper numbers">
				<div
					class="rating-cards--numbers-radio"
					v-for="(option, index) in options"
					v-on:click="select(index)"
				>
					<div
						class="rating-cards--numbers-radio--wrapper"
						v-bind:class="selected === index ? 'active' : ''"
					>
						<div class="rating-cards--numbers-radio--wrapper-dot" v-if="selected === index"></div>
					</div>
					<div class="">{{ getPercentage(option.number) }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "PercentageRating",
		props: [
			'options',
			'answer'
		],

		data(){
			return {
				selected: -1,
				dragging: false,

				posY: null,
			}
		},

		methods: {
			select(i){
				this.selected = i;
				this.$emit('select', this.options[i]);
			},

			reset(){
				this.selected = -1;
				this.$nextTick(function () {
					if (this.answer){
						for (let i = 0; i < this.options.length ; i ++){
							if (this.options[i].number === this.answer.rating){
								this.selected = i;
								return;
							}
						}
					}
				});
			},

			getPercentage(number){
				let first = this.options[0].number, last = this.options[this.options.length - 1].number;

				if (first === 0) {
					return ((number - first) / (last) * 100).toFixed(0) + '%';
				}
				else {
					return ((number - first) / (last - 1) * 100).toFixed(0) + '%';
				}
			}
		},
	}
</script>
