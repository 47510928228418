<template>
	<div class="container mt-3 reporting-page">

		<!--Header-->
		<div class="row">
			<div class="col-md-3 order-0 order-md-0 d-flex">
				<a v-if="back_label && back_url" :href="back_url" class="my-md-auto">< {{back_label}}</a>
			</div>

			<div class="col-md-6 order-2 order-md-1">
				<h1>{{participant ? participant.user.full_name : campaign.name}}</h1>
			</div>

			<div class="col-md-3 order-1 order-md-2 d-flex">
				<div class="dropdown">
					<button class="btn btn-primary dropdown-toggle" id="download-dropdown" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
						{{__('buttons.download_report')}}
					</button>
					<div id="collapse-download" class="dropdown-menu" aria-labelledby="download-dropdown">
						<template v-for="(button, label) in downloadOptions">
							<button
								v-if="!label.includes('all') || !participant"
								class="dropdown-item" 
								:disabled="!pdf_download" 
								@click="button"
							>
								{{__(label)}}
							</button>
						</template>
					</div>
				</div>
			</div>
		</div>

		<!--Body-->
		<div class="row">
			<div class="col-lg-3 order-0 order-lg-0">
				<reporting-summary-card
					@loaded="loadedSection('summary_card', $event)"
					@unloaded="unloadSection('summary_card')"
					:comparison_participants="comparison_campaign ? comparison_participants : null"
                    :campaign_type="campaign.campaign_type_id"
					:participants="summary_participants"
					:primary_color="primary_color"
					:secondary_color="secondary_color"
				></reporting-summary-card>
				<div v-if="campaign.comparison_report && comparison_url" class="mt-4">
					<a :href="comparison_url" target="_blank" class="btn btn-outline">View previous campaign</a>
				</div>
			</div>

			<!--Sections-->
			<div class="col-lg-6 order-2 order-lg-1">

				<spider-diagram
					v-if="hasSection('spider')"

					@loaded="loadedSection('spider', $event)"
					@unloaded="unloadSection('spider')"
					:load_url="load_url"
					:token="token"
					:primary_color="primary_color"
					:secondary_color="secondary_color"
                    :primary_color_faded="primary_color_faded"
                    :secondary_color_faded="secondary_color_faded"
					:font="font"
					:campaign_type_id="campaign.campaign_type_id"
				></spider-diagram>

				<make-sense-table
					v-if="hasSection('feedback')"

					@loaded="loadedSection('feedback', $event)"
					@unloaded="unloadSection('feedback')"
					:load_url="load_url"
					:token="token"
                    :campaign="campaign"
				></make-sense-table>

				<summary-of-competencies
					v-if="hasSection('summary')"

					@loaded="loadedSection('summary', $event)"
					@unloaded="unloadSection('summary')"
					:load_url="load_url"
					:token="token"
					:primary_color="primary_color"
					:secondary_color="secondary_color"
					:primary_color_faded="primary_color_faded"
					:secondary_color_faded="secondary_color_faded"
				></summary-of-competencies>

				<score-by-competency
					v-if="hasSection('percentage_score')"

					@loaded="loadedSection('percentage_score', $event)"
					@unloaded="unloadSection('percentage_score')"
                    :campaign_type_id="campaign.campaign_type_id"
					:load_url="load_url"
					:token="token"
					:primary_color="primary_color"
					:secondary_color="secondary_color"
					:primary_color_faded="primary_color_faded"
					:secondary_color_faded="secondary_color_faded"
				></score-by-competency>

				<open-ended-questions
					v-if="hasSection('open_ended')"

					@loaded="loadedSection('open_ended', $event)"
					@unloaded="unloadSection('open_ended')"
					:load_url="load_url"
					:token="token"
					:primary_color="primary_color"
					:secondary_color="secondary_color"
				></open-ended-questions>

				<high-low-comparison
					v-if="hasSection('high_low')"

					@loaded="loadedSection('high_low', $event)"
					@unloaded="unloadSection('high_low')"
					:load_url="load_url"
					:token="token"
					:primary_color="primary_color"
					:secondary_color="secondary_color"
					:primary_color_faded="primary_color_faded"
					:secondary_color_faded="secondary_color_faded"
				></high-low-comparison>


				<leaderboard
					v-if="hasSection('leaderboard')"

					@loaded="loadedSection('leaderboard', $event)"
					@unloaded="unloadSection('leaderboard')"
					:load_url="load_url"
					:token="token"
					:primary_color="primary_color"
					:secondary_color="secondary_color"
					:primary_color_faded="primary_color_faded"
					:secondary_color_faded="secondary_color_faded"
				></leaderboard>

			</div>

			<div class="col-lg-3 order-1 order-lg-2">
				<div v-if="!participant" class="mt-4">
					<div class="d-flex justify-content-between">
						<span class="font-weight-bold">{{__('place_holders.include_all_participants')}}</span>
						<label class="switch mr-2 my-auto">
							<input type="checkbox" v-model="use_all_participants">
							<span class="slider round"></span>
						</label>
					</div>
					<hr>

                    <div v-if="campaign.campaign_type_id === 6">
                        <div v-for="participant in participants_in_use" class="d-flex justify-content-between pb-3">
                            <span class="font-weight-bold">{{participant.user.full_name}}</span>
                            <label class="switch mr-2 my-auto">
                                <input type="checkbox" v-model="participant.enabled">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>

					<div v-else v-for="(department, id) in departments_in_use_toggle" class="">
						<div class="d-flex justify-content-between pb-3">
							<a
								:href="'#side-collapse-' + department.id"
								data-toggle="collapse"
								role="button"
								aria-expanded="false"
								:aria-controls="'side-collapse-' + department.id"
							>
								<span class="font-weight-bold text-primary">{{department.name}}</span>
							</a>
							<label class="switch mr-2 my-auto">
								<input type="checkbox" @change="toggleDepartment(department)" v-model="department.enabled">
								<span class="slider round"></span>
							</label>
						</div>
						<div :id="'side-collapse-' + department.id" class="collapse" v-if="campaign.campaign_type_id === 1 || campaign.campaign_type_id === 2 || campaign.campaign_type_id === 3">
							<div v-for="participant in department.participants" class="d-flex justify-content-between pb-3">
								<span class="font-weight-bold">{{participant.user.full_name}}</span>
								<label class="switch mr-2 my-auto">
									<input type="checkbox" @change="checkDepartmentToggle" v-model="participant.enabled">
									<span class="slider round"></span>
								</label>
							</div>
						</div>
						<hr v-if="campaign.campaign_type_id === 1 || campaign.campaign_type_id === 2 || campaign.campaign_type_id === 3" />
					</div>
				</div>
			</div>
		</div>
<!--		<insight-pdf-download-->
<!--			v-if="downloading_pdf"-->

<!--			id="pdf-download-modal"-->

<!--			:sections="sections_loaded"-->

<!--			:subtitle="participant ? __('translation.pdf_title_prefix') + participant.user.full_name : __('translation.pdf_title_prefix') + campaign.name"-->
<!--			:file_name="PDFFileName"-->

<!--			:campaign_id="campaign.id"-->
<!--			:participants="participants_in_use_ids"-->

<!--			:primary_color="primary_color"-->
<!--			:secondary_color="secondary_color"-->

<!--			:generate_pdf_token_url="generate_pdf_token_url"-->
<!--			:generate_pdf_url="generate_pdf_url"-->
<!--			:merge_pdf_url="merge_pdf_url"-->

<!--			@finished="pdfDownloaded"-->
<!--            @error="errorOnDownloadPDF"-->
<!--		></insight-pdf-download>-->
	</div>
</template>

<script>
	import ReportingSummaryCard from "./ReportingSummaryCard";
	import _ from "underscore/underscore"
	import SpiderDiagram from "./SpiderDiagram";
	import MakeSenseTable from "./MakeSenseTable";
	import SummaryOfCompetencies from "./SummaryOfCompetencies";
	import ScoreByCompetency from "./ScoreByCompetency";
	import HighLowComparison from "./HighLowComparison";
	import OpenEndedQuestions from "./OpenEndedQuestions";
	import InsightPDFDownload from "../modals/InsightPDFDownload";
	import Leaderboard from "./Leaderboard";
	export default {
		name: "ReportingPage",
		components: {
			Leaderboard,
			'insight-pdf-download': InsightPDFDownload,
			OpenEndedQuestions,
			HighLowComparison,
			ScoreByCompetency, SummaryOfCompetencies, MakeSenseTable, SpiderDiagram, ReportingSummaryCard},
		props: [
			'back_url',
			'back_label',

			'comparison_url',

			'load_url',
			'generate_token_url',

			'primary_color',
			'secondary_color',
			'font',

			'participant',

			'campaign',
			'comparison_campaign',
			'company_terminologies',
			'terminologies',

			'generate_pdf_token_url',
			'generate_pdf_url',
			'merge_pdf_url'
		],

		data(){
			return {
				participants_in_use: [],
				departments_in_use_toggle: {},

                summary_participants: [],
                comparison_participants: [],

				use_all_participants: true,

				pdf_download: true,
				downloading_pdf: false,

				token: null,
				generating_token: false,
				request_cancel_token_source: null,

				sections_loaded: {
					summary_card: {
						loaded: false,
						data: null
					},

					feedback: {
						loaded: true,
						data: null
					},
					spider: {
						loaded: true,
						data: null
					},
					summary: {
						loaded: true,
						data: null
					},
					percentage_score: {
						loaded: true,
						data: null
					},
					open_ended: {
						loaded: true,
						data: null
					},
					high_low: {
						loaded: true,
						data: null
					},
					leaderboard: {
						loaded: true,
						data: null
					}
				},
			}
		},

		beforeMount(){
			let _this = this;

			_this.PermissionsController = window.PermissionsController;
			_this.participants_only.forEach(function (participant) {
				let _participant = Object.assign({}, participant);

				_participant.enabled = true;
				_this.participants_in_use.push(_participant);

				if (_participant.user.department){
					if (!_this.departments_in_use_toggle[_participant.user.department.id]){
						_this.departments_in_use_toggle[_participant.user.department.id] = {
							id: _participant.user.department.id,
							enabled: true,
							name: _participant.user.department.name,
							participants: []
						};
					}
					_this.departments_in_use_toggle[_participant.user.department.id].participants.push(_participant);
				}
				else {
					if (!_this.departments_in_use_toggle[0]){
						_this.departments_in_use_toggle[0] = {
							id: null,
							enabled: true,
							name: _this.__('place_holders.na'),
							participants: []
						};
					}
					_this.departments_in_use_toggle[0].participants.push(_participant);
				}

			});

			//Check each section and set loaded to false for valid ones
			//Invalid should be true to not lock the pdf generation

			for (let i = 0; i < _this.campaign.reporting_sections.length; i ++){
				if (_this.campaign.reporting_sections[i].value)
					_this.sections_loaded[_this.campaign.reporting_sections[i].code].loaded = false;
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			hasSection(code) {
				for (let i = 0; i < this.campaign.reporting_sections.length; i ++){
					if (this.campaign.reporting_sections[i].code === code && this.campaign.reporting_sections[i].value)
						return true;
				}

				return false;
			},

			getSummaryParticipants(){
				let data = [], _this = this;

				_this.participants_in_use.forEach(function (filter){
					_this.campaign.participants.forEach(function (campaign_participant){
						if (campaign_participant.participant && campaign_participant.participant.campaign_participant_id === filter.campaign_participant_id && filter.enabled){
							data.push(campaign_participant);
						}
						else if (campaign_participant.respondent && campaign_participant.respondent.participant_id === filter.id && filter.enabled){
							data.push(campaign_participant);
						}
					});
				});
				return data;
			},

            getComparisonParticipants(){
                let data = [], _this = this;
                if(this.campaign.comparison_report) {
                    _this.participants_in_use.forEach(function (filter) {
                        _this.comparison_campaign.participants.forEach(function (campaign_participant) {
                            if (campaign_participant.participant && campaign_participant.participant.campaign_participant_id === filter.comparison_participant_id && filter.enabled) {
                                data.push(campaign_participant);
                            } else if (campaign_participant.respondent && campaign_participant.respondent.participant_id === filter.comparison_id && filter.enabled) {
                                data.push(campaign_participant);
                            }
                        });
                    });
                }
                return data;
            },

			generateToken(){
				this.token = null;
				if (this.request_cancel_token_source){
					this.request_cancel_token_source.cancel();
				}

				this.generating_token = true;
				this.request_cancel_token_source = axios.CancelToken.source();

				//@todo Check this is post request when serverside has participants_in_use accounted for
				this.requester = axios.post(this.generate_token_url, {
					filter: _.pluck(_.where(this.participants_in_use, {enabled: true}), 'campaign_participant_id'),
				}, {
					cancelToken: this.request_cancel_token_source.token
				})
					.then(response => {
						this.generating_token = false;
						if (this.regenerate_token){
							this.regenerate_token = false;
							this.generateToken();
							return;
						}

						this.token = response.data.token;
						//@todo expires at
					})
					.catch(exception => {
						if (axios.isCancel(exception)){
							return;
						}
						//If something happens just reload
						window.location.reload();
					})
			},


			loadedSection(code, data){
				this.sections_loaded[code].loaded = true;
				this.sections_loaded[code].data = data;
			},
			unloadSection(code){
				this.sections_loaded[code].loaded = false;
				this.sections_loaded[code].data = null;
			},

			generatePDF(){
				if (this.downloading_pdf)
					return;

				this.downloading_pdf = true;
				this.pdf_download = false;

				this.$nextTick(function () {
					openModal('#pdf-download-modal');
				})
			},

            generatePDFServerside(include_individual_exports = false, type = 'pdf'){
                this.downloading_pdf = true;
                this.pdf_download = false;
                let self = this;
				
                axios.post('/pdf/serverside', {
					type: type,
                    name: self.PDFFileName,
                    campaign_id: self.campaign.id,
                    participants: self.participants_in_use_ids,
					include_individual_exports: include_individual_exports,
                    subtitle: self.participant ? self.__('translation.pdf_title_prefix') + self.participant.user.full_name : self.__('translation.pdf_title_prefix') + self.campaign.name,
                }).then((response) => {
					toastr.success(response.data ?? `${String(type).toUpperCase()} Generation started. You will receive an email once your download is ready.`, String(type).toUpperCase());
				}).finally(() => {
					this.pdfDownloaded();
				})
            },

			pdfDownloaded(){
				this.downloading_pdf = false;
				this.pdf_download = this.canDownloadPDF();
			},

			canDownloadPDF(){
				return !this.downloading_pdf //&&
					// this.sections_loaded.summary_card.loaded &&
					// this.sections_loaded.feedback.loaded &&
					// this.sections_loaded.spider.loaded &&
					// this.sections_loaded.summary.loaded &&
					// this.sections_loaded.percentage_score.loaded &&
					// this.sections_loaded.open_ended.loaded &&
					// this.sections_loaded.high_low.loaded;
			},

			checkDepartmentToggle(){
				let _this = this;

				for (let department_id in _this.departments_in_use_toggle){
					if (_this.departments_in_use_toggle.hasOwnProperty(department_id)){
						_this.departments_in_use_toggle[department_id].enabled = !_this.departments_in_use_toggle[department_id]
							.participants.every(function(participant) { return !participant.enabled });
					}
				}
			},

			toggleDepartment(department){
				department.participants.forEach(function (participant){
					participant.enabled = department.enabled;
				});
			},

			errorOnDownloadPDF(data){
				toastr.error('Error downloading PDF', 'PDF');
				this.downloading_pdf = false;
            },

			LightenDarkenColor(color,amt) {
				let usePound = false;
				if (color[0] === "#") {
					color = color.slice(1);
					usePound = true;
				}

				let num = parseInt(color,16);

				let r = (num >> 16) + amt;

				if ( r > 255 ) r = 255;
				else if  (r < 0) r = 0;

				let b = ((num >> 8) & 0x00FF) + amt;

				if ( b > 255 ) b = 255;
				else if  (b < 0) b = 0;

				let g = (num & 0x0000FF) + amt;

				if ( g > 255 ) g = 255;
				else if  ( g < 0 ) g = 0;

				return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
			}
		},

		watch: {
			use_all_participants(val){
				if (val){
					this.participants_in_use.forEach(function (participant){
						participant.enabled = true;
					});
				}
				else {
					if (this.participants_in_use.every(function(participant) { return participant.enabled })){
						this.participants_in_use.forEach(function (participant){
							participant.enabled = false;
						});
					}
				}
				this.checkDepartmentToggle();
			},

            //@todo check this is replicated in the serverside work
			participants_in_use:{
				handler: function (){
					if (!this.participants_in_use.every(function(participant) { return participant.enabled }))
						this.use_all_participants = false;


					this.summary_participants = this.getSummaryParticipants();

                    this.comparison_participants = this.participant ? this.comparison_campaign?.participants : this.getComparisonParticipants();
					this.generateToken();
				},

				deep: true
			},

			sections_loaded: {
				handler: function (){
					this.pdf_download = this.canDownloadPDF();
				},
				deep: true
			}
		},

		computed: {
			participants_only(){
				return _.pluck(_.where(this.campaign.participants, {respondent: null}), 'participant');
			},

            participants_in_use_ids(){
				return _.pluck(_.filter(this.participants_in_use, participant => participant.enabled), 'id')
			},

			PDFFileName(){
				let participants = this.participants_only;

				if (this.participants_in_use_ids.length === 1){
				    const participant = _.where(participants, {id: this.participants_in_use_ids[0]})[0];
					return this.campaign.is_tna
                        ? participant.user.full_name + ' - ' + this.__('translation.tna_title')
                        : participant.user.full_name + ' - ' + this.campaign.campaign_type.name
				}
				else {
					return this.campaign.is_tna
						? this.__('translation.overview') + ' - ' + this.__('translation.tna_title')
						: this.__('translation.overview') + ' - ' + this.campaign.campaign_type.name
				}
			},

			primary_color_faded() {
				return this.LightenDarkenColor(this.primary_color, +50);
			},
			secondary_color_faded() {
				return this.LightenDarkenColor(this.secondary_color, +50);
			},

			downloadOptions(){
				return {
					'buttons.download_pdf'     : () => this.generatePDFServerside(false, 'pdf'),
					'buttons.download_csv'     : () => this.generatePDFServerside(false, 'csv'),
					'buttons.download_all_pdf' : () => this.generatePDFServerside(true, 'pdf'),
					'buttons.download_all_csv' : () => this.generatePDFServerside(true, 'csv'),
				}
			}
		}
	}
</script>
