<template>
	<div class="mt-4">
		<div class="form-group row">
			<div class="col-md-6">
				<label for="manager-select-input">{{__('translation.select_user')}}</label>
				<select @change="delete errors_edit.manager_user_id" name="manager_user_id" id="manager-select-input" class="form-control" v-model="manager_user_id" v-bind:class="errors_edit.manager_user_id ? 'is-invalid' : ''">
					<option :value="null">{{__('place_holders.create_new')}}</option>
					<option disabled>---------------</option>
					<option v-for="user in users" :value="user.id">{{user.full_name}}</option>
				</select>
				<span v-if="errors_edit.manager_user_id" class="invalid-feedback" role="alert">
					<strong>{{ errors_edit.manager_user_id[0] }}</strong>
				</span>
			</div>
		</div>

		<div v-if="!manager_user_id" class="form-group row">
			<div class="col-md-6">
				<label for="manager-first-name-input">{{__('translation.first_name_title')}}</label>
				<input v-on:keydown="delete errors_edit.manager_first_name" id="manager-first-name-input" name="manager_first_name" type="text" class="form-control" v-bind:class="errors_edit.manager_first_name ? 'is-invalid' : ''" v-model="manager_first_name" required="required">
				<span v-if="errors_edit.manager_first_name" class="invalid-feedback" role="alert">
					<strong>{{ errors_edit.manager_first_name[0] }}</strong>
				</span>
			</div>
			<div class="col-md-6">
				<label for="manager-last-name-input">{{__('translation.last_name_title')}}</label>
				<input v-on:keydown="delete errors_edit.manager_last_name" id="manager-last-name-input" name="manager_last_name" type="text" class="form-control" v-bind:class="errors_edit.manager_last_name ? 'is-invalid' : ''" v-model="manager_last_name" required="required">
				<span v-if="errors_edit.manager_last_name" class="invalid-feedback" role="alert">
					<strong>{{ errors_edit.manager_last_name[0] }}</strong>
				</span>
			</div>
		</div>

		<div v-if="!manager_user_id" class="form-group row mt-4">
			<div class="col-12">
				<label for="manager-email-input">{{__('translation.email_address_title')}}</label>
				<input v-on:keydown="delete errors_edit.manager_email" id="manager-email-input" name="manager_email" type="email" class="form-control" v-bind:class="errors_edit.manager_email ? 'is-invalid' : ''" v-model="manager_email" required="required">
				<span v-if="errors_edit.manager_email" class="invalid-feedback" role="alert">
					<strong>{{ errors_edit.manager_email[0] }}</strong>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "UserSelection",
		props: [
			'users',
			'errors',
			'old_input',
		],

		data(){
			return {
				manager_first_name: '',
				manager_last_name: '',
				manager_email: '',
				manager_user_id: null,
				errors_edit: {}
			}
		},

		beforeMount(){
			this.errors_edit = this.errors;
			if (this.old_input){
				this.manager_first_name = this.old_input.manager_first_name;
				this.manager_last_name = this.old_input.manager_last_name;
				this.manager_email = this.old_input.manager_email;
				this.manager_user_id = this.old_input.manager_user_id;
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},
		}
	}
</script>