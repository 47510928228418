<template>
	<div class="reporting-page--section-card">
		<div class="row mx-0">
			<div class="col-9 py-2">
				<h5 class="font-weight-bold text-secondary">{{item.question}}</h5>
				<h6 class="font-weight-bold text-secondary">{{item.section}}</h6>
			</div>
			<div class="col-3 py-2 text-right">
				<h5 v-bind:class="item.avg_previously ? 'mb-0' : ''" :style="{'color': primary_color}">
					{{__('translation.avg_score') + ': ' + parseFloat(item.avg).toFixed(2)}}
				</h5>
				<small
					v-if="item.avg_previously"
					v-bind:class="compareValues(item.avg, item.avg_previously, 2).class"
				>{{compareValues(item.avg, item.avg_previously, 2).label}}</small>
			</div>
		</div>
		<participants-respondents-bars
			v-for="(term_data, term, index) in item.terms"

			:key="index"

			:term="term"
			:term_data="term_data"
			:primary_color="primary_color"
			:secondary_color="secondary_color"
			:primary_color_faded="primary_color_faded"
			:secondary_color_faded="secondary_color_faded"
		></participants-respondents-bars>
	</div>
</template>

<script>
	import ParticipantsRespondentsBars from "./ParticipantsRespondentsBars";
	export default {
		name: 'LeaderboardItemCard',
		components: {ParticipantsRespondentsBars},
		props: [
			'item',

			'primary_color',
			'secondary_color',

			'primary_color_faded',
			'secondary_color_faded',
		],

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			compareValues(a, b, toFixed = null){
				const val = a - b;
				if (val > 0)
					return {
						class: 'text-success',
						different: true,
						label: '+' + (toFixed ? val.toFixed(toFixed) : val)
					};
				else if (val < 0)
					return {
						class: 'text-danger',
						different: true,
						label: (toFixed ? val.toFixed(toFixed) : val)
					};
				else
					return {
						class: 'text-grey',
						different: false,
						label: (toFixed ? (0).toFixed(toFixed) : '0')
					};
			}
		}
	}
</script>