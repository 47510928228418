<template>
	<div class="reporting-page--section" data-section="spider">
		<div v-if="loading" class="loaders--standard override"></div>

		<div v-show="has_data">
			<div class="navbar inner" v-if="comparison_report && (campaign_type_id === 1 || campaign_type_id === 2)">
				<div class="navbar--toggles">
					<a
						href="javascript:void(0)"
						class="navbar--toggles-link"
						v-bind:class="showing_participants ? 'active' : ''"
						v-on:click="showing_participants = true"
					>{{__('translation.self_assessment')}}</a>
					<a
						href="javascript:void(0)"
						class="navbar--toggles-link"
						v-bind:class="showing_participants ? '' : 'active'"
						v-on:click="showing_participants = false"
					>{{__('translation.respondents')}}</a>
				</div>
			</div>

			<canvas :style="{opacity: loading ? 0 : 1}" id="spider-chart"></canvas>
			<canvas id="spider-chart-2" style="width: 2500px; height: 2500px; position:fixed; left: -9999px; top: -9999px;"></canvas>
			<canvas id="spider-chart-3" style="width: 2500px; height: 2500px; position:fixed; left: -9999px; top: -9999px;"></canvas>
		</div>
	</div>
</template>

<script>
	import _ from "underscore/underscore"
	import chartjs from "chart.js"
	export default {
		name: "SpiderDiagram",
		props: [
			'load_url',
			'disable_duration',

			'token',
			'primary_color',
			'secondary_color',
			'primary_color_faded',
			'secondary_color_faded',
			'font',
			'campaign_type_id'
		],

		data(){
			return {
				pre_loading: true,
				loading: true,

				data: {},
				comparison_data: undefined,
				comparison_report: false,

				showing_participants: true,
				to_update: true,
				chart_canvas: null,
				chart_canvas2: null,
				chart_canvas3: null, //thanks Nick...

				chart_data:{
					labels: [],
					datasets: [],
                    max_rating: null,
				},
				chart_data_2:{
					labels: [],
					datasets: [],
                    max_rating: null,
				},
				chart_data_3:{
					labels: [],
					datasets: [],
                    max_rating: null,
				},
			}
		},

		computed: {
			has_data(){
				return !this.loading && this.chart_data.datasets?.some((dataset) => dataset?.data?.length > 0)
			}
		},

		beforeMount(){
			this.chart_data.datasets = [
				{
					backgroundColor: this.secondary_color + '55',
					borderColor: this.secondary_color,
					label: this.__('translation.respondents'),
					borderWidth: 1,
					data: []
				},
				{
					backgroundColor: this.primary_color + '55',
					borderColor: this.primary_color,
					label: this.__('translation.self_assessors_title'),
					borderWidth: 1,
					data: []
				}
			];
			this.chart_data_2.datasets = [
				{
					backgroundColor: this.secondary_color + '55',
					borderColor: this.secondary_color,
					label: this.__('translation.respondents'),
					borderWidth: 6,
					data: []
				},
				{
					backgroundColor: this.primary_color + '55',
					borderColor: this.primary_color,
					label: this.__('translation.self_assessors_title'),
					borderWidth: 6,
					data: []
				}
			];
			this.chart_data_3.datasets = [
				{
					backgroundColor: this.secondary_color + '55',
					borderColor: this.secondary_color,
					label: this.__('translation.respondents'),
					borderWidth: 6,
					data: []
				},
				{
					backgroundColor: this.primary_color + '55',
					borderColor: this.primary_color,
					label: this.__('translation.self_assessors_title'),
					borderWidth: 6,
					data: []
				}
			];
		},

		mounted(){
			this.loadData()
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			loadData(){

                let _this = this;

				_this.loading = true;
				_this.showing_participants = true;

				axios.post(_this.load_url, {
					token: this.token,
					code: 'spider'
				})
					.then(function (response){
						_this.loading = false;
						_this.data = response.data.spider_data;
						_this.comparison_report = !!response.data.comparison_report;
						_this.loadChart();
					})
					.catch(function (){
						//@todo
					})
			},

			startChart(){
				let ctx = document.getElementById('spider-chart').getContext('2d');

				this.chart_canvas = new Chart(ctx, {
					type: 'radar',
					data: this.chart_data,
					options: {
						responsive: true,
						maintainAspectRatio: true,
						aspectRatio: 1.2,
						legend: {
							display: false,
							position: 'bottom',
							labels: {
								boxWidth: 15
							}
						},
						title: {
							display: false
						},
						animation: {
							duration: this.disable_duration ? 0 : 1000
						},
						startAngle: 0,
						scale: {
							ticks: {
								display: false,
								beginAtZero: true,
								stepSize: 1,
								max: this.chart_data.max_rating,
							},
							scale: {
								display: false
							},
							angleLines:{
								display: true,
								color: '#B1B2C1',
								lineWidth: 0.5,
							},
							pointLabels: {
								fontColor: '#2C2D43',
                                fontFamily: this.font,
                                callback: function(value) {
                                    const maxLength = 15;
                                    return value.length > maxLength ?  value.substring(0, maxLength) + '...' : value;
                                }
							},
							gridLines: {
								color: '#B1B2C1',
								lineWidth: 0.5
							}
                        },
					}
				});

				ctx.webkitImageSmoothingEnabled = false;
				ctx.mozImageSmoothingEnabled = false;
				ctx.imageSmoothingEnabled = false;


				//Creating a second high resolution graph

                let ctx2 = document.getElementById('spider-chart-2').getContext('2d');

                this.chart_canvas2 = new Chart(ctx2, {
                    type: 'radar',
                    data: this.chart_data_2,
                    plugins:[this.createChartPlugin()],
                    options: {
                        responsive: false,
                        maintainAspectRatio: false,
                        aspectRatio: 1,
                        legend: {
                            display: false,
                            position: 'bottom',
                            labels: {
                                boxWidth: 15
                            }
                        },
                        title: {
                            display: false
                        },
                        animation: {
                            duration: this.disable_duration ? 0 : 1000
                        },
                        startAngle: 0,
                        scale: {
                            ticks: {
                                display: false,
                                beginAtZero: true,
                                stepSize: 1,
                                max: this.chart_data_2.max_rating,
                            },
                            scale: {
                                display: false
                            },
                            angleLines:{
                                display: true,
                                color: '#B1B2C1',
                                lineWidth: 2.5,
                            },
                            pointLabels: {
                                fontColor: '#2C2D43',
                                fontFamily: this.font,
                                fontSize: 40
                            },
                            gridLines: {
                                color: '#B1B2C1',
                                lineWidth: 2.5
                            }
                        }
                    }
                });

                ctx2.webkitImageSmoothingEnabled = false;
                ctx2.mozImageSmoothingEnabled = false;
                ctx2.imageSmoothingEnabled = false;


				//Creating a second high resolution graph with comparison data

                let ctx3 = document.getElementById('spider-chart-3').getContext('2d');

                this.chart_canvas3 = new Chart(ctx3, {
                    type: 'radar',
                    data: this.chart_data_3,
                    plugins:[this.createChartPlugin()],
                    options: {
                        responsive: false,
                        maintainAspectRatio: false,
                        aspectRatio: 1,
                        legend: {
                            display: false,
                            position: 'bottom',
                            labels: {
                                boxWidth: 15
                            }
                        },
                        title: {
                            display: false
                        },
                        animation: {
                            duration: this.disable_duration ? 0 : 1000
                        },
                        startAngle: 0,
                        scale: {
                            ticks: {
                                display: false,
                                beginAtZero: true,
                                stepSize: 1,
                                max: this.chart_data_3.max_rating,
                            },
                            scale: {
                                display: false
                            },
                            angleLines:{
                                display: true,
                                color: '#B1B2C1',
                                lineWidth: 2.5,
                            },
                            pointLabels: {
                                fontColor: '#2C2D43',
                                fontFamily: this.font,
                                fontSize: 40
                            },
                            gridLines: {
                                color: '#B1B2C1',
                                lineWidth: 2.5
                            }
                        }
                    }
                });

                ctx3.webkitImageSmoothingEnabled = false;
                ctx3.mozImageSmoothingEnabled = false;
                ctx3.imageSmoothingEnabled = false;
			},

			loadChart(){
				if (this.comparison_report) {
					this.chart_data.labels = _.pluck(this.data, 'name');

                    this.chart_data.max_rating = _.pluck(this.data, 'max_rating')[0];

                    this.chart_data.datasets[0].label = this.__('translation.after');
					this.chart_data.datasets[1].label = this.__('translation.before');
					if (this.showing_participants){
						this.chart_data.datasets[0].data = _.pluck(_.pluck(this.data, 'participants'), 'avg');
						this.chart_data.datasets[1].data = _.pluck(_.pluck(this.data, 'prev_participants'), 'avg');
					}
					else {
						this.chart_data.datasets[0].data = _.pluck(_.pluck(this.data, 'respondents'), 'avg');
						this.chart_data.datasets[1].data = _.pluck(_.pluck(this.data, 'prev_respondents'), 'avg');
					}

					this.chart_data_2.datasets[0].label = this.__('translation.after');
					this.chart_data_2.datasets[1].label = this.__('translation.before');
					this.chart_data_2.labels = _.pluck(this.data, 'name');
                    this.chart_data_2.max_rating = _.pluck(this.data, 'max_rating')[1];

                    this.chart_data_2.datasets[0].data = _.pluck(_.pluck(this.data, 'participants'), 'avg');
					this.chart_data_2.datasets[1].data = _.pluck(_.pluck(this.data, 'prev_participants'), 'avg');

					this.chart_data_3.datasets[0].label = this.__('translation.after');
					this.chart_data_3.datasets[1].label = this.__('translation.before');
					this.chart_data_3.labels = _.pluck(this.data, 'name');
                    this.chart_data_3.max_rating = _.pluck(this.data, 'max_rating')[2];

                    this.chart_data_3.datasets[0].data = _.pluck(_.pluck(this.data, 'respondents'), 'avg');
					this.chart_data_3.datasets[1].data = _.pluck(_.pluck(this.data, 'prev_respondents'), 'avg');


                    this.to_update = true;
                    this.startChart();
					this.chart_canvas.update();
					this.chart_canvas2.update();
					this.chart_canvas3.update();
				}
				else {
					this.chart_data.labels = _.pluck(this.data, 'name');
                    this.chart_data.max_rating = _.pluck(this.data, 'max_rating')[0];

                    this.chart_data.datasets[1].data = _.pluck(_.pluck(this.data, 'participants'), 'avg');
					this.chart_data.datasets[0].data = _.pluck(_.pluck(this.data, 'respondents'), 'avg');



                    this.chart_data_2.labels = _.pluck(this.data, 'name');
					this.chart_data_2.datasets[1].data = _.pluck(_.pluck(this.data, 'participants'), 'avg');
					this.chart_data_2.datasets[0].data = _.pluck(_.pluck(this.data, 'respondents'), 'avg');
                    this.chart_data_2.max_rating = _.pluck(this.data, 'max_rating')[1];

					this.to_update = true;
                    this.startChart();
					this.chart_canvas.update();
					this.chart_canvas2.update();
				}

			},



			createChartPlugin(){
				let _this = this;

				return {
					id: 'export_png_data',

					beforeDraw : function(chart, options){
						if (_this.to_update)
							return;

						_this.to_update = true;
					},

					afterRender: function(chart, options) {
						if (!_this.to_update || _this.loading)
							return;
						if (document.getElementById('spider-chart')){
							let data = {
								graph: document.getElementById('spider-chart-2').toDataURL('image/png', 1),
								primary_color: _this.primary_color,
								secondary_color: _this.secondary_color,
                                primary_color_faded: _this.primary_color_faded,
                                secondary_color_faded: _this.secondary_color_faded
							};

							if (_this.comparison_report)
								data.comparison_graph = document.getElementById('spider-chart-3').toDataURL('image/png', 1);

							_this.$emit('loaded', data);
							_this.to_update = false;
						}
					}
				};
			},
		},

		watch: {
			token() {
				this.$emit('unloaded');
				if (this.token !== null) {
					this.pre_loading = false;
					this.loading = false;
					this.loadData();
				}
				else {
					this.pre_loading = true;
					this.loading = true;
				}
			},

			showing_participants(){
				this.loadChart();
			}
		}
	}
</script>
