<template>
	<div class="container questionnaire_edit_table" v-on:mouseleave="mouseOverTable = null" >
		<div class="row border-bottom questionnaire_edit_table--header">
			<div class="col-6">{{__('translation.questionnaire_title')}}</div>
			<div class="col-3">{{__('translation.date_added')}}</div>
			<div class="col-2">{{__('translation.sections')}}</div>
			<div class="col-1"><!--action--></div>
		</div>
		<div class="questionnaire_edit_table--body">
			<div
				class="row questionnaire_edit_table--body-row"
				v-for="(questionnaire, index) in library"
				v-on:mouseover="mouseOverTable = index"
				v-on:click="selectRow(index)"
			>
				<div class="col-1 questionnaire_edit_table--cell"></div>
				<div class="col-5 questionnaire_edit_table--cell questionnaire_edit_table--cell-bold">{{questionnaire.questionnaire_data.name}}</div>
				<div class="col-3 questionnaire_edit_table--cell">{{questionnaire.questionnaire_data.formatted_date}}</div>
				<div class="col-2 questionnaire_edit_table--cell">{{questionnaire.questionnaire_data.sections_count}}</div>
				<div class="col-1 questionnaire_edit_table--cell">
					<img v-if="mouseOverTable === index" class="d-block my-auto" src="/svg/chevron-active.svg" />
					<img v-else class="d-block my-auto" src="/svg/chevron-inactive.svg" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "QuestionnairesLibraryTableComponent",
		props: [
			'library'
		],

		data(){
			return {
				mouseOverTable: null,
				selected_row: null,
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			selectRow(i){
				this.selected_row = i;
				this.$emit('select', i);
			}
		}
	}
</script>