<template>
	<div class="reporting-page--section" data-section="spider" style="min-width: 100% !important; max-width: 100% !important; padding-top: 0!important; padding-bottom: 0!important;">
		<div v-if="loading" class="loaders--standard override"></div>

		<div class="navbar inner" v-else-if="comparison_report && (campaign_type_id === 1 || campaign_type_id === 2)">
			<div class="navbar--toggles">
				<a
					href="javascript:void(0)"
					class="navbar--toggles-link"
					v-bind:class="showing_participants ? 'active' : ''"
					v-on:click="showing_participants = true"
				>{{__('translation.self_assessment')}}</a>
				<a
					href="javascript:void(0)"
					class="navbar--toggles-link"
					v-bind:class="showing_participants ? '' : 'active'"
					v-on:click="showing_participants = false"
				>{{__('translation.respondents')}}</a>
			</div>
		</div>

        <canvas style="width: 900px; height: 900px;" :style="{opacity: loading ? 0 : 1}" id="spider-chart-2-1"></canvas>
	</div>
</template>

<script>
	import _ from "underscore/underscore"
	import chartjs from "chart.js"
	export default {
		name: "PdfSpiderDiagram2",
		props: [

            'spider_data_prop',
            'comparison_report_prop',

			'primary_color',
			'secondary_color',
			'primary_color_faded',
			'secondary_color_faded',
			'font',
			'campaign_type_id'
		],

		data(){
			return {
				pre_loading: true,
				loading: true,

				data: {},
				comparison_data: undefined,
				comparison_report: false,

				showing_participants: true,
				to_update: true,
				chart_canvas: null,
				chart_canvas2: null,
				chart_canvas3: null, //thanks Nick...

				chart_data:{
					labels: [],
					datasets: [],
                    max_rating: null,
				},
				chart_data_2:{
					labels: [],
					datasets: [],
                    max_rating: null,
				},
				chart_data_3:{
					labels: [],
					datasets: [],
                    max_rating: null,
				},
			}
		},


		beforeMount(){
			this.chart_data.datasets = [
				{
					backgroundColor: this.secondary_color + '55',
					borderColor: this.secondary_color,
					label: this.__('translation.respondents'),
					borderWidth: 1,
					data: []
				},
				{
					backgroundColor: this.primary_color + '55',
					borderColor: this.primary_color,
					label: this.__('translation.self_assessors_title'),
					borderWidth: 1,
					data: []
				}
			];
			this.chart_data_2.datasets = [
				{
					backgroundColor: this.secondary_color + '55',
					borderColor: this.secondary_color,
					label: this.__('translation.respondents'),
					borderWidth: 6,
					data: []
				},
				{
					backgroundColor: this.primary_color + '55',
					borderColor: this.primary_color,
					label: this.__('translation.self_assessors_title'),
					borderWidth: 6,
					data: []
				}
			];
			this.chart_data_3.datasets = [
				{
					backgroundColor: this.secondary_color + '55',
					borderColor: this.secondary_color,
					label: this.__('translation.respondents'),
					borderWidth: 6,
					data: []
				},
				{
					backgroundColor: this.primary_color + '55',
					borderColor: this.primary_color,
					label: this.__('translation.self_assessors_title'),
					borderWidth: 6,
					data: []
				}
			];
		},

		mounted(){
            this.showing_participants = true;
            this.data = JSON.parse(this.spider_data_prop);
            this.comparison_report = JSON.parse(this.comparison_report_prop)
            this.loadChart();
            this.loading = false;
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},


			startChart(){
				let ctx = document.getElementById('spider-chart-2-1').getContext('2d');

				this.chart_canvas = new Chart(ctx, {
					type: 'radar',
					data: this.chart_data,
					options: {
						responsive: false,
						maintainAspectRatio: true,
						aspectRatio: 1.2,
						legend: {
							display: false,
							position: 'bottom',
							labels: {
								boxWidth: 15
							}
						},
						title: {
							display: false
						},
						animation: {
							duration: 0
						},
						startAngle: 0,
						scale: {
							ticks: {
								display: false,
								beginAtZero: true,
								stepSize: 1,
								max: this.chart_data.max_rating,
							},
							scale: {
								display: false
							},
							angleLines:{
								display: true,
								color: '#B1B2C1',
								lineWidth: 0.5,
							},
							pointLabels: {
								fontColor: '#2C2D43',
								fontFamily: this.font,
                                fontSize: 20
							},
							gridLines: {
								color: '#B1B2C1',
								lineWidth: 0.5
							}
						}
					}
				});

				ctx.webkitImageSmoothingEnabled = false;
				ctx.mozImageSmoothingEnabled = false;
				ctx.imageSmoothingEnabled = false;


			},

			loadChart(){
				if (this.comparison_report) {
                    this.chart_data.labels = _.pluck(this.data, 'name');

                    this.chart_data.max_rating = _.pluck(this.data, 'max_rating')[0];

                    this.chart_data.datasets[0].label = this.__('translation.after');
                    this.chart_data.datasets[1].label = this.__('translation.before');
                    this.chart_data.datasets[0].data = _.pluck(_.pluck(this.data, 'respondents'), 'avg');
                    this.chart_data.datasets[1].data = _.pluck(_.pluck(this.data, 'prev_respondents'), 'avg');


                    this.to_update = true;
                    this.startChart();
					this.chart_canvas.update();

				}
				else {
					this.chart_data.labels = _.pluck(this.data, 'name');
                    this.chart_data.max_rating = _.pluck(this.data, 'max_rating')[0];

                    this.chart_data.datasets[1].data = _.pluck(_.pluck(this.data, 'participants'), 'avg');
					this.chart_data.datasets[0].data = _.pluck(_.pluck(this.data, 'respondents'), 'avg');



                    this.chart_data_2.labels = _.pluck(this.data, 'name');
					this.chart_data_2.datasets[1].data = _.pluck(_.pluck(this.data, 'participants'), 'avg');
					this.chart_data_2.datasets[0].data = _.pluck(_.pluck(this.data, 'respondents'), 'avg');
                    this.chart_data_2.max_rating = _.pluck(this.data, 'max_rating')[1];

					this.to_update = true;
                    this.startChart();
					this.chart_canvas.update();
					this.chart_canvas2.update();
				}

			},



			createChartPlugin(){
				let _this = this;

				return {
					id: 'export_png_data',

					beforeDraw : function(chart, options){
						if (_this.to_update)
							return;

						_this.to_update = true;
					},

					afterRender: function(chart, options) {

					}
				};
			},
		},

		watch: {

		}
	}
</script>
