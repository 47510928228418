<template>
	<div class="reporting-page--section" data-section="summary">
		<collapsable :title="__('translation.reporting_section_summary')">
			<div v-if="loading" class="loaders--standard override"></div>

			<!--table-->
			<div class="" v-else>
				<div class="row">
					<div class="col-12">
						<hr />
					</div>
				</div>

                <div class="row mb-3">
                    <div class="col-12">
                        {{__('reporting.insights.summary_description')}}
                    </div>
                </div>

				<!--Dots-->
                <div v-if="comparison_data" class="row my-4">
					<div class="d-flex" v-bind:class="has_respondents ? 'col-3' : 'col-6'">
						<div class="d-flex my-auto">
							<div class="dot" :style="{'background-color': primary_color_faded}"></div>
						</div>
						<span class="d-block my-auto pl-2" :style="{'color': primary_color_faded}">{{has_respondents ? __('translation.prev_self') : __('translation.prev_participant')}}</span>
					</div>
					<div v-if="has_respondents" class="col-3 d-flex">
						<div class="d-flex my-auto">
							<div class="dot" :style="{'background-color': secondary_color_faded}"></div>
						</div>
						<span class="d-block my-auto pl-2" :style="{'color': secondary_color_faded}">{{__('translation.prev_respondent')}}</span>
					</div>

                    <div class="d-flex" v-bind:class="has_respondents ? 'col-3' : 'col-6'">
                        <div class="d-flex my-auto">
                            <div class="dot" :style="{'background-color': primary_color}"></div>
                        </div>
                        <span class="d-block my-auto pl-2" :style="{'color': primary_color}">{{has_respondents ? __('translation.self') : __('translation.participant')}}</span>
                    </div>
                    <div v-if="has_respondents" class="col-3 d-flex">
                        <div class="d-flex my-auto">
                            <div class="dot" :style="{'background-color': secondary_color}"></div>
                        </div>
                        <span class="d-block my-auto pl-2" :style="{'color': secondary_color}">{{__('translation.respondent')}}</span>
                    </div>
                </div>
				<div v-else class="row my-4">
					<div class="d-flex" v-bind:class="has_respondents ? 'col-5' : 'col-12'">
						<div class="dot" :style="{'background-color': primary_color}"></div>
						<span class="d-block my-auto pl-2" :style="{'color': primary_color}">{{has_respondents ? __('translation.self') : __('translation.participant')}}</span>
					</div>
					<div v-if="has_respondents" class="col-7 d-flex">
						<div class="dot" :style="{'background-color': secondary_color}"></div>
						<span class="d-block my-auto pl-2" :style="{'color': secondary_color}">{{__('translation.respondent')}}</span>
					</div>
				</div>

				<div v-for="section in compiled_data" class="row mx-0 reporting-page--section-card">
					<div class="col-4 d-flex">
						<h5 class="my-auto font-weight-bold">{{section.name}}</h5>
					</div>
					<div class="col-8">
						<!--Comparison Report-->
						<div v-if="comparison_data" class="d-flex">
							<div class="w-100 my-auto">
								<div class="progress-bar dark my-3">
									<div class="progress-bar--progress" :style="{'width': section.comparison_participant_percentage + '%', 'background-color': primary_color_faded}"></div>
								</div>
								<div v-show="has_respondents" class="progress-bar dark my-3">
									<div class="progress-bar--progress" :style="{'width': section.comparison_respondent_percentage + '%', 'background-color': secondary_color_faded}"></div>
								</div>
							</div>
							<div class="ml-4">
								<h5 class="my-1" :style="{'color': primary_color_faded}">{{parseFloat(section.comparison_participant_score).toFixed(1)}}</h5>
								<h5 class="my-1" v-show="has_respondents" :style="{'color': secondary_color_faded}">{{parseFloat(section.comparison_respondent_score).toFixed(1)}}</h5>
							</div>
						</div>

						<!--Current Report-->
						<div class="d-flex">
							<div class="w-100 my-auto">
								<div class="progress-bar dark my-3">
									<div class="progress-bar--progress" :style="{'width': section.participant_percentage + '%', 'background-color': primary_color}"></div>
								</div>
								<div v-show="has_respondents" class="progress-bar dark my-3">
									<div class="progress-bar--progress" :style="{'width': section.respondent_percentage + '%', 'background-color': secondary_color}"></div>
								</div>
							</div>
							<div class="ml-4">
								<h5 class="my-1" :style="{'color': primary_color}">{{parseFloat(section.participant_score).toFixed(1)}}</h5>
								<h5 class="my-1" v-show="has_respondents" :style="{'color': secondary_color}">{{parseFloat(section.respondent_score).toFixed(1)}}</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</collapsable>
		<hr v-if="!loading" />
	</div>
</template>

<script>
	import _ from "underscore/underscore"
	import Collapsable from "./Collapsable";

	export default {
		name: "SummaryOfCompetencies",
		components: {Collapsable},
		props: [
			'load_url',
			'token',

			'primary_color',
			'secondary_color',
			'primary_color_faded',
			'secondary_color_faded',
		],

		data(){
			return {
				loading: true,
				pre_loading: true,

				data: [],
				comparison_data: null,
				compiled_data: [],
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			loadData(){
				let _this = this;

				_this.loading = true;

				axios.post(_this.load_url, {
					token: this.token,
					code: 'summary'
				})
					.then(function (response){
						_this.loading = false;

						_this.data = response.data.data;
						_this.comparison_data = response.data.comparison_report_data;
						//Parsing PDF DATA
						let pdf_data = {
							sections: [],
                            comparison_report: !!_this.comparison_data,
							primary_color: _this.primary_color,
							secondary_color: _this.secondary_color,
							primary_color_faded: _this.primary_color_faded,
							secondary_color_faded: _this.secondary_color_faded,
						};

						_this.data.forEach(function (section){
							pdf_data.sections.push({
								name: section.name,
								participant_score: section.participants.avg,
								participant_percentage: section.participants.percentage,
								respondent_percentage: section.respondents.percentage,
								respondent_score: section.respondents.avg,
							});
						});

						if (_this.comparison_data){
							_this.comparison_data.forEach(function (section){
								for (let i = 0; i < pdf_data.sections.length; i++)
									if (section.name === pdf_data.sections[i].name){
										pdf_data.sections[i].comparison_participant_score = section.participants.avg;
										pdf_data.sections[i].comparison_participant_percentage = section.participants.percentage;
										pdf_data.sections[i].comparison_respondent_percentage = section.respondents.percentage;
										pdf_data.sections[i].comparison_respondent_score = section.respondents.avg;
										break;
									}
							});
						}


						_this.compiled_data = pdf_data.sections;
						_this.$emit('loaded', pdf_data);
					})
					.catch(function (){
						//@todo
					})
			}
		},

		computed: {
			has_respondents(){
				return this.data && this.data.length && (this.data[0].respondents.percentage || (
					this.comparison_data && this.comparison_data.length && this.comparison_data[0].respondents.percentage
				));
			}
		},

		watch: {
			token() {
				this.$emit('unloaded');
				if (this.token !== null) {
					this.pre_loading = false;
					this.loading = false;
					this.loadData();
				}
				else {
					this.pre_loading = true;
					this.loading = true;
				}
			}
		}
	}
</script>
