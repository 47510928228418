<template>
	<div class="w-100 justify-content-center">
		<!--Page 1-->

		<div class="container" :style="{'min-height': height}" v-if="page === 0">
			<div class="row mt-5">
				<div class="col-12">
					<h1 class="text-center">{{__('translation.rating_type_select_title')}}</h1>
				</div>
			</div>
			<div class="row mt-5">
				<div class="rating-cards--wrapper">
					<!--<div v-on:click="selectRatingType('slider')" v-bind:class="typeSelected.indexOf('slider') !== -1 ? 'active' : ''" class="rating-cards&#45;&#45;card">-->
					<!--	<div class="rating-cards&#45;&#45;card-icon">-->
					<!--		<img src="/svg/slider.svg" class="">-->
					<!--	</div>-->
					<!--	<h4 class="rating-cards&#45;&#45;card-text">{{__('translation.rating_types.slider')}}</h4>-->
					<!--</div>-->
					<div v-on:click="selectRatingType('text')" v-bind:class="typeSelected.indexOf('text') !== -1 ? 'active' : ''" class="rating-cards--card">
						<div class="rating-cards--card-icon">
							<img src="/svg/text-options.svg" class="">
						</div>
						<h4 class="rating-cards--card-text">{{__('translation.rating_types.text')}}</h4>
					</div>
					<div v-on:click="selectRatingType('smileys')" v-bind:class="typeSelected.indexOf('smileys') !== -1 ? 'active' : ''" class="rating-cards--card">
						<div class="rating-cards--card-icon">
							<img src="/svg/faces.svg" class="">
						</div>
						<h4 class="rating-cards--card-text">{{__('translation.rating_types.smileys')}}</h4>
					</div>
					<div v-on:click="selectRatingType('stars')" v-bind:class="typeSelected.indexOf('stars') !== -1 ? 'active' : ''" class="rating-cards--card">
						<div class="rating-cards--card-icon">
							<img src="/svg/stars.svg" class="">
						</div>
						<h4 class="rating-cards--card-text">{{__('translation.rating_types.stars')}}</h4>
					</div>
					<div v-on:click="selectRatingType('numbers')" v-bind:class="typeSelected.indexOf('numbers') !== -1 ? 'active' : ''" class="rating-cards--card">
						<div class="rating-cards--card-icon">
							<img src="/svg/numbers.svg" class="">
						</div>
						<h4 class="rating-cards--card-text">{{__('translation.rating_types.numbers')}}</h4>
					</div>
					<div v-on:click="selectRatingType('percentage')" v-bind:class="typeSelected.indexOf('percentage') !== -1 ? 'active' : ''" class="rating-cards--card">
						<div class="rating-cards--card-icon">
							<img src="/svg/percentage.svg" class="">
						</div>
						<h4 class="rating-cards--card-text">{{__('translation.rating_types.percentage')}}</h4>
					</div>
				</div>
			</div>
		</div>

		<!--Page 2-->

		<div class="container" :style="{'min-height': height}" v-if="page === 1">
			<div class="row mt-5">
				<div class="col-12">
					<h1 class="text-center">{{__('translation.rating_create_title')}}</h1>
					<h5 class="text-center">{{__('translation.rating_choose_title')}}</h5>
				</div>
			</div>
			<div class="row mt-5">
				<div class="col-12 text-center mb-2" style="min-height: 30px;">
					<transition name="fade">
						<div v-if="scaleSelected.length < 2" class="rating-cards--tooltip">
							<img src="/svg/tooltip.svg" />
							<div class="rating-cards--tooltip-body">
								<span class="rating-cards--tooltip-body-text">{{__('translation.choose_two_numbers')}}</span>
							</div>
						</div>
					</transition>
				</div>
				<div class="rating-cards--wrapper numbers">
					<div
						class="rating-cards--numbers-tag"
						v-for="i in numbers_range"
						v-on:click="selectScaleNumber(i)"
						v-bind:class="scaleSelected.indexOf(i) !== -1 ? 'active' : ''"
					>{{ i }}</div>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-md-6 text-center text-md-right">
					<a class="d-inline-flex my-auto mx-4" href="javascript:void(0)" v-on:click="flipped = !flipped">
						<img src="/svg/flip.svg" class="mr-2">
						<div v-if="!flipped" class="my-auto">
							{{__('translation.flip_numbers')}}
						</div>
						<div v-else class="my-auto">
							{{__('translation.flip_numbers_reverse')}}
						</div>
					</a>
				</div>
				<div class="col-md-6 text-center text-md-left">
					<a class="d-inline-flex my-auto mx-4" href="javascript:void(0)" v-on:click="na_option = !na_option">
						<label class="switch mr-2 my-auto">
							<input type="checkbox" v-model="na_option">
							<span class="slider round"></span>
						</label>
						<div class="my-auto">
							{{__('translation.na_option')}}
						</div>
					</a>
				</div>
			</div>
		</div>

		<!--Page 3-->

		<div class="container" :style="{'min-height': height}" v-if="page === 2">
			<!--Smiley Select Modal-->
			<smiley-select-modal
				id="smiley-select"
				@select="selectSmiley"
			></smiley-select-modal>

			<div class="row mt-5">
				<div class="col-12">
					<h1 class="text-center">{{__('translation.rating_meaning_title')}}</h1>
				</div>
			</div>
			<div class="row mt-4 justify-content-center">
				<div class="col-md-6 col-lg-4 text-center">
					<div class="d-inline-flex w-100 my-2 justify-content-center" v-for="(index, i) in numbers_selected_ordered">
						<h5 class="d-block my-auto mr-3 text-right" style="width: 20px;">{{index}}</h5>

						<input
							type="text"
							class="form-control w-75"
							v-bind:class="typeSelected.indexOf('text') !== -1 && texts[index] == '' ? 'border border-danger' : ''"
							:placeholder="__('translation.add_title')"
							:tabindex="i + 20"
							v-model="texts[index]"
						/>
						<a href="javascript:void(0)" v-if="typeSelected.indexOf('smileys') !== -1" v-on:click="openSmileyModal(index)">
							<img
								class="ml-2"
								width="44"
								height="44"
								v-bind:class="typeSelected.indexOf('smileys') !== -1 && smileysSelected[index] == '' ? 'rounded-circle' : ''"
								v-bind:src="smileysSelected[index] !== '' ? '/smileys/' + smileysSelected[index] + '.svg' : '/svg/faces.svg'"
								ondragstart="return false;"
							/>
						</a>
					</div>
				</div>
			</div>
		</div>

		<!--Footer-->

		<div class="container mb-4">
			<div class="row mt-5">
				<div class="col-12 text-center">
					<button class="btn btn-back" v-if="!(page === 0 && campaign_crud)" v-on:click="backButton" v-bind:class="!can_back ? 'disabled' : ''" v-bind:disabled="!can_back">{{__('translation.back_button')}}</button>
					<div class="d-inline pr-5 mr-5" v-if="page === 0 && campaign_crud"></div>
					<button class="btn btn-primary" v-on:click="nextButton" v-bind:class="!can_next ? 'disabled' : ''" v-bind:disabled="!can_next">
						{{ button_label }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SmileySelectModal from './../modals/SmileySelectModal'

	export default {
		props: [
			'back_url',
			'only_one_edit',
			'rating_scale',
			'save_url',
			'update',
			'campaign_crud',
		],

		components: {
			SmileySelectModal
		},

		data(){
			return {
				typeSelected: [],
				scaleSelected: [],
				texts: ['','','','','','','','','','',''],
				smileysSelected: ['','','','','','','','','','',''],
				flipped: false,
				na_option: true,
				page: 0,
				editing_smiley: null,

				height: '',

				saving: false,

				limit_one: false,
			}
		},

		mounted() {
			// SaveProtection.lockWindow();
			if (this.only_one_edit || this.rating_scale){
				this.limit_one = true;
			}

			if (this.rating_scale){
				let _this = this;
				_this.typeSelected = [_this.rating_scale.code];
				_this.na_option = _this.rating_scale.option_na;
					_this.rating_scale.rating_options.forEach(function (option){
					_this.scaleSelected.push(option.number);
					_this.texts[option.number] = option.value;
					_this.smileysSelected[option.number] = option.smiley_code;
				});

				if (_this.rating_scale.rating_options[0].number > _this.rating_scale.rating_options[_this.rating_scale.rating_options.length - 1].number){
					_this.flipped = true;
					_this.scaleSelected.reverse();
				}
			}

			this.height = this.campaign_crud ? '320px' : '500px';
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			backButton(){
				if (this.can_back){
					if (this.page === 0){
						//Return back()
						window.location.href = this.back_url;
					}
					else {
						this.page --;
					}
				}
			},

			nextButton(){
				if (this.can_next){

					if (this.typeSelected[0] === 'percentage'){
						//Percentage is different because you dont need to save
                        this.save();
					}

					else {
						if (this.page === 2){
							this.save();
						}
						else {
							this.page ++;
						}
					}
				}
			},

			save(){
				let _this = this;

				if (_this.saving){
					return;
				}

				_this.saving = true;

				let data = {};

				if (_this.typeSelected.length > 1){
					data['multi'] = true;
					data['data'] = [];

					_this.typeSelected.forEach(function (type){
						let _type_data = {
							code: type,
							option_na: _this.na_option,
							flipped: _this.flipped,
							scale: []
						};

						_this.scaleSelected.forEach(function (scale){
							let _scale_data = {
								number: scale,
								value: _this.texts[scale],
							};

							if (type === 'smileys'){
								_scale_data.smiley_code = _this.smileysSelected[scale];
							}

							_type_data.scale.push(_scale_data);
						});

						data['data'].push(_type_data);
					});
				}else {
					data = {
						code: _this.typeSelected[0],
						option_na: _this.na_option,
						flipped: _this.flipped,
						scale: []
					};

					_this.scaleSelected.forEach(function (scale){
						let _scale_data = {
							number: scale,
							value: _this.texts[scale],
						};

						if (_this.typeSelected[0] === 'smileys'){
							_scale_data.smiley_code = _this.smileysSelected[scale];
						}

						data.scale.push(_scale_data);
					});
				}

				if (_this.update) {
					axios.put(_this.save_url, data)
						.then(function (response){
							_this.saving = false;
							_this.$emit('saved', response.data.rating);
							if(!_this.campaign_crud){
								SaveProtection.unlockWindow();
								window.location.href = _this.back_url;
							}
						})
						.catch(function (error){
							for (var i in error.response.data.errors) {
								if (error.response.data.errors.hasOwnProperty(i)) {
									toastr.error(error.response.data.errors[i][0]);
								}
							}
							_this.saving = false;
						});
				}
				else {
					axios.post(_this.save_url, data)
						.then(function (response){
							_this.saving = false;
							_this.$emit('saved', response.data.rating);
							if(!_this.campaign_crud){
								SaveProtection.unlockWindow();
								window.location.href = _this.back_url;
							}
						})
						.catch(function (error){
							for (var i in error.response.data.errors) {
								if (error.response.data.errors.hasOwnProperty(i)) {
									toastr.error(error.response.data.errors[i][0]);
								}
							}
							_this.saving = false;
						});
				}
			},


			openSmileyModal(index){
				this.editing_smiley = index;
				openModal($('#smiley-select'));
			},

			selectSmiley(smiley){
				Vue.set(this.smileysSelected, this.editing_smiley, smiley);
			},


			selectRatingType(type) {
				if (this.typeSelected.indexOf(type) !== -1){
					this.typeSelected.splice(this.typeSelected.indexOf(type), 1);
				}
				else if (!this.limit_one) {
					if (this.typeSelected.indexOf('percentage') !== -1){
						this.typeSelected.splice(this.typeSelected.indexOf('percentage'), 1);
						this.typeSelected.push(type);
					}
					else if (type === 'percentage'){
						//clears all the others
						this.typeSelected = ['percentage'];
					}
					else {
						if (this.limit_one){
							this.typeSelected.push([type]);
						}
						this.typeSelected.push(type);
					}
				}
				else {
					this.typeSelected = [type];
				}
			},

			selectScaleNumber(number){
				if (this.scaleSelected.length < 1){
					this.scaleSelected.push(number);
				}
				else {
					let
						highest = this.scaleSelected[this.scaleSelected.length - 1],
						lowest = this.scaleSelected[0];

					if (lowest <= number && highest >= number){
						this.scaleSelected = [];
					}
					else if (highest < number) {
						while (this.scaleSelected[this.scaleSelected.length - 1] !== number){
							this.scaleSelected.push(this.scaleSelected[this.scaleSelected.length - 1] + 1);
						}
					}
					else {
						while (this.scaleSelected[0] !== number){
							this.scaleSelected.splice(0, 0, this.scaleSelected[0] - 1);
						}
					}
				}
			},
		},

		computed: {
			can_back(){
				if (this.saving)
					return false;

				if (this.page === 0 && this.campaign_crud)
					return false;

				return true;
			},

			can_next(){
				if (this.saving)
					return false;

				switch (this.page){
					case 0:
						return this.typeSelected.length;
					case 1:
						return this.scaleSelected.length > 1;
					case 2:
						let
							can = true,
							_this = this;

						if (_this.typeSelected.indexOf('smileys') !== -1){

							_this.scaleSelected.forEach(function (scale){
								if (_this.smileysSelected[scale] === '')
									can = false;
							});
						}
						if (_this.typeSelected.indexOf('text') !== -1){

							_this.scaleSelected.forEach(function (scale){
								if (_this.texts[scale] === '')
									can = false;
							});
						}
						return can;
				}
			},

			numbers_selected_ordered(){
				return this.flipped
					? this.scaleSelected.slice().reverse()
					: this.scaleSelected;
			},

			numbers_range(){
				return this.flipped
					? [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
					: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
			},

			button_label(){
				if (this.typeSelected[0] === 'percentage'){
					return this.page === 0 ? this.__('translation.save_button') : this.__('translation.next_button');
				}
				else {
					return this.page === 2 ? this.__('translation.save_button') : this.__('translation.next_button');
				}
			}
		}
	}
</script>

<style>
	.fade-enter-active, .fade-leave-active {
		transition: opacity .4s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
</style>
