<template>
	<div class="container">
		<div class="row mt-4 justify-content-center">
			<div class="col-lg-7 text-center">
				<button class="btn btn-primary" v-bind:disabled="!can_send" v-on:click="send">{{__('buttons.resend')}}</button>
			</div>
			<div class="col-lg-7 text-right">
				<a href="javascript:void(0)" class="btn-link text-primary" v-on:click="toggleAll()">
					{{all_selected ? __('place_holders.select_none') : __('place_holders.select_all')}}
				</a>
			</div>
		</div>
		<div class="row mt-2 justify-content-center">
			<div class="col-lg-7">
				<div
					v-for="(user, index) in users_edit"
					class="questionnaire_edit_table--question-select-row justify-content-between"
					style="cursor: pointer;"
					v-on:click="toggleSelected(index)"
				>
					<div>
						<span class="font-weight-bold">{{user.full_name}}</span>
						<span>&lt;{{user.email}}&gt;</span>
					</div>
					<img v-show="user.selected" alt="tick" src="/svg/orange-tick.svg">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CampaignParticipantsInvitationList",
		props: [
			'users',
			'submit_url',
			'back_url'
		],

		data(){
			return {
				users_edit: [],
				all_selected: true,
				sending: false,
			}
		},

		beforeMount(){
			let _this = this;
			_this.users.forEach(function (user){
				_this.users_edit.push({
					id: user.id,
					full_name: user.full_name,
					email: user.email,
					campaign_participant_id: user.campaign_participant_id,
					selected: true,
				})
			});
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			toggleSelected(index){
				let user = this.users_edit[index];

				user.selected = !user.selected;
				this.$set(this.users_edit, index, user);

				let _test_users_selected = this.users_edit.filter(function (user){
					return user.selected
				});

				this.all_selected = _test_users_selected.length === this.users_edit.length;
			},

			toggleAll(){
				let _this = this;
				if (_this.all_selected){
					_this.users_edit.forEach(function (user, index){
						user.selected = false;
						_this.$set(_this.users_edit, index, user);
					});
				}
				else {
					_this.users_edit.forEach(function (user, index){
						user.selected = true;
						_this.$set(_this.users_edit, index, user);
					});
				}

				_this.all_selected = !_this.all_selected;
			},

			send(){
				let
					_this = this,
					participants = _this.users_edit
						.filter(function (user) { return user.selected })
						.map(function (user) {
							return user.id;
						});

				_this.sending = true;
				axios.post(_this.submit_url, {
					participants: participants
				})
					.then(function (response){
						window.location.href = _this.back_url;
					})
			}
		},
		computed: {
			can_send(){
				if (this.sending){
					return false;
				}

				//Should be faster than array.filter
				for (let i = 0; i < this.users_edit.length; i++){
					if (this.users_edit[i].selected)
						return true
				}

				return false;
			}
		}
	}
</script>