<template>
	<div class="row">
		<div
            class="col-4 text-center p-2"
            style="cursor: pointer"
            v-for="(option, index) in options"
            v-on:click="select(index)"
            v-on:mouseenter="number_highlighted = index"
            v-on:mouseleave="number_highlighted = -1"
        >
            <div class="d-block">
                <img v-show="selected === index || number_highlighted === index" :src="'/smileys/'  + option.smiley_code + '.svg'">
                <img v-show="selected !== index && number_highlighted !== index" :src="'/smileys/'  + option.smiley_code + '-inactive.svg'">

            </div>
            <span class="d-block w-100 text-grey" style="min-height: 15px">{{option.value}}</span>

		</div>

		<div class="col-12 mt-3" style="min-height: 29px">
			<!--<h5 class="text-primary text-center font-weight-bold font-italic">{{displayValue}}</h5>-->
		</div>
	</div>
</template>

<script>
	export default {
		name: "SmileysRating",
		props: [
			'options',
			'answer'
		],

		data(){
			return {
				selected: -1,
				number_highlighted: -1
			}
		},

		methods: {
			select(i){
				if (this.selected === i)
					return ;
				this.selected = i;
				this.$emit('select', this.options[i]);
			},

			reset(){
				this.selected = this.number_highlighted = -1;
				this.$nextTick(function () {
					if (this.answer){
						for (let i = 0; i < this.options.length ; i ++){
							if (this.options[i].number === this.answer.rating){
								this.selected = i;
								return;
							}
						}
					}
				});
			}
		},
        computed: {
			displayValue(){
				if (this.number_highlighted > -1){
					return this.options[this.number_highlighted].value;
				}
				if (this.selected > -1){
					return this.options[this.selected].value
				}

				return '';
            }
        }
	}
</script>
