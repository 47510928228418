var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"text-primary text-center font-weight-bold font-italic"},[_vm._v(_vm._s(_vm.options[_vm.selected].value))])]),_vm._v(" "),_c('div',{staticClass:"col-12 mt-3"},[_c('vue-slider-component',{attrs:{"max":_vm.options.length - 1,"dotSize":20,"lazy":true,"piecewise":true,"processStyle":{
					backgroundColor: '#f0612e',
  					backgroundImage: '-webkit-linear-gradient(left, #fdaa8d, #f0612e)',
					padding: '5px',
					top: '-1px'
				},"sliderStyle":{
					position: 'relative',
					top: '-6px',
    				left: '-6px',
					width: '32px',
					height: '32px',
					border: '2px solid #e5e5e5'
				},"piecewiseStyle":{
					backgroundColor: '#ccc',
					visibility: 'visible',
					left: '-1px',
					width: '2px',
					height: '10px',
				},"piecewiseActiveStyle":{
					backgroundColor: '#fff'
				},"tooltip":"none","width":"280","height":"8"},on:{"drag-end":_vm.pre_select,"callback":_vm.select},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }