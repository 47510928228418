<template>
	<div class="reporting-page--section" data-section="feedback">
		<collapsable :title="__('translation.reporting_section_feedback')">
			<div v-if="loading" class="loaders--standard override"></div>

			<!--table-->
			<div class="row" v-else>
				<div class="col-12 mt-3">
					<h5 class="font-weight-bold">{{__('reporting.feedback.report_criteria')}}</h5>
				</div>

				<div class="col-12 mt-2">{{__('reporting.feedback.report_is_based')}}:</div>

				<!--Participants-->
				<div class="col-3 mt-2">
					<h5 class="mb-0">
						{{ data.participants.complete }}/{{ data.participants.total }}
						<span v-if="data.comparison_report_data" class="pl-2 font-weight-normal" v-bind:class="compareValues(data.participants.complete, data.comparison_report_data.participants.complete).class">
							{{compareValues(data.participants.complete, data.comparison_report_data.participants.complete).label}}
						</span>
					</h5>
					<span class="text-secondary font-weight-bold">{{ getParticipantsLabel(data) }}</span>
				</div>
				<div v-for="(num, name) in data.all_terms" class="col-3 mt-2">
					<h5 v-if="!data.comparison_report_data" class="mb-0">{{ num.complete }}/{{ num.total }}</h5>
					<h5 v-else class="mb-0">
						{{ num[0].complete }}/{{ num[0].total }}
						<span class="pl-2 font-weight-normal" v-bind:class="compareValues(num[0].complete, num[1].complete).class">
							{{compareValues(num[0].complete, num[1].complete).label}}
						</span>
					</h5>
					<span class="text-secondary font-weight-bold">{{ name }}</span>
				</div>

				<div class="col-12">
					<hr />
				</div>

				<!--Measurements-->
				<div class="col-12">
					<h5 class="font-weight-bold">{{__('reporting.feedback.measurements')}}</h5>
				</div>

				<div class="col-12">
					<span v-html="__('reporting.feedback.num_questions_in_competency').replace(':statements_questions', data.num_questions)"></span>
					<span v-if="data.comparison_report_data" class="font-weight-normal" v-bind:class="compareValues(data.num_questions, data.comparison_report_data.num_questions).class">
						{{compareValues(data.num_questions, data.comparison_report_data.num_questions).label}}
					</span>
				</div>
				<div class="col-12">{{__('reporting.feedback.questions_in_competency')}}</div>
				<div v-for="(num, name) in data.sections" class="col-6 mt-2">
					<h5 class="mb-0">
						{{num}}
						<span v-if="data.comparison_report_data && compareValues(num, data.comparison_report_data.sections[name]).different" class="pl-2 font-weight-normal" v-bind:class="compareValues(num, data.comparison_report_data.sections[name]).class">
							{{compareValues(num, data.comparison_report_data.sections[name]).label}}
						</span>
					</h5>
					<span class="text-secondary font-weight-bold">{{name}}</span>
				</div>

                <div class="col-12">
                    <hr />
                </div>
                <div class="col-12">
                    <h5 class="font-weight-bold">{{__('reporting.feedback.rating_system')}}</h5>
                </div>
                <div class="col-12">
                    <span v-html="__('reporting.feedback.rating_system_score_range').replace(':min' , min_rating).replace(':max', max_rating).replace(':highest', highest_rating)"></span>
                </div>
				<div v-if="hasRatingKeys">
					<div class="col-12 my-2">
						<span>{{__('reporting.feedback.rating_system_keys')}}</span>
					</div>
					<div v-for="option in data.rating_scale.rating_options" class="col-12">
						<span v-if="option.value">{{option.number + ' = ' + option.value}}</span>
					</div>
				</div>
			</div>
		</collapsable>
		<hr v-if="!loading" />
	</div>
</template>

<script>
	import _ from "underscore/underscore"
	import Collapsable from "./Collapsable";
	export default {
		name: "MakeSenseTable",
		components: {Collapsable},
		props: [
			'load_url',
            'campaign',
			'token'
		],

		data(){
			return {
				pre_loading: true,
				loading: true,

				data: {},
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			loadData(){
				let _this = this;

				_this.loading = true;

				axios.post(_this.load_url, {
					token: _this.token,
					code: 'feedback'
				})
					.then(function (response){
						_this.loading = false;

						_this.data = response.data;
						_this.data.all_terms = _this.all_terms;
						_this.data.rating_scale.min = _this.min_rating;
						_this.data.rating_scale.max = _this.max_rating;
						_this.data.rating_scale.highest = _this.highest_rating;
						_this.$emit('loaded', _this.data);
					})
					.catch(function (){
						//@todo
					})
			},

			compareValues(a, b){
				const val = a - b;
				if (val > 0)
					return {
						class: 'text-success',
						different: true,
						label: '+' + val
					};
				else if (val < 0)
					return {
						class: 'text-danger',
						different: true,
						label: val
					};
				else
					return {
						class: 'text-grey',
						different: false,
						label: '0'
					};
			},

			getParticipantsLabel(data){
				if (data.terms && Object.values(data.terms).length){
					return this.__('translation.self_assessment');
				}
				return data.participants > 1 ? this.__('translation.participants') : this.__('translation.participant');
			}
		},

		computed: {
			min_rating(){
				return this.data.rating_scale.rating_options[0].number;
            },

            max_rating(){
				return this.data.rating_scale.rating_options[this.data.rating_scale.rating_options.length - 1].number;
            },

            highest_rating(){
				return this.data.rating_scale.rating_options[this.data.rating_scale.rating_options.length - 1].number;
            },

			all_terms(){
				if (!this.data.comparison_report_data)
					return this.data.terms;

				let terms = {};
				for (let key in this.data.terms)
					if (this.data.terms.hasOwnProperty(key))
						terms[key] = [this.data.terms[key]];

				for (let key in this.data.comparison_report_data.terms)
					if (this.data.comparison_report_data.terms.hasOwnProperty(key)){
						if (!terms[key]){
							terms[key] = [0, this.data.comparison_report_data.terms[key]];
							continue;
						}

						terms[key] = [terms[key][0], this.data.comparison_report_data.terms[key]];
					}

				return terms;
			},

			hasRatingKeys(){
				return !!this.data.rating_scale.rating_options.filter(rate => !!rate.value).length;
			}
		},

		watch: {
			token() {
				this.$emit('unloaded');
				if (this.token !== null) {
					this.pre_loading = false;
					this.loading = false;
					this.loadData();
				}
				else {
					this.pre_loading = true;
					this.loading = true;
				}
			}
		}
	}
</script>
