<template>
	<div class="container mt-4">
		<!--header-->
		<div v-if="type !== 'campaign'" class="row">
			<div class="col-md-10">
				<h1>
					{{questionnaire_name}}
					<small v-if="requests.length || updating">Saving...</small>
				</h1>
			</div>
			<div class="col-md-2">
				<!--<button class="btn btn-primary">{{__('buttons.save_button')}}</button>-->
			</div>
		</div>

		<!--body-->
		<div class="row">
			<div class="col-md-10 order-1 order-md-0">
				<div v-if="sections.length" class="container">
					<div class="row">
						<!--Folder Select-->
						<div class="col-lg-4">
							<ul class="list-group">
								<draggable v-model="sections" @end="orderSection" :move="checkPermissionToMove">
									<transition-group name="list-transition">
										<li
											v-for="(section, index) in sections"
											class="list-group-item list-transition-item"
											:key="section.id"
										>
											<a href="javascript:void(0)" v-on:click="selectSection(index)">
												<span v-bind:class="index === selected_section ? 'text-primary' : ''">{{section.name}}</span>
												<img v-if="index === selected_section" src="/svg/chevron-active.svg" />
												<img v-else src="/svg/chevron-inactive.svg" />
											</a>
										</li>
									</transition-group>
								</draggable>
							</ul>
						</div>

						<!--Table-->
						<div class="col-lg-8 questionnaire_edit_table--question-select">
							<draggable v-if="sections[selected_section].questions.length" v-model="sections[selected_section].questions" @end="orderQuestion" :move="checkPermissionToMove">
								<transition-group name="list-transition">
									<div
										v-for="(question, index) in sections[selected_section].questions"
										class="questionnaire_edit_table--question-select-row list-transition-item"
										:key="question.id"
									>
										<img class="pr-2" :src="getQuestionIcon(question)" alt="">
										<p class="questionnaire_edit_table--question-select-row-question">
											{{trimQuestion(question.question)}}
										</p>
										<a href="javascript:void(0)" v-if="type !== 'company_library' && checkPermission()" v-on:click="editQuestion(index)" class="questionnaire_edit_table--question-select-row-button">
											<img src="/svg/edit_icon.svg">
										</a>
										<a href="javascript:void(0)" v-if="(type !== 'company_library' && checkPermission()) || type === 'comparison_report'" v-on:click="removeQuestion(index)" class="questionnaire_edit_table--question-select-row-button pl-0">
											<img src="/svg/delete-question.svg">
										</a>
									</div>
								</transition-group>
							</draggable>

							<div class="col-lg-8" v-else-if="checkPermission()">
                                <a href="javascript:void(0)" v-on:click="openCreateQuestion()">
                                    <h5 class="font-weight-bold ">{{__('translation.create_question')}}</h5>
                                </a>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="container">
					<div class="row mt-4">
						<div class="col-12" v-if="checkPermission()">
							<h5 class="font-weight-bold">{{__('translation.create_question_section')}}</h5>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-2 order-0 order-md-1 right-side-list">
				<a v-if="type !== 'company_library' && checkPermission() && !temp_check" class="right-side-list--item" href="javascript:void(0)" v-on:click="openCreateQuestionnaireSection()">{{__('buttons.create_questionnaire_section_button')}}</a>
				<a v-if="type !== 'company_library' && sections.length && checkPermission()" class="right-side-list--item" href="javascript:void(0)" v-on:click="openCreateQuestion()">{{__('buttons.create_question_button')}}</a>
				<a v-if="type !== 'company_library' && sections.length && checkPermission() && !temp_check" class="right-side-list--item" href="javascript:void(0)" v-on:click="openChangeQuestionnaireSection()">{{__('buttons.change_section_button')}}</a>
				<a v-if="type !== 'company_library' && sections.length && checkPermission() && !temp_check" class="right-side-list--item" href="javascript:void(0)" v-on:click="removeSection()">{{__('buttons.delete_section_button')}}</a>
				<!--Saving copies-->
				<a v-if="type !== 'company_library' && checkPermission()" class="right-side-list--item" href="javascript:void(0)" v-on:click="openUploadCSVModal()">{{__('buttons.upload_csv')}}</a>
				<a v-if="sections.length" class="right-side-list--item" href="javascript:void(0)" v-on:click="downloadCSV()">{{__('buttons.download_csv')}}</a>
				<a v-if="sections.length && type !== 'my_library' && PermissionsController.can('crud self questionnaires library')" class="right-side-list--item" href="javascript:void(0)" v-on:click="duplicateToPersonalLibrary()">{{__('buttons.save_in_personal_library')}}</a>
				<a v-if="sections.length && type === 'my_library' && PermissionsController.can('save company questionnaires library')" class="right-side-list--item" href="javascript:void(0)" v-on:click="duplicateToPersonalLibrary()">{{__('buttons.save_in_company_library')}}</a>
				<!--Edit this questionnaire-->
				<hr v-if="checkPermission() && type !== 'campaign'" />
				<a v-if="type === 'campaign'" class="right-side-list--item" href="javascript:void(0)" v-on:click="replaceQuestionnaire()">{{__('buttons.replace_questionnaire')}}</a>
				<a v-if="checkPermission() && type !== 'campaign'" class="right-side-list--item" href="javascript:void(0)" v-on:click="openChangeQuestionnaireName()">{{__('buttons.rename_questionnaire')}}</a>
				<a v-if="checkPermission() && type !== 'campaign'" class="right-side-list--item" href="javascript:void(0)" v-on:click="deleteThisQuestionnaire()">{{__('buttons.delete_questionnaire_from_library')}}</a>

				<!--Permissions-->
				<div v-if="type === 'campaign' && sections.length && filter_sections">
					<hr>
					<span class="right-side-list--item">Show this section to:</span>
					<div class="right-side-list--item group" v-for="term_filter in sections[selected_section].filters_data">
						<span class="font-weight-bold">{{term_filter.name}}</span>
						<div class="checkbox w-auto my-auto pl-3">
							<label class="customer_checkbox">
								<input class="form-check-input" type="checkbox" @change="saveFilters" v-model="term_filter.active">
								<span class="checkmark"></span>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<csv-upload-modal
			id="csv-upload-questionnaire-modal"
			prefix="questionnaire"

			:questionnaire_id="questionnaire.id"
			:fields="['Question', 'Section']"
			:upload_url="questionnaire_csv_validate_url"
			:submit_url="questionnaire_csv_submit_url"
			@submitted="submitted"
		></csv-upload-modal>

		<change-questionnaire-name-modal
			id="change-questionnaire"
			:update_url="update_url"
			:questionnaire="questionnaire"
			@saved="changedQuestionnaireName"
		></change-questionnaire-name-modal>

		<create-questionnaire-section-modal
			id="create-section"
			v-if="!temp_check"
			:update_url="update_url"
			@saved="createdQuestionnaireSection"
		></create-questionnaire-section-modal>

		<edit-question-modal
			id="edit-question"
			:update_url="update_url"
			v-if="sections.length && sections[selected_section].questions.length && sections[selected_section].questions[selected_question]"
			:question="sections[selected_section].questions[selected_question]"
			@saved="questionEdited"
		></edit-question-modal>

		<change-questionnaire-section-name
			v-if="sections.length"
			id="change-section"
			:update_url="update_url"
			:section="sections[selected_section]"
			@saved="changedQuestionnaireSection"
		></change-questionnaire-section-name>

		<create-question-modal
			id="create-question"
			:update_url="update_url"
			:sections="sections"
			:selected_index="selected_section"
			@saved="createdQuestion"
		></create-question-modal>

		<duplicate-questionnaire-modal
			id="duplicate-questionnaire"
			:questionnaire="questionnaire"
			:request_url="duplicate_url"
			:folders="save_folders"
			:type="type"
		></duplicate-questionnaire-modal>

	</div>
</template>

<script>
	import draggable from 'vuedraggable'
	import CreateQuestionnaireSectionModal from "../modals/CreateQuestionnaireSectionModal";
	import ChangeQuestionnaireNameModal from "../modals/ChangeQuestionnaireNameModal";
	import CreateQuestionModal from "../modals/CreateQuestionModal";
	import ChangeQuestionnaireSectionName from "../modals/ChangeQuestionnaireSectionNameModal";
	import DuplicateQuestionnaireModal from "../modals/DuplicateQuestionnaireModal"
	import CSVUploadModal from "../modals/CSVUploadModal"
	import EditQuestionModal from "../modals/EditQuestionModal";

	export default {
		name: "QuestionnaireCreateOrEditPage",
		components: {
			EditQuestionModal,
			ChangeQuestionnaireSectionName,
			ChangeQuestionnaireNameModal,
			CreateQuestionnaireSectionModal,
			CreateQuestionModal,
			DuplicateQuestionnaireModal,
			'csv-upload-modal': CSVUploadModal,
			draggable,
		},
		props: [
			'reload_url',
			'update_url',
			'delete_url',
			'duplicate_url',
			'download_csv_url',
			'type',
			'save_folders',
			'questionnaire',
			'terms',
			'company_terms',
			'questionnaire_csv_submit_url',
			'questionnaire_csv_validate_url',
			'filter_sections',
			'temp_check'
		],

		data(){
			return {
				selected_section: 0,
				selected_question: 0,
				sections: [],
				drag: false,

				questionnaire_name: '',

				requests: [],
				updating: false,
			}
		},

		beforeMount(){
			this.PermissionsController = PermissionsController;

			this.sections = this.questionnaire.sections;
			this.questionnaire_name = this.questionnaire.name;

			if (this.type === 'campaign'){
				this.getSectionFilters();
			}
		},

		mounted(){
			if (!this.sections.length && !this.temp_check){
				this.openCreateQuestionnaireSection();
			}
			else if (!this.sections.length && this.temp_check){
				let
					_this = this,
					questionnaire_section = {
						id: 0,
						name: 'Temperature Check',
						questions: [],
						order: 1,
						filters: []
					};
				_this.sections.push(questionnaire_section);

				if (_this.type === 'campaign'){
					_this.getSectionFilters();
				}

				_this.$emit('update', this.questionnaire);

				axios.put(_this.update_url, {
					questionnaire_section_create: {
						name: 'Temperature Check'
					}
				})
					.then(function (response){
						_this.sections[0].id = response.data.questionnaire_section.id;
					})
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			update(data){
				this.sendUpdateRequest(this.requests.push(data) - 1);
			},

			getQuestionIcon(question){
				if (question.open_ended) {
					return '/svg/icon_open_ended.svg';
				}
				else if (question.yes_no_question) {
					return '/svg/icon_yes_no.svg';
				}
				else {
					return '/svg/icon_rating.svg';
				}
			},

			trimQuestion(question){
				if (question.length >= 195){ //~3 lines
					question = question.substr(0, 192) + '...';
				}

				return question;
			},

			sendUpdateRequest(index){
				let _this = this;

				if (_this.updating) return;

				SaveProtection.lockWindow();
				_this.updating = true;
				if (_this.requests[index].type === 'delete'){
					axios.delete(_this.delete_url, {params: _this.requests[index].data})
						.then(function (response){
							if (_this.requests[index].reload){
								//End all the requests and reload the page
								//This is probably deleting itself
								SaveProtection.unlockWindow();
								_this.requests = [];
								if (_this.type !== 'campaign'){
									window.location.href = _this.reload_url;
								}
								else {
									//Campaign so we need to emit up the deletion
									_this.$emit('delete_questionnaire', true);
								}
								return ;
							}
							//Looks like we updated correctly
							_this.requests.splice(index, 1); //Remove from the requests list
							_this.updating = false;

							_this.$emit('update', _this.questionnaire);

							if (_this.requests.length > 0){
								//We have new requests to send
								_this.sendUpdateRequest(0); //Always FIFO
							}
							else {
								SaveProtection.unlockWindow();
							}
						})
						.catch(function (error){
							for (var i in error.response.data.errors) {
								if (error.response.data.errors.hasOwnProperty(i)) {
									toastr.error(error.response.data.errors[i][0]);
								}
							}

							//Something went wrong but we are going to proceed with the updates

							_this.requests.splice(index, 1); //Remove from the requests list
							_this.updating = false;

							if (_this.requests.length > 0){
								//We have new requests to send
								_this.sendUpdateRequest(0); //Always FIFO
							}
							else {
								SaveProtection.unlockWindow();
							}
						});
				}
				else if(_this.requests[index].type === 'update') {
					axios.patch(_this.update_url, _this.requests[index].data)
						.then(function (response){
							//Looks like we updated correctly
							_this.requests.splice(index, 1); //Remove from the requests list
							_this.updating = false;

							_this.$emit('update', _this.questionnaire);

							if (_this.requests.length > 0){
								//We have new requests to send
								_this.sendUpdateRequest(0); //Always FIFO
							}
							else {
								SaveProtection.unlockWindow();
							}
						})
						.catch(function (error){
							for (var i in error.response.data.errors) {
								if (error.response.data.errors.hasOwnProperty(i)) {
									toastr.error(error.response.data.errors[i][0]);
								}
							}

							//Something went wrong but we are going to proceed with the updates

							_this.requests.splice(index, 1); //Remove from the requests list
							_this.updating = false;

							if (_this.requests.length > 0){
								//We have new requests to send
								_this.sendUpdateRequest(0); //Always FIFO
							}
							else {
								SaveProtection.unlockWindow();
							}
						});
				}
			},


			openUploadCSVModal(){
				openModal('#csv-upload-questionnaire-modal');
			},


			submitted(questionnaire){
				this.sections = questionnaire.sections;
			},

			checkPermission(){
				if (this.type === 'templates')
					return PermissionsController.can('save template questionnaires library');
				else if (this.type === 'my_library')
					return PermissionsController.can('crud self questionnaires library');
				else if (this.type === 'company_library')
					return PermissionsController.can('save company questionnaires library');
				else if (this.type === 'campaign')
					return true;

				return false;
			},

			checkPermissionToMove(){
				return this.type !== 'company_library' && this.checkPermission();
			},


			/* Campaigns */
			replaceQuestionnaire(){
				let _this = this;
				window.confirm(_this.__('messages.confirm_delete_questionnaire'), function(confirmed){
					if (!confirmed) return;

					//Lets make the update (delete) request
					_this.update({
						type: 'delete',
						reload: true,
						data: {
							questionnaire_id: _this.questionnaire.id
						}
					});
				});
			},

			getSectionFilters(){
				let
					_this = this;

				_this.sections.forEach(function (section){
					section.filters_data = [];

					_this.terminologies.forEach(function (term){
						let _active = true;

						for (let i = 0; i < section.filters.length; i++){
							if (section.filters[i].terminology_term_id === term.id){
								_active = false;
							}
						}

						section.filters_data.push({
							id: term.id,
							name: term.name,
							active: _active
						});
					});
				});
			},

			saveFilters(){
				if (this.type !== 'campaign') return;

				let _this = this, _section = Object.assign({}, _this.sections[_this.selected_section]);
				_section.filters_array = [];

				_section.filters_data.forEach(function (filter) {
					if (!filter.active){
						_section.filters_array.push(filter.id);
					}
				});

				_this.update({
					type: 'update',
					data: {
						questionnaire_section_update: {
							id: _section.id,
							order: _section.order,
							filters_array: _section.filters_array
						}
					}
				});
			},

			/* Questionnaire */
			deleteThisQuestionnaire(){
				let _this = this;
				window.confirm(_this.__('messages.confirm_delete_questionnaire'), function(confirmed){
					if (!confirmed) return;

					//Lets make the update (delete) request
					_this.update({
						type: 'delete',
						reload: true,
						data: {
							questionnaire_id: _this.questionnaire.id
						}
					});
				});
			},

			duplicateToPersonalLibrary(){
				openModal($('#duplicate-questionnaire'));
			},

			openChangeQuestionnaireName(){
				openModal($('#change-questionnaire'));
			},

			changedQuestionnaireName(questionnaire_name){
				this.questionnaire_name = questionnaire_name;

				if (!this.requests.length && this.type !== 'campaign') {
					window.location.reload();
				}
			},

			/* Sections */

			openCreateQuestionnaireSection(){
				openModal($('#create-section'));
			},

			createdQuestionnaireSection(questionnaire_section){
				questionnaire_section.questions = [];
				this.sections.push(questionnaire_section);

				if (this.type === 'campaign'){
					this.getSectionFilters();
				}

				this.selected_section = this.sections.length - 1;

				this.$emit('update', this.questionnaire);
			},

			openChangeQuestionnaireSection(){
				openModal($('#change-section'));
			},

			changedQuestionnaireSection(questionnaire_section){
				for (let i=0; i<this.sections.length; i++){
					if (this.sections[i].id === questionnaire_section.id){
						this.sections[i].name = questionnaire_section.name;
						this.sections[i].order = questionnaire_section.order;
						this.$emit('update', this.questionnaire);
						return ;
					}
				}
			},

			selectSection (index){
				this.selected_section = index;
			},

			orderSection(data){
				let
					new_index = data.newIndex,
					moved = data.newIndex !== data.oldIndex,
					section = this.sections[new_index];

				if (!moved) return;

				section.order = new_index;

				this.update({
					type: 'update',
					data: {
						questionnaire_section_update: {
							id: section.id,
							order: new_index
						}
					}
				});
			},

			downloadCSV(){
				if (this.saving){
					return ;
				}

				let linkClick = document.createElement('a');
				linkClick.style.display = 'none';
				linkClick.href = this.download_csv_url;
				document.body.appendChild(linkClick);
				linkClick.click();
			},

			removeSection(){
				let _this = this;
				window.confirm(_this.__('messages.confirm_delete_section'), function(confirmed){
					if (!confirmed) return;

					let index = _this.selected_section, section = _this.sections[_this.selected_section];

					//Lets make the update (delete) request
					_this.update({
						type: 'delete',
						data: {
							questionnaire_section_id: section.id
						}
					});

					//Remove from the local array
					_this.sections.splice(index, 1);

					if (!_this.sections[_this.selected_section]){
						_this.selected_section = _this.sections.length -1;
					}

					if (!_this.sections.length){
						_this.selected_section = 0;
					}
				});
			},

			/* Questions */

			openCreateQuestion(){
				openModal($('#create-question'));
			},

			createdQuestion(question){
				for (let i=0; i<this.sections.length; i++){
					if (this.sections[i].id === question.questionnaire_section_id){
						this.sections[i].questions.push(question);
						this.$emit('update', this.questionnaire);
						return ;
					}
				}
			},

			orderQuestion(data){
				let
					new_index = data.newIndex,
					moved = data.newIndex !== data.oldIndex,
					question = this.sections[this.selected_section].questions[new_index];

				if (!moved) return;

				question.order = new_index;

				this.update({
					type: 'update',
					data: {
						question_update: {
							id: question.id,
							order: new_index
						}
					}
				});
			},

			removeQuestion(index){
				let question_id = this.sections[this.selected_section].questions[index].id;
				//Lets make the update (delete) request
				this.update({
					type: 'delete',
					data: {
						questionnaire_question_id: question_id
					}
				});

				//Remove from the local array
				this.sections[this.selected_section].questions.splice(index, 1);
			},

			editQuestion(index){
				this.selected_question = index;
				openModal($('#edit-question'));
			},

			questionEdited(question){
				this.sections[this.selected_section].questions[this.selected_question].question = question.question;
				this.sections[this.selected_section].questions[this.selected_question].open_ended = question.open_ended;
				this.sections[this.selected_section].questions[this.selected_question].yes_no_question = question.yes_no_question;
			}
		},

		computed: {
			terminologies() {
				if (!this.terms || !this.company_terms){
					return null;
				}

				let collect = [], _this = this;

				_this.terms.forEach(function (term){
					for (let i=0 ; i < _this.company_terms.length; i++){
						if (term.id === _this.company_terms.terminology_id){
							collect.push({
								id: term.id,
								name: _this.company_term.terminology
							});
							break;
						}
					}

					collect.push({
						id: term.id,
						name: term.name
					});
				});

				return collect;
			}
		}
	}
</script>
