<template>
	<div class="container mt-4">
		<div class="row">
			<div class="col-2 d-flex">
				<a :href="back_url" class="d-block my-auto">{{__('buttons.back')}}</a>
			</div>
			<div class="col-8 text-center">
				<h1 class="">{{campaign.name}}</h1>
			</div>
			<div class="col-2 text-right">
				<button v-on:click="save" class="btn btn-primary">{{__('buttons.save_and_close')}}</button>
			</div>
		</div>
		<div class="row mt-3">
			<div class="col-6">
				<h1 class="">{{__('translation.campaign_information_title')}}</h1>
			</div>
		</div>

		<campaign-messages
			ref="message_system"
			:campaign="campaign_edit"
			:disabled="!company_campaign_permissions.campaign_messages || !mine"
			:messages_update_url="update_url"
			:change_messages_language_url="change_messages_language_url"
			:default_subject="default_subject"
			:languages="languages"

			:disable_auto_save="true"
			:back_url_upon_save="back_url"
		></campaign-messages>
	</div>
</template>

<script>
	import CampaignMessages from "./CampaignMessages";
	export default {
		name: "EditMessages",
		components: {CampaignMessages},
		props: [
			'campaign',
			'update_url',
			'back_url',
			'change_messages_language_url',
			'company_campaign_permissions',
			'default_subject',
			'languages',
			'mine'
		],

		data(){
			return {
				campaign_edit: null,
			}
		},

		beforeMount() {
			this.campaign_edit = this.campaign;
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			save() {
				if (this.$refs.message_system.saving)
					return;

				this.$refs.message_system.saveChanges();
			}
		},

	}
</script>