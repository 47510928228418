<template>
	<div v-if="!submitted" class="mt-4 mt-md-5">
		<span class="font-weight-bold">{{__('place_holders.write_a_review')}}</span>
		<textarea v-model="comment" class="form-control mt-2 mx-auto" rows="5" style="resize: none; max-width: 455px;"></textarea>
		<button class="btn btn-secondary mt-4" v-bind:disabled="saving" v-on:click="save()">{{__('buttons.submit')}}</button>
	</div>
	<div v-else class="mt-4 mt-md-5">
		{{__('place_holders.review_submitted')}}
	</div>
</template>

<script>
	export default {
		name: "FinalCommentComponent",
		props: [
			'submit_comment_url'
		],

		data(){
			return {
				comment: '',
				submitted: false,
				saving: false,
			}
		},

		methods: {
			'__': function (val) {
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			save(){
				let _this = this;

				if (_this.saving){
					return;
				}

				_this.saving = true;

				axios.post(_this.submit_comment_url, {
					comment: _this.comment
				})
					.then(function (){
						_this.submitted = true;
						_this.saving = false;
					})
					.catch(function (){
						_this.saving = false;
					})
			}
		}
	}
</script>