<template>
	<div class="container questionnaire_edit_table">
		<div class="questionnaire_edit_table--body">
			<div
				class="w-100"
				v-for="respondents in respondents_data"
			>
				<div class="row questionnaire_edit_table--body-row questionnaire_edit_table--body-row-header">
					<div class="col-12 questionnaire_edit_table--cell">
						<span class="text-grey">{{respondents[0].respondent.company_term ? respondents[0].respondent.company_term.name : respondents[0].respondent.term.name}}</span>
					</div>
				</div>
				<div
					class="row questionnaire_edit_table--body-row no-hover"
					v-for="respondent in respondents"
				>
					<div class="col-1 questionnaire_edit_table--cell d-flex justify-content-end">
						<img class="d-block my-auto" src="/svg/orange-tick.svg" alt="Tick" v-if="respondent.percentage_completed >= 100">
					</div>
					<div class="col-4 questionnaire_edit_table--cell">
						<span class="d-block w-100 font-weight-bold">{{respondent.respondent.user.full_name}}</span>
						<span class="">{{respondent.respondent.user.email}}</span>
					</div>
					<div class="col-3 questionnaire_edit_table--cell my-auto">{{respondent.respondent.user.department ? respondent.respondent.user.department.name : ''}}</div>
					<div class="col-3 questionnaire_edit_table--cell my-auto">
						<!--<a href="" class="text-primary mr-4">{{__('buttons.edit')}}</a>-->
						<a href="javascript:void(0)" v-on:click="confirmDeleteRespondent(respondent.id)" class="text-primary">{{__('buttons.remove')}}</a>
					</div>
					<div class="col-1 questionnaire_edit_table--cell my-auto">
						<!--<img v-if="mouseOverTable === respondent.id" src="/svg/chevron-active.svg" />-->
						<!--<img v-else src="/svg/chevron-inactive.svg" />-->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CampaignShowOnlyParticipantTable",
		props: [
			'respondents_data',
			'remove_url',
		],

		data() {
			return {
				mouseOverTable: null,
				deleting: false,
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			confirmDeleteRespondent(id){
				let _this = this;
				window.confirm(_this.__('messages.confirm_delete_participant'), function(confirmed){
					if (!confirmed) return;

					//Lets make the update (delete) request
					_this.deleteRespondent(id);
				});
			},

			deleteRespondent(id){
				let _this = this;

				if (_this.deleting) {
					return;
				}

				_this.deleting = true;

				axios.delete(_this.remove_url.replace('?', id))
					.then(function (){
						window.location.reload();
					})
					.catch(function (error) {
						for (var i in error.response.data.errors) {
							if (error.response.data.errors.hasOwnProperty(i)) {
								toastr.error(error.response.data.errors[i][0]);
							}
						}
						_this.deleting = false;
					});
			}
		}
	}
</script>
