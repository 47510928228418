<template>
	<div class="feedo-modal" v-on:click="closeModal($event)">
		<div class="feedo-modal--card" style="min-width: 400px">
			<div class="feedo-modal--card-header">
				<div class="feedo-modal--card-header-title">
					<h4>{{__('translation.pdf_download_modal')}}</h4>
				</div>
			</div>
			<div class="feedo-modal--card-body p-3">
				<div class="row">
					<div class="col-12 text-center">
						<h5>{{__('translation.pdf_message')}}</h5>
					</div>
				</div>
				<div class="progress-bar dark my-3">
					<div class="progress-bar--progress progress-bar--transition" :style="{'width': progress + '%', 'background-color': '#f0612e'}"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "InsightPDFDownload",
		props: [
			'sections',

			'primary_color',

			'subtitle',
			'file_name',

			'campaign_id',
			'participants',

			'generate_pdf_token_url',
			'generate_pdf_url',
			'merge_pdf_url'
		],

		data(){
			return {
				token: null,
				progress: 0,
				started: false,

				current_section: -1,

				default_sections: [
					'feedback',
					'spider',
					'summary',
					'percentage_score',
					'open_ended',
					'high_low',
					'leaderboard'
				]
			}
		},

		mounted() {
			//Let's start downloading pdf
			let _this = this;

			axios.post(_this.generate_pdf_token_url, {
				name: _this.file_name,
				campaign_id: _this.campaign_id,
				participants: _this.participants,
                subtitle: _this.subtitle
			})
				.then(function (response){
					_this.token = response.data.token;
					_this.next()
				});
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			next(){
				let _this = this;
				_this.current_section ++;

				_this.progress = (_this.current_section + 1) / _this.default_sections.length * 100;
				if (_this.current_section >= _this.default_sections.length){
					//Finish
					_this.mergeAndDownload();
					return;
				}

				let
					section = _this.default_sections[_this.current_section],
					data = null;

				//check if spider diagram (uses other data)
				if (section === 'spider' && _this.sections.spider.data){
					data = _this.sections.spider.data;
					data.summary_card = _this.sections.summary_card.data;
				}
				else {
					data = _this.sections[section].data;
				}

				if (data){
					data.token = _this.token;
					data.subtitle = _this.subtitle;
					axios.post(_this.generate_pdf_url + '/' + section, data)
						.then(function (){
							_this.next();
						})
						.catch(function (){
							_this.$emit('error');
						})
				}
				else {
					_this.next();
				}
			},

			mergeAndDownload(){
				const link = document.createElement('a');
				link.href = this.merge_pdf_url.replace('?', this.token);
				link.style.display = 'none';
				link.setAttribute('download', this.file_name + '.pdf'); //or any other extension
				document.body.appendChild(link);
				link.click();

				this.$nextTick(function () {
					this.$emit('finished');
					window.closeModal();
				})
			},

			closeModal(e, force = false){
				if (!force){
					return;
				}

				window.closeModal();
			},
		}
	}
</script>
