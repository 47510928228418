<template>
	<div class="search-input">
		<img class="search-input--icon" src="/svg/search.svg" v-on:click="submitSearch()">
		<input type="text" v-model="search" class="search-input--input" v-on:keyup.enter="submitSearch()" :placeholder="placeholder">
		<svg v-show="search" class="search-input--cancel" v-on:click="clearSearch()" width="15px" height="16px" viewBox="0 0 9 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch -->
			<title>Icon/Delete</title>
			<desc>Created with Sketch.</desc>
			<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="Misc/Tag" transform="translate(-72.000000, -10.000000)" fill="#b1b2c1" fill-rule="nonzero">
					<g id="Icon/Delete" transform="translate(72.000000, 10.000000)">
						<path d="M4.6875,2.68933983 L7.15716991,0.219669914 C7.45006313,-0.0732233047 7.92493687,-0.0732233047 8.21783009,0.219669914 C8.5107233,0.512563133 8.5107233,0.987436867 8.21783009,1.28033009 L5.74816017,3.75 L8.21783009,6.21966991 C8.5107233,6.51256313 8.5107233,6.98743687 8.21783009,7.28033009 C7.92493687,7.5732233 7.45006313,7.5732233 7.15716991,7.28033009 L4.6875,4.81066017 L2.21783009,7.28033009 C1.92493687,7.5732233 1.45006313,7.5732233 1.15716991,7.28033009 C0.864276695,6.98743687 0.864276695,6.51256313 1.15716991,6.21966991 L3.62683983,3.75 L1.15716991,1.28033009 C0.864276695,0.987436867 0.864276695,0.512563133 1.15716991,0.219669914 C1.45006313,-0.0732233047 1.92493687,-0.0732233047 2.21783009,0.219669914 L4.6875,2.68933983 Z" id="Delete"></path>
					</g>
				</g>
			</g>
		</svg>
	</div>
</template>

<script>
	import URLEditing from '../../helpers/URLEditing';

	export default {
		props: [
			'value',
			'query',
			'href',
			'placeholder',

			'input_only'
		],

		data(){
			return {
				search: '',
			}
		},

		mounted(){
			if (this.input_only){
				this.search = this.value;
			}
			else {
				this.search = this.query['search'] ? this.query['search'] : '';
			}
		},

		methods: {
			submitSearch(){
				if (this.input_only){
					this.$emit('input', this.search);
				}
				else {
					let query_to_run = this.query;

					query_to_run['search'] = this.search;

					window.location.href = URLEditing.getURLWithParams(this.href, query_to_run);
				}
			},

			clearSearch(){
				if (this.input_only){
					this.search = '';
					this.$emit('input', this.search);
					return;
				}

				let query_to_run = this.query;

				if (query_to_run['search']){
					delete query_to_run['search'];
					window.location.href = URLEditing.getURLWithParams(this.href, query_to_run);
				}
				else {
					this.search = '';
				}
			}
		}
	}
</script>