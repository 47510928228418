Vue.component(
	'search-input',
	require('./components/general_components/SearchInputComponent').default
);

Vue.component(
	'color-picker-input',
	require('./components/general_components/ColorPickerInputComponent').default
);

//Users

Vue.component(
	'users-table',
	require('./components/users/UsersTableSelector').default
);

//Settings
Vue.component(
	'job-titles-table',
	require('./components/settings/JobTitlesTable').default
);

Vue.component(
	'message-settings',
	require('./components/settings/MessageSettingsComponent').default
);

Vue.component(
	'rating-system-crud',
	require('./components/settings/RatingSystemCrud').default
);

Vue.component(
	'user-selection',
	require('./components/general_components/UserSelection').default
);

Vue.component(
	'company-campaign-permissions',
	require('./components/settings/CompanyCampaignPermissions').default
);

Vue.component(
	'delete-department-modal',
	require('./components/modals/DeleteDepartmentModal').default
);

Vue.component(
	'rating-card',
	require('./components/settings/RatingCard').default
);

//Questionnaires
Vue.component(
	'questionnaire-table-crud',
	require('./components/questionnaires/QuestionnairesLibraryIndexPage').default
);
Vue.component(
	'questionnaire-create-edit-page',
	require('./components/questionnaires/QuestionnaireCreateOrEditPage').default
);

//Questionnaire - Answers
Vue.component(
	'questionnaire-answers-page',
	require('./components/answers/QuestionnaireAnswersPage').default
);

Vue.component(
	'completed-missed-answers-index',
	require('./components/answers/CompletedMissedAnswersIndex').default
);

//Campaigns
Vue.component(
	'campaigns-index-table',
	require('./components/campaigns/CampaignIndexTable').default
);


Vue.component(
	'campaigns-edit-page',
	require('./components/campaigns/CampaignEditPage').default
);

Vue.component(
	'campaign-show-participants-table',
	require('./components/campaigns/CampaignShowParticipantsTable').default
);

Vue.component(
	'campaign-show-only-participant-table',
	require('./components/campaigns/CampaignShowOnlyParticipantTable').default
);

Vue.component(
	'reporting-page',
	require('./components/reporting/ReportingPage').default
);


Vue.component(
    'pdf-spider-diagram',
    require('./components/reporting/PdfSpiderDiagram').default
);

Vue.component(
    'pdf-spider-diagram-2',
    require('./components/reporting/PdfSpiderDiagram2').default
);

Vue.component(
	'campaign-participants-invitation-list',
	require('./components/campaigns/CampaignParticipantsInvitationList').default
);

Vue.component(
	'campaign-add-respondent-to-participant',
	require('./components/modals/AddRespondentToParticipantModal').default
);

Vue.component(
	'campaign-add-just-participant',
	require('./components/modals/AddJustParticipantModal').default
);

Vue.component(
	'campaign-change-end-date',
	require('./components/modals/CampaignChangeEndDate').default
)

Vue.component(
	'campaign-edit-information',
	require('./components/campaigns/EditInformation').default
);

Vue.component(
	'campaign-edit-messages',
	require('./components/campaigns/EditMessages').default
);

Vue.component(
	'help-index-page',
	require('./components/help/HelpIndexPage').default
);

Vue.component(
    'help-edit-page',
	require('./components/help/HelpEditPage').default
);

/* Modals */
Vue.component(
	'language-selection-modal',
	require('./components/modals/LanguageModal').default
);

Vue.component(
    'first-login-modal',
    require('./components/modals/FirstLoginModal').default
);

// Confirm modal is in the confirm.js


Vue.component(
	'vue-circle',
	require('vue2-circle-progress')
);


/* Redactor */
Vue.component(
	'Redactor',
	require('./components/general_components/Redactor').default
);
