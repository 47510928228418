<template>
	<div class="container mt-4">
		<div class="row">
			<div class="col-2 d-flex">
				<a :href="back_url" class="d-block my-auto">{{__('buttons.back')}}</a>
			</div>
			<div class="col-8 text-center">
				<h1 class="">{{campaign.name}}</h1>
			</div>
			<div class="col-2 text-right">
				<button v-on:click="save" v-bind:disabled="saving" class="btn btn-primary">{{__('buttons.save_and_close')}}</button>
			</div>
		</div>
		<div class="row mt-3">
			<div class="col-6">
				<h1 class="">{{__('translation.campaign_information_title')}}</h1>
			</div>
			<div class="col-12">
				<div class="form-group row">
					<div class="col-lg-6">
						<label for="campaign-title-input">{{__('place_holders.campaign_title')}}</label>
						<input id="campaign-title-input" v-model="campaign_edit.name" v-on:change="save" type="text" class="form-control" :placeholder="__('place_holders.campaign_title')">
					</div>
					<div class="col-lg-6">
						<label for="campaign-pdf-title-input">{{__('place_holders.campaign_pdf_title')}}</label>
						<input id="campaign-pdf-title-input" v-model="campaign_edit.pdf_title" v-on:change="save" type="text" class="form-control" :placeholder="__('place_holders.campaign_pdf_title_description')">
					</div>
				</div>
				<div class="form-group row">
					<div class="col-lg-6">
						<label for="campaign-notes-input">{{__('place_holders.campaign_instruction_description')}}</label>
						<Redactor
							id="campaign-introduction-input"
							v-model="campaign_edit.instruction"
							rows="4"
							:placeholder="__('place_holders.campaign_instruction')"
							:config="{
									breakline: false,
									focus: true,
    								stylesClass: 'redactor-box-grey'
								}"
						></Redactor>
					</div>
					<div class="col-lg-6">
						<div class="w-100">
							<label for="campaign-pdf-subtitle-input">{{__('place_holders.campaign_pdf_subtitle')}}</label>
							<input id="campaign-pdf-subtitle-input" v-model="campaign_edit.pdf_subtitle" v-on:change="save" type="text" class="form-control" :placeholder="__('place_holders.campaign_pdf_subtitle_description')">
						</div>
						<div class="w-100 mt-4">
							<label for="campaign-notes-input">{{__('place_holders.campaign_notes')}}</label>
							<textarea
								id="campaign-notes-input"
								v-model="campaign_edit.notes"
								v-on:change="save"
								rows="10"
								class="form-control"
								style="resize: none;"
								:placeholder="__('place_holders.campaign_notes_description')"
							></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
_description
<script>
	export default {
		name: "EditInformation",
		props: [
			'campaign',
			'back_url',
			'update_url'
		],

		data(){
			return {
				campaign_edit: null,
				saving: false,
			}
		},

		beforeMount(){
			this.campaign_edit = this.campaign;
		},

		methods: {
			'__': function (val) {
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			save(){
				let _this = this;

				if (_this.saving)
					return;

				_this.saving = true;
				axios.put(_this.update_url, {
					name: _this.campaign_edit.name,
					notes: _this.campaign_edit.notes,
					instruction: _this.campaign_edit.instruction,
					pdf_title: _this.campaign_edit.pdf_title,
					pdf_subtitle: _this.campaign_edit.pdf_subtitle,
				})
					.then(function (response){
						window.location.href = _this.back_url;
						return ;
					})
					.catch(function (error) {
						for (var i in error.response.data.errors) {
							if (error.response.data.errors.hasOwnProperty(i)) {
								toastr.error(error.response.data.errors[i][0]);
							}
						}
						_this.saving = false;
					});
			},
		}
	}
</script>
