
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');


//Using the defined by blade script
if (window.DataController){
	window.Lang = require('./helpers/Language');
	window.PermissionsController = require('./helpers/PermissionsController');

	//Clearing the blade script because we don't need it anymore
	// delete(window.DataController);
}

window.SaveProtection = require('./helpers/SaveProtection');

//Icons list
window.Smileys = [
    'angry',
    'terrible',
    'sad',
    'bad',
    'shocked',
    'disappointed',
    'worried',
    'surprised',
    'neutral',
    'happy',
    'very-happy',
    'excited',
];

window.Vue = require('vue');


//Modals controller
require('./modal');


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./vue_components');

//Confirm override
require('./confirm');

const app = new Vue({
	el: '#app'
});
