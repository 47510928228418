<template>
	<table class="custom-table">
		<thead>
		<tr>
			<th>{{__('translation.our_titles')}}</th>
			<td></td>
			<th class="custom-table--header-long">{{__('translation.your_titles')}}</th>
		</tr>
		</thead>
		<tbody>
		<tr class="py-1" v-for="(terminology_term, index) in terminology_terms">
			<td class="font-weight-bold">
				{{terminology_term.name}}
			</td>
			<td class="px-3 d-inline-flex">
				<input v-on:keydown.enter.prevent="addTerm(index, input_terms[index])" v-model="input_terms[index]" type="text" class="form-control" :placeholder="__('translation.add_phrase')"/>
				<a
					href="javascript:void(0)"
					v-on:click="addTerm(index, input_terms[index])"
					class="btn btn-jobs ml-2"
					v-bind:class="input_terms[index] ? 'active' : ''"
				>
					<img src="/svg/add-plus.svg" alt="+" />
				</a>
			</td>
			<td>
				<div v-for="(company_term, company_term_index) in company_terms[index]" class="mr-2 tag">
					<span class="text-white">{{company_term.terminology}}</span>
					<img v-on:click="removeTerm(index, company_term_index)" src="/svg/Delete.svg" class="tag--close"/>
				</div>
			</td>
			<input type="hidden" :name="'terms[' + terminology_term.id + ']'" v-bind:value="input_data(index)">
		</tr>
		</tbody>
	</table>
</template>

<script>
	export default {
		props: [
			'terminology_terms'
		],

		data(){
			return {
				company_terms: [],
				input_terms: []
			}
		},

		beforeMount() {
			let _this = this;

			_this.terminology_terms.forEach(function (term){
				_this.company_terms.push(term.terminologies);
			});
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			addTerm(terminology_term_index, term){
				if(!this.input_terms[terminology_term_index] || this.input_terms[terminology_term_index].trim() === ''){
					return;
				}

				let found = false;

				this.company_terms.forEach(function (company_term){
					company_term.forEach(function (terms){
						if (terms.terminology === term)
							found = true;
					});
				});

				if (found){
					toastr.error(this.__('messages.term_already_in_use'));
					return;
				}

				this.company_terms[terminology_term_index].push({
					id: -1,
					terminology: term
				});
				this.input_terms[terminology_term_index] = '';


				window.SaveProtection.lockWindow();
			},

			removeTerm(terminology_term_index, company_term_index){
				this.company_terms[terminology_term_index].splice(company_term_index, 1);


				window.SaveProtection.lockWindow();
			},

			input_data(index){
				let result = [];
				this.company_terms[index].forEach(function (term){
					result.push(term.terminology);
				});

				return result.join(',');
			}
		}
	}
</script>