<template>
	<div class="campaign-page">
		<!--Nav Bar-->
		<div class="container-fluid bg-light py-3 px-4">
			<div class="row">
				<div class="col-sm-4">
					<div class="navbar--logo ml-2 text-center text-sm-left">
						<img src="/svg/logo-dark.svg">
					</div>
				</div>
				<div class="col-sm-4">
					<h4 class="text-center font-weight-bold">{{campaign_name}}</h4>
					<h5 v-if="campaign_edit.comparison_report" class="text-center">Comparison Report</h5>
				</div>
				<div class="col-sm-4 text-center text-sm-right">
					<button v-on:click="closeThis()" class="btn btn-outline-primary">{{__('buttons.save_and_close')}}</button>
				</div>
			</div>
			<div class="row mt-5">
				<div class="container">
					<div class="row">
						<div class="campaign-page--nav">
							<div v-on:click="selectPage(0)" class="campaign-page--nav-step" v-bind:class="selected_page === 0 ? 'active' : ''">
								<div class="campaign-page--nav-step-tick-wrapper">
									<div class="campaign-page--nav-step-tick" v-if="confirmed_sections[0]">
										<img v-show="selected_page !== 0" src="/svg/campaign-tick-light.svg" alt="Tick">
										<img v-show="selected_page === 0" src="/svg/campaign-tick-dark.svg" alt="Tick">
									</div>
								</div>
								<div class="campaign-page--nav-step-logo">
									<img v-show="selected_page !== 0" src="/svg/campaign.svg" alt="Campaign">
									<img v-show="selected_page === 0" src="/svg/campaign-light.svg" alt="Campaign">
								</div>
								<h5 class="campaign-page--nav-step-title">{{__('translation.campaign_title')}}</h5>
							</div>
							<div v-on:click="selectPage(1)" class="campaign-page--nav-step" v-bind:class="selected_page === 1 ? 'active' : ''">
								<div class="campaign-page--nav-step-tick-wrapper">
									<div class="campaign-page--nav-step-tick" v-if="confirmed_sections[1]">
										<img v-show="selected_page !== 1" src="/svg/campaign-tick-light.svg" alt="Tick">
										<img v-show="selected_page === 1" src="/svg/campaign-tick-dark.svg" alt="Tick">
									</div>
								</div>
								<div class="campaign-page--nav-step-logo">
									<img v-show="selected_page !== 1" src="/svg/questionnaire.svg" alt="Questionnaire">
									<img v-show="selected_page === 1" src="/svg/questionnaire-light.svg" alt="Questionnaire">
								</div>
								<h5 class="campaign-page--nav-step-title">{{__('translation.questionnaire_title')}}</h5>
							</div>
							<div v-on:click="selectPage(2)" class="campaign-page--nav-step" v-bind:class="selected_page === 2 ? 'active' : ''">
								<div class="campaign-page--nav-step-tick-wrapper">
									<div class="campaign-page--nav-step-tick" v-if="confirmed_sections[2]">
										<img v-show="selected_page !== 2" src="/svg/campaign-tick-light.svg" alt="Tick">
										<img v-show="selected_page === 2" src="/svg/campaign-tick-dark.svg" alt="Tick">
									</div>
								</div>
								<div class="campaign-page--nav-step-logo">
									<img v-show="selected_page !== 2" src="/svg/stars.svg" alt="Rating">
									<img v-show="selected_page === 2" src="/svg/stars-light.svg" alt="Rating">
								</div>
								<h5 class="campaign-page--nav-step-title">{{__('translation.rating_scale_title')}}</h5>
							</div>
							<div v-on:click="selectPage(3)" class="campaign-page--nav-step" v-bind:class="selected_page === 3 ? 'active' : ''">
								<div class="campaign-page--nav-step-tick-wrapper">
									<div class="campaign-page--nav-step-tick" v-if="confirmed_sections[3]">
										<img v-show="selected_page !== 3" src="/svg/campaign-tick-light.svg" alt="Tick">
										<img v-show="selected_page === 3" src="/svg/campaign-tick-dark.svg" alt="Tick">
									</div>
								</div>
								<div class="campaign-page--nav-step-logo">
									<img v-show="selected_page !== 3" src="/svg/staff.svg" alt="People">
									<img v-show="selected_page === 3" src="/svg/staff-light.svg" alt="People">
								</div>
								<h5 class="campaign-page--nav-step-title">{{__('translation.people_title')}}</h5>
							</div>
							<div v-on:click="selectPage(4)" class="campaign-page--nav-step" v-bind:class="selected_page === 4 ? 'active' : ''">
								<div class="campaign-page--nav-step-tick-wrapper">
									<div class="campaign-page--nav-step-tick" v-if="confirmed_sections[4]">
										<img v-show="selected_page !== 4" src="/svg/campaign-tick-light.svg" alt="Tick">
										<img v-show="selected_page === 4" src="/svg/campaign-tick-dark.svg" alt="Tick">
									</div>
								</div>
								<div class="campaign-page--nav-step-logo">
									<img v-show="selected_page !== 4" src="/svg/message.svg" alt="Message">
									<img v-show="selected_page === 4" src="/svg/messages-light.svg" alt="Message">
								</div>
								<h5 class="campaign-page--nav-step-title">{{__('translation.messaging_title')}}</h5>
							</div>
							<div v-on:click="selectPage(5)" class="campaign-page--nav-step" v-bind:class="selected_page === 5 ? 'active' : ''">
								<div class="campaign-page--nav-step-tick-wrapper">
									<div class="campaign-page--nav-step-tick" v-if="confirmed_sections[5]">
										<img v-show="selected_page !== 5" src="/svg/campaign-tick-light.svg" alt="Tick">
										<img v-show="selected_page === 5" src="/svg/campaign-tick-dark.svg" alt="Tick">
									</div>
								</div>
								<div class="campaign-page--nav-step-logo">
									<img v-show="selected_page !== 5" src="/svg/reporting.svg" alt="Reporting">
									<img v-show="selected_page === 5" src="/svg/reporting-light.svg" alt="Reporting">
								</div>
								<h5 class="campaign-page--nav-step-title">{{__('translation.reporting')}}</h5>
							</div>
							<div v-on:click="selectPage(6)" class="campaign-page--nav-step" v-bind:class="selected_page === 6 ? 'active' : ''">
								<div class="campaign-page--nav-step-tick-wrapper">
									<div class="campaign-page--nav-step-tick" v-if="confirmed_sections[6]">
										<img v-show="selected_page !== 6" src="/svg/campaign-tick-light.svg" alt="Tick">
										<img v-show="selected_page === 6" src="/svg/campaign-tick-dark.svg" alt="Tick">
									</div>
								</div>
								<div class="campaign-page--nav-step-logo">
									<img v-show="selected_page !== 6" src="/svg/launch.svg" alt="Launch">
									<img v-show="selected_page === 6" src="/svg/launch-light.svg" alt="Launch">
								</div>
								<h5 class="campaign-page--nav-step-title">{{__('translation.launch_title')}}</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--Page 0 - Campaign-->
		<div v-show="selected_page === 0" class="container p-0">
			<div class="row mt-4">
				<div class="col-md-6">
					<h1>{{__('translation.campaign_information_title')}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button class="btn btn-primary" v-on:click="selected_page ++" v-bind:disabled="!confirmed_sections[0]">{{__('buttons.continue')}}</button>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-12">
					<div class="form-group row">
						<div class="col-lg-6">
							<label for="campaign-title-input">{{__('place_holders.campaign_title')}}</label>
							<input id="campaign-title-input" v-model="campaign_edit.name" v-on:change="save" type="text" class="form-control" :placeholder="__('place_holders.campaign_title')">
						</div>
						<div class="col-lg-6">
							<label for="campaign-pdf-title-input">{{__('place_holders.campaign_pdf_title')}}</label>
							<input id="campaign-pdf-title-input" v-model="campaign_edit.pdf_title" v-on:change="save" type="text" class="form-control" :placeholder="__('place_holders.campaign_pdf_title_description')">
						</div>
					</div>
					<div class="form-group row">
						<div class="col-lg-6">
							<label for="campaign-notes-input">{{__('place_holders.campaign_instruction')}}</label>
							<Redactor
								id="campaign-introduction-input"
								v-model="campaign_edit.instruction"
								rows="4"
								:placeholder="__('place_holders.campaign_instruction_description')"
								:config="{
									breakline: false,
									focus: true,
    								stylesClass: 'redactor-box-grey'
								}"
							></Redactor>
						</div>
						<div class="col-lg-6">
							<div class="w-100">
								<label for="campaign-pdf-subtitle-input">{{__('place_holders.campaign_pdf_subtitle')}}</label>
								<input id="campaign-pdf-subtitle-input" v-model="campaign_edit.pdf_subtitle" v-on:change="save" type="text" class="form-control" :placeholder="__('place_holders.campaign_pdf_subtitle_description')">
							</div>
							<div class="w-100 mt-4">
								<label for="campaign-notes-input">{{__('place_holders.campaign_notes')}}</label>
								<textarea
									id="campaign-notes-input"
									v-model="campaign_edit.notes"
									v-on:change="save"
									rows="10"
									class="form-control"
									style="resize: none;"
									:placeholder="__('place_holders.campaign_notes_description')"
								></textarea>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--Page 1 - Questionnaire-->
		<div v-show="selected_page === 1" class="container p-0">
			<div class="row mt-4">
				<div class="col-md-6">
					<h1>{{__('translation.choose_questionnaire')}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button class="btn btn-primary" v-on:click="selected_page ++" v-bind:disabled="!confirmed_sections[1]">{{__('buttons.continue')}}</button>
				</div>
			</div>
			<div class="row">
				<campaign-questionnaire-selection
					v-if="!questionnaire_edit"
					:load_questionnaires_url="load_questionnaires_url"
					:search_placeholder="questionnaire_search_place_holder"
					:questionnaire_create_url="questionnaire_create_url"
					:select_questionnaire_url="select_questionnaire_url"
					:download_questionnaire_template_url="download_questionnaire_template_url"

					:questionnaire_csv_submit_url="questionnaire_csv_submit_url"
					:questionnaire_csv_validate_url="questionnaire_csv_validate_url"

					:campaign_id="campaign.id"

					@selected="selectQuestionnaire"
				></campaign-questionnaire-selection>

				<questionnaire-create-or-edit-page
					v-else
					class="p-0"

					:type="campaign_edit.comparison_report ? 'comparison_report' : 'campaign'"
					@delete_questionnaire="replaceQuestionnaire"
					@update="checkCompleted"

					:terms="terms"
					:company_terms="company_terms"
					:save_folders="questionnaire_save_folders"
					:questionnaire="questionnaire_edit"
					:update_url="questionnaire_update_url.replace('?', questionnaire_edit.id)"
					:duplicate_url="questionnaire_duplicate_url"
					:delete_url="questionnaire_delete_url"
					:questionnaire_csv_submit_url="questionnaire_csv_submit_url"
					:questionnaire_csv_validate_url="questionnaire_csv_validate_url"
					:download_csv_url="questionnaire_csv_download_url.replace('?', questionnaire_edit.id)"
					:filter_sections="campaign.campaign_type_id === 1 && campaign.campaign_type_id === 2"
					:temp_check="campaign.campaign_type_id === 4"

				></questionnaire-create-or-edit-page>
			</div>
		</div>

		<!--Page 2 - Rating Scale-->
		<div v-show="selected_page === 2" class="container p-0">
			<div class="row mt-4">
				<div class="col-md-6">
					<h1 v-if="ratings.length">{{__('translation.choose_rating_scale')}}</h1>
					<div v-else-if="created_rating_edit && !campaign_rating_editing">
						<h1>{{__('translation.rating_scale_title')}}</h1>
						<h6 class="text-primary">{{__('translation.rating_scale_saved')}}</h6>
					</div>
				</div>
				<div class="col-md-6 text-right">
					<button v-if="!campaign_rating_editing || ratings.length" class="btn btn-primary" v-on:click="selected_page ++" v-bind:disabled="!confirmed_sections[2]">{{__('buttons.continue')}}</button>
				</div>
			</div>
			<div class="row">
				<div class="rating-cards--wrapper mt-4" v-if="ratings.length">
					<div class="rating-cards--saved-card" v-on:click="selectRating(rating.id)" v-for="rating in ratings" v-bind:class="campaign_edit.company_rating_scale_id === rating.id ? 'active':''">
						<div class="rating-cards--saved-card-type">
							<img v-bind:src="rating.icon_url">
						</div>
						<div class="rating-cards--saved-card-footer">
							<div>
								<span class="d-block">{{__('translation.rating_types.' + rating.code)}}</span>
								<span class="d-block text-grey">{{rating.option_na ? 'N/A, ' : ''}}{{rating.range}}</span>
							</div>
							<img width="19" height="19" class="my-auto" src="/svg/chevron-active.svg">
						</div>
					</div>
				</div>
				<rating-system-crud
					v-else-if="campaign_rating_editing"

					@saved="savedRating"
					:only_one_edit="true"
					:rating_scale="created_rating_edit"
					:save_url="rating_create_update_url"
					:campaign_crud="true"
				></rating-system-crud>

				<div class="w-100 mt-4" style="min-height: 360px" v-else>
					<div class="rating-cards--wrapper mt-5">
						<div class="rating-cards--saved-card dont-hover">
							<div class="rating-cards--saved-card-type">
								<img v-bind:src="created_rating_edit.icon_url">
							</div>
							<div class="rating-cards--saved-card-footer">
								<div>
									<span class="d-block text-primary">{{__('translation.rating_types.' + created_rating_edit.code)}}</span>
									<span class="d-block text-primary">{{created_rating_edit.option_na ? 'N/A, ' : ''}}{{created_rating_edit.range}}</span>
								</div>
								<img width="19" height="19" class="my-auto" src="/svg/orange-tick.svg">
							</div>
						</div>
					</div>

					<div v-if="!campaign_edit.comparison_report" class="mt-4 text-center">
						<button class="btn btn-outline" v-on:click="campaign_rating_editing = true">{{__('buttons.change_rating_system')}}</button>
					</div>
				</div>

			</div>
		</div>

		<!--Page 3 - People-->
		<div v-show="selected_page === 3" class="container p-0">
			<div class="row mt-4">
				<div class="col-md-6">
					<h1>{{__('translation.add_participants')}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button class="btn btn-primary" v-on:click="selected_page ++" v-bind:disabled="!confirmed_sections[3]">{{__('buttons.continue')}}</button>
				</div>
			</div>

			<campaign-participants-table
				:participants_csv_fields=participants_csv_fields
				:participants_csv_upload_url=participants_csv_upload_url
				:participants_submit_url=participants_submit_url
				:participants_create_url="participants_create_url"
				:participants_delete_url="participants_delete_url"
				:participants_delete_participant_url="participants_delete_participant_url"
				:participants_csv_download_template_url="participants_csv_download_template_url"
                :participants_csv_download_guid_url="participants_csv_download_guid_url"
				:single_participant_create_url="single_participant_create_url"

				:load_participants_url="load_participants_url"

				:terms="terms"
				:company_terms="company_terms"

				:campaign="campaign_edit"

				@submitted="savedParticipants"
			></campaign-participants-table>
		</div>

		<!--Page 4 - Messaging-->
		<div v-show="selected_page === 4" class="container p-0">
			<div class="row mt-4">
				<div class="col-md-6">
					<h1>{{__('translation.message_to_participants')}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button class="btn btn-primary" v-on:click="selected_page ++" v-bind:disabled="!confirmed_sections[4]">{{__('buttons.continue')}}</button>
				</div>
			</div>

			<campaign-messages
				:campaign="campaign_edit"
				:disabled="!company_campaign_permissions.campaign_messages || !mine"
				:default_subject="campaign_name"
				:messages_update_url="messages_update_url"
				:change_messages_language_url="change_messages_language_url"
				:languages="languages"
			></campaign-messages>
		</div>

		<!--Page 5 - Reporting-->
		<div v-show="selected_page === 5" class="container p-0">
			<div class="row mt-4">
				<div class="col-md-6">
					<h1>{{__('translation.reporting')}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button class="btn btn-primary" v-on:click="selected_page ++" v-bind:disabled="!confirmed_sections[5]">{{__('buttons.continue')}}</button>
				</div>
			</div>
			<campaign-edit-reporting
				v-model="campaign_edit"
				:reporting_update_url="reporting_update_url"
			></campaign-edit-reporting>
		</div>

		<!--Page 6 - Launch-->
		<div v-show="selected_page === 6" class="container p-0">
			<div class="row mt-4">
				<div class="col-md-6">
					<h1>{{__('translation.launch_title')}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button class="btn btn-primary" v-on:click="launchCampaign" v-show="campaign_edit.launch_manually" v-bind:disabled="!confirmed_sections[6] || saving">{{__('translation.launch_title')}}</button>
					<button class="btn btn-primary" v-on:click="closeThis" v-show="!campaign_edit.launch_manually" v-bind:disabled="!confirmed_sections[6] || saving">{{__('buttons.ready')}}</button>
				</div>
			</div>

			<div class="row mt-4">
				<div class="col-lg-8 col-xl-8 d-md-inline-flex">
					<div class="d-block">
						<calendar
							:selected="campaign_edit.launch_date"
							:placeholder="__('translation.launch_campaign_title')"
							:default_now="true"
							:disabled="campaign_edit.launch_manually"
							@change="selectLaunchDate"
						></calendar>
					</div>
					<img class="mx-md-5 mx-lg-2 mx-xl-5 d-none d-md-block" src="/svg/calendar-arrow.svg" alt="Arrow" style="max-height: 250px">
					<div class="d-block mt-5 mt-md-0">
						<calendar
							:selected="campaign_edit.finish_date"
							:placeholder="__('translation.finish_campaign_title')"
							@change="selectFinishDate"
						></calendar>
					</div>
				</div>
				<div class="col-lg-4 col-xl-3 offset-xl-1 right-side-list">
					<div class="right-side-list--item group">
						<span class="d-block my-auto mx-2">{{__('translation.remind_all_people')}}</span>
						<input v-model="campaign_edit.remind_every_day" @change="save" v-on:keypress="isNumber($event)" type="text" class="form-control digit">
						<span class="d-block my-auto mx-2">{{__('translation.remind_all_people_day')}}</span>
					</div>

					<div class="right-side-list--item group">
						<span class="cursor-pointer">{{__('translation.campaign_launch_manually')}}</span>
						<div class="checkbox w-auto my-auto pl-3">
							<label class="customer_checkbox">
								<input class="form-check-input" v-on:click="$nextTick(save)" v-model="campaign_edit.launch_manually" type="checkbox">
								<span class="checkmark"></span>
							</label>
						</div>
					</div>

					<div class="right-side-list--item group" v-if="campaign.campaign_type_id === 1 || campaign.campaign_type_id === 2">
						<span
							class="cursor-pointer"
							v-bind:class="!company_campaign_permissions.self_assessors_view_report ? 'text-grey' : ''"
						>{{__('translation.campaign_allow_see_report')}}</span>
						<div class="checkbox w-auto my-auto pl-3">
							<label class="customer_checkbox">
								<input class="form-check-input" type="checkbox" v-bind:disabled="!company_campaign_permissions.self_assessors_view_report" v-model="campaign_edit.allow_managers_see_feedback" v-on:click="toggleAllowView()">
								<span class="checkmark"></span>
							</label>
						</div>
					</div>

					<div class="right-side-list--item group" v-if="campaign.campaign_type_id === 1 || campaign.campaign_type_id === 2">
						<span
							class="cursor-pointer"
							v-bind:class="!campaign_edit.allow_managers_see_feedback || !company_campaign_permissions.allow_self_assessor_view_feedback_before_ended ? 'text-grey' : ''"
						>{{__('translation.campaign_allow_see_feedback')}}</span>
						<div class="checkbox w-auto my-auto pl-3">
							<label class="customer_checkbox">
								<input v-bind:disabled="!campaign_edit.allow_managers_see_feedback || !company_campaign_permissions.allow_self_assessor_view_feedback_before_ended"  @change="$nextTick(save)" class="form-check-input" type="checkbox" v-model="campaign_edit.allow_while_working_progress">
								<span class="checkmark"></span>
							</label>
						</div>
					</div>

					<!--<div class="right-side-list&#45;&#45;item group">-->
					<!--	<span class="cursor-pointer">{{__('translation.campaign_allow_department')}}</span>-->
					<!--	<div class="checkbox w-auto my-auto pl-3">-->
					<!--		<label class="customer_checkbox">-->
					<!--			<input class="form-check-input" type="checkbox" v-on:click="$nextTick(save)" v-model="campaign_edit.department_view_reporting_all_times">-->
					<!--			<span class="checkmark"></span>-->
					<!--		</label>-->
					<!--	</div>-->
					<!--</div>-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CampaignQuestionnaireSelection from "./CampaignQuestionnaireSelection";
	import QuestionnaireCreateOrEditPage from "../questionnaires/QuestionnaireCreateOrEditPage";
	import CampaignParticipantsTable from "./CampaignParticipantsTable";
	import CampaignMessages from "./CampaignMessages";
	import CampaignEditReporting from "./CampaignEditReporting";
	import Calendar from "../general_components/Calendar";
	export default {
		name: "CampaignEditPage",
		components: {
			Calendar,
			CampaignEditReporting,
			CampaignMessages,
			CampaignParticipantsTable,
			QuestionnaireCreateOrEditPage,
			CampaignQuestionnaireSelection
		},
		props: [
			'types',
			'campaign',
			'ratings',
			'created_rating',

			'min_relations_360',
			'mine',

			'languages',

			'participants_csv_fields',

			'company_terms',
			'company_campaign_permissions',
			'terms',

			'update_url',
			'back_url',
			'launch_url',
			'rating_create_update_url',

			'load_questionnaires_url',

			'load_participants_url',

			'select_questionnaire_url',
			'questionnaire_search_place_holder',
			'questionnaire_create_url',
			'questionnaire_update_url',
			'questionnaire_delete_url',
			'questionnaire_csv_validate_url',
			'questionnaire_csv_submit_url',
			'questionnaire_csv_download_url',
			'questionnaire_duplicate_url',
			'questionnaire_save_folders',
			'questionnaire',
			'download_questionnaire_template_url',
            'participants_csv_download_guid_url',

			'participants_csv_download_template_url',
			'participants_csv_upload_url',
			'participants_submit_url',
			'participants_create_url',
			'single_participant_create_url',
			'participants_edit_url',
			'participants_delete_url',
			'participants_delete_participant_url',
			'messages_update_url',
			'change_messages_language_url',
			'reporting_update_url',
		],

		data(){
			return {
				selected_page: 0,
				questionnaire_edit: null,

				saved_rating: false,
				campaign_rating_editing: true,
				created_rating_edit: null,

				saving: false,
				resave: false,

				confirmed_sections: [
					false,
					false,
					false,
					false,
					false,
					false,
					false
				],

				campaign_edit: {}
			}
		},

		beforeMount(){
			this.campaign_edit = this.campaign;
			this.questionnaire_edit = this.questionnaire;
			this.created_rating_edit = this.created_rating;

			if (!this.company_campaign_permissions.self_assessors_view_report){
				this.campaign_edit.allow_managers_see_feedback = false;
				this.campaign_edit.allow_while_working_progress = false;
			}
			if (!this.company_campaign_permissions.allow_self_assessor_view_feedback_before_ended){
				this.campaign_edit.allow_while_working_progress = false;
			}

			if (this.created_rating_edit){
				this.saved_rating = true;
				this.campaign_rating_editing = false;
			}

			this.checkCompleted();
			// window.SaveProtection.lockWindow();
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			isNumber: function(evt) {
				evt = (evt) ? evt : window.event;
				let charCode = (evt.which) ? evt.which : evt.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57)){
					evt.preventDefault();
				} else {
					return true;
				}
			},

			toggleAllowView(){
				this.campaign_edit.allow_managers_see_feedback = !this.campaign_edit.allow_managers_see_feedback;
				this.campaign_edit.allow_while_working_progress = false;
				this.save();
			},

			save(){
				let _this = this;
				_this.checkCompleted();

				if (_this.saving){
					_this.resave = true;
					return;
				}

				if (_this.campaign_edit.launch_manually){
					_this.campaign_edit.launch_date = null;
				}

				_this.saving = true;
				let data = {
					campaign: _this.campaign_edit
				};

				axios.put(_this.update_url, data)
					.then(function (response) {
						_this.saving = false;

						if (_this.resave){
							_this.resave = false;
							_this.save();
						}
						_this.checkCompleted();
					})
					.catch(function (error) {
						_this.saving = false;

						for (var i in error.response.data.errors) {
							if (error.response.data.errors.hasOwnProperty(i)) {
								toastr.error(error.response.data.errors[i][0]);
							}
						}
					});
			},


			//Launch
			launchCampaign(){
				if (!this.confirmed_sections[6] || this.saving){
					return ;
				}

				let _this = this;

				_this.saving = true;
				axios.post(_this.launch_url)
					.then(function (){
						window.location.href = _this.back_url;
					})
					.catch(function (errors){
                        _this.saving = false;
                        _this.resave = false;
					    if (errors.response.status === 422){
					       toastr.error( _this.__('messages.missing_dates'));
                        }
					    else {
					        toastr.error(_this.__('messages.something_went_wrong'));
                        }
					})
			},


			//Dates

			formatDate(date){
				return date.getFullYear()
					+ '-' + (date.getMonth() +1) + '-' + date.getDate() + ' ' +
					('0' + date.getHours()).slice(-2)  + ':' + ('0' + date.getMinutes()).slice(-2) + ':00';
			},

			selectLaunchDate(date){
				if (date){
					this.campaign_edit.launch_date = this.formatDate(date);
				}
				else {
					this.campaign_edit.launch_date = null;
				}
				this.save();
			},

			selectFinishDate(date){
				this.campaign_edit.finish_date = this.formatDate(date);
				this.save();
			},


			closeThis(){
				window.SaveProtection.unlockWindow();

				this.$nextTick(function (){
					window.location.href = this.back_url;
				});
			},

			checkCompleted(){
				if(!this.campaign_edit) return false;

				//Campaign
				this.$set(this.confirmed_sections, 0, this.campaign_edit.name && this.campaign_edit.name.trim() !== '');
				this.confirmed_sections[0] = this.campaign_edit.name && this.campaign_edit.name.trim() !== '';

				//Questionnaire
				this.$set(this.confirmed_sections, 1, true);

				if (!this.questionnaire_edit)
					this.$set(this.confirmed_sections, 1, false);
				else if (!this.questionnaire_edit.sections)
					this.$set(this.confirmed_sections, 1, false);
				else if (!this.questionnaire_edit.sections.length)
					this.$set(this.confirmed_sections, 1, false);
				else {
					for (let i=0; i<this.questionnaire_edit.sections.length; i++)
						if (!this.questionnaire_edit.sections[i].questions)
							this.$set(this.confirmed_sections, 1, false);
						else if (this.questionnaire_edit.sections[i].questions.length === 0)
							this.$set(this.confirmed_sections, 1, false);
				}

				//Rating Scale
				this.$set(this.confirmed_sections, 2, this.ratings.length ? this.campaign_edit.company_rating_scale_id != null : this.saved_rating);

				//People
				if (this.campaign_edit.grouped_participants.length)
					this.$set(this.confirmed_sections, 3, this.validateParticipantsForCampaign());
				else
					this.$set(this.confirmed_sections, 3, false);
				//Messaging
				this.$set(this.confirmed_sections, 4, true);

				//Reporting
				this.$set(this.confirmed_sections, 5, false);
				for(let i = 0; i < this.campaign_edit.reporting_sections.length; i++){
					if (this.campaign_edit.reporting_sections[i].value){
						this.$set(this.confirmed_sections, 5, true);
						break;
					}
				}

				//Launch
				this.$set(this.confirmed_sections, 6, this.confirmed_sections[0] &&
					this.confirmed_sections[1] &&
					this.confirmed_sections[2] &&
					this.confirmed_sections[3] &&
					this.confirmed_sections[4] &&
					this.confirmed_sections[5] &&
					this.campaign_edit.finish_date && (this.campaign_edit.launch_date || this.campaign_edit.launch_manually));
			},

			validateParticipantsForCampaign(){
				let _this = this, failed = false, num_relations = 0;
				switch (_this.campaign_edit.campaign_type_id) {
					//360
					case 1:
						_this.campaign_edit.grouped_participants.forEach(function (participant){
							participant.terminologies.forEach(function (terminology){
								if (terminology.respondents.length){
									num_relations ++;
								}
							});

							if (num_relations < _this.min_relations_360){
								failed = true;
							}
						});
						return !failed;
					//180
					case 2:
						_this.campaign_edit.grouped_participants.forEach(function (participant){
							num_relations = 0;

							participant.terminologies.forEach(function (terminology){
								if (terminology.respondents.length){
									num_relations ++;
								}
							});

							if (!num_relations){
								failed = true;
							}
						});
						return !failed;
					//Others
					case 3:
					case 4:
					case 5:
					case 6:
						//Only requires to have participants
						return true;
				}
				return false;
			},

			replaceQuestionnaire(){
				this.questionnaire_edit = null;
				this.campaign_edit.questionnaire_id = null;
				this.save();
			},


			selectPage(index){
				//some conditions? maybe save
				this.selected_page = index;
			},

			selectQuestionnaire(questionnaire){
				this.campaign_edit.questionnaire_id = questionnaire.id;
				this.questionnaire_edit = questionnaire;
				if (!this.questionnaire_edit.sections)
					this.questionnaire_edit.sections = [];
				this.save();
			},

			selectRating(id){
				this.campaign_edit.company_rating_scale_id = id;
				this.save();
			},

			savedRating(rating_system){
				this.saved_rating = true;
				this.campaign_rating_editing = false;
				this.created_rating_edit = rating_system;
				this.checkCompleted();
			},

			savedParticipants(participants){
				this.campaign_edit.grouped_participants = $.extend(true, [], participants);
				this.checkCompleted();
			},
		},

		watch: {
			'campaign_edit.min_respondents': {
				handler: function (){
					this.save()
				},

				deep: true
			},

			'campaign_edit.instruction': {
				handler: function (){
					this.save()
				},

				deep: true
			},

			campaign_edit: {
				handler: function (){
					this.checkCompleted();
				},

				deep: true
			},

			selected_page(){
				if (this.created_rating_edit){
					this.campaign_rating_editing = false;
				}
			}
		},

		computed: {
			campaign_name(){
				if (this.campaign_edit.is_tna){
					return this.types[2].name
				}
				for (let i = 0; i < this.types.length; i++){
					if (this.types[i].id === this.campaign_edit.campaign_type_id)
						return this.types[i].name;
				}
			}
		},
	}
</script>
