<template>
	<div class="container p-0 mt-4">
		<div v-if="!loading && !selected" class="row">

			<!--Main Body-->
			<div class="col-md-10 order-1 order-md-0">
				<div class="container p-0">
					<div class="row">
						<div class="col-12">
							<search-input
								:input_only="true"
								:placeholder="search_placeholder"
							></search-input>
						</div>
					</div>
					<div class="row">
						<!--Folder Select-->
						<div class="col-lg-4">
							<!--Library Select-->
							<ul class="list-group" id="folders">
								<!--Templates-->
								<li class="list-group-item">
									<a href="#collapse-template-library" v-on:click="selectLibrary('template')" data-toggle="collapse" aria-expanded="false" aria-controls="collapse-template-library">
										<span>{{ __('translation.template_library')}}</span>
										<img v-if="library_select === 'template'" src="/svg/dropdown.svg" />
										<img v-else src="/svg/chevron-inactive.svg" />
									</a>
								</li>
								<div id="collapse-template-library" data-parent="#folders" class="collapse" aria-labelledby="collapse-template-library">
									<ul class="list-group">
										<li class="list-group-item  pl-4" v-for="folder in template_library">
											<a href="javascript:void(0)" v-on:click="loadQuestionnaires(folder.questionnaire_libraries, folder.id, 'template')">
												<span v-bind:class="folder.id === template_library_folder_selected ? 'text-primary' : ''">{{folder.name}} <small>({{folder.questionnaire_libraries.length}})</small></span>
												<img v-if="folder.id === template_library_folder_selected"  src="/svg/chevron-active.svg" />
												<img v-else src="/svg/chevron-inactive.svg" />
											</a>
										</li>
									</ul>
								</div>

								<!--Company-->
								<li class="list-group-item">
									<a href="#collapse-company-library" v-on:click="selectLibrary('company')" data-toggle="collapse" aria-expanded="false" aria-controls="collapse-company-library">
										<span>{{ __('translation.company_library')}}</span>
										<img  v-if="library_select === 'company'" src="/svg/dropdown.svg" />
										<img v-else src="/svg/chevron-inactive.svg" />
									</a>
								</li>
								<div id="collapse-company-library" data-parent="#folders" class="collapse" aria-labelledby="collapse-company-library">
									<ul class="list-group">
										<li class="list-group-item  pl-4" v-for="folder in company_library">
											<a href="javascript:void(0)" v-on:click="loadQuestionnaires(folder.questionnaire_libraries, folder.id, 'company')">
												<span v-bind:class="folder.id === company_library_folder_selected ? 'text-primary' : ''">{{folder.name}} <small>({{folder.questionnaire_libraries.length}})</small></span>
												<img v-if="folder.id === company_library_folder_selected"  src="/svg/chevron-active.svg" />
												<img v-else src="/svg/chevron-inactive.svg" />
											</a>
										</li>
									</ul>
								</div>

								<!--Personal-->
								<li class="list-group-item">
									<a href="#collapse-personal-library" v-on:click="selectLibrary('personal')" data-toggle="collapse" aria-expanded="false" aria-controls="collapse-personal-library">
										<span>{{__('translation.my_library')}}</span>
										<img v-if="library_select === 'personal'" src="/svg/dropdown.svg" />
										<img v-else src="/svg/chevron-inactive.svg" />
									</a>
								</li>
								<div id="collapse-personal-library" data-parent="#folders" class="collapse" aria-labelledby="collapse-personal-library">
									<ul class="list-group">
										<li class="list-group-item  pl-4" v-for="folder in personal_library">
											<a href="javascript:void(0)" v-on:click="loadQuestionnaires(folder.questionnaire_libraries, folder.id, 'personal')">
												<span v-bind:class="folder.id === personal_library_folder_selected ? 'text-primary' : ''">{{folder.name}} <small>({{folder.questionnaire_libraries.length}})</small></span>
												<img v-if="folder.id === personal_library_folder_selected" src="/svg/chevron-active.svg" />
												<img v-else src="/svg/chevron-inactive.svg" />
											</a>
										</li>
									</ul>
								</div>
							</ul>
						</div>

						<!--Table-->
						<div class="col-lg-8">
							<h4 class="pb-2">{{folder_name}}</h4>
							<questionnaire-library-table
								:library="questionnaires"
								@select="selectQuestionnaire"
							></questionnaire-library-table>
						</div>
					</div>
				</div>
			</div>

			<!--Side Actions-->
			<div class="col-md-2 order-0 order-md-1 right-side-list">
				<a
					v-on:click="openCreateQuestionnaire()"
					href="javascript:void(0)"
					class="right-side-list--item"
				>{{__('buttons.create_questionnaire_button')}}</a>

				<a
					:href="download_questionnaire_template_url"
					target="_blank"
					class="right-side-list--item"
					v-if="download_questionnaire_template_url"
				>{{__('buttons.download_questionnaire_template')}}</a>

				<a
					v-on:click="openUploadQuestionnaire()"
					href="javascript:void(0)"
					class="right-side-list--item"
					v-if="folders.length"
				>{{__('buttons.upload_questionnaire')}}</a>
			</div>

			<create-questionnaire-modal
				id="create-questionnaire"
				:create_url="questionnaire_create_url"
				@created="createANewQuestionnaire"
			></create-questionnaire-modal>

		</div>
		<div v-else class="row">
			<div class="col-12">
				<h5>Loading...</h5>
			</div>
		</div>


		<csv-upload-modal
			id="csv-upload-questionnaire-modal"
			prefix="questionnaire"

			:extra_name_field="true"
			type="campaign"
			:campaign_id="campaign_id"
			:fields="['Question', 'Section']"
			:upload_url="questionnaire_csv_validate_url"
			:submit_url="questionnaire_csv_submit_url"
			@submitted="submittedCSV"
		></csv-upload-modal>
	</div>
</template>

<script>
	import QuestionnairesLibraryTableComponent from './../questionnaires/QuestionnairesLibraryTableComponent';
	import CreateQuestionnaireModal from './../modals/CreateQuestionnaireModal';
	import CSVUploadModal from './../modals/CSVUploadModal'

	export default {
		name: "CampaignQuestionnaireSelection",
		components:{
			'questionnaire-library-table': QuestionnairesLibraryTableComponent,
			'csv-upload-modal': CSVUploadModal,
			CreateQuestionnaireModal
		},
		props: [
			'load_questionnaires_url',
			'search_placeholder',
			'questionnaire_create_url',
			'select_questionnaire_url',
			'download_questionnaire_template_url',

			'type',

			'campaign_id',

			'questionnaire_csv_submit_url',
			'questionnaire_csv_validate_url'
		],

		data(){
			return {
				template_library: null,
				personal_library: null,
				company_library: null,


				questionnaires: [],
				loaded_folder: null,

				library_select: null,

				template_library_folder_selected: null,
				company_library_folder_selected: null,
				personal_library_folder_selected: null,

				loading: false,

				selected: false,

				folders: [],
			}
		},

		mounted(){
			let _this = this;

			_this.loadData();
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			loadData(){
				let _this = this;

				_this.loading = true;

				axios.get(_this.load_questionnaires_url)
					.then(function (response){
						_this.template_library = response.data.libraries.templates;
						_this.personal_library = response.data.libraries.personal;
						_this.company_library = response.data.libraries.company;

						_this.personal_library.forEach(function (folder){
							_this.folders.push(folder);
						});

						_this.loading = false;
					});
			},

			openCreateQuestionnaire(){
				openModal($('#create-questionnaire'));
			},

			createANewQuestionnaire(questionnaire){
				this.$emit('selected', questionnaire);
			},

			selectLibrary(library){
				if (this.library_select === library){
					this.library_select = null;
				}
				else {
					this.library_select = library;
				}
			},

			loadQuestionnaires(data, folder_id, type){
				this.questionnaires = data;

				this.template_library_folder_selected = null;
				this.company_library_folder_selected = null;
				this.personal_library_folder_selected = null;

				if (type === 'template')
					this.template_library_folder_selected = folder_id;
				else if (type === 'company')
					this.company_library_folder_selected = folder_id;
				else if (type === 'personal')
					this.personal_library_folder_selected = folder_id;
			},

			openUploadQuestionnaire(){
				openModal($('#csv-upload-questionnaire-modal'));
			},

			submittedCSV(questionnaire){
				this.selected = false;
				this.$emit('selected', questionnaire);
			},

			selectQuestionnaire(index){
				if (this.selected) return;

				let questionnaire_library = null, _this = this;

				if (_this.template_library_folder_selected){
					for (let i=0; i<_this.template_library.length; i++)
						if (_this.template_library_folder_selected === _this.template_library[i].id)
							questionnaire_library = _this.template_library[i].questionnaire_libraries[index];
				}
				else if (_this.company_library_folder_selected){
					for (let i=0; i<_this.company_library.length; i++)
						if (_this.company_library_folder_selected === _this.company_library[i].id)
							questionnaire_library = _this.company_library[i].questionnaire_libraries[index];
				}
				else if (_this.personal_library_folder_selected){
					for (let i=0; i<_this.personal_library.length; i++)
						if (_this.personal_library_folder_selected === _this.personal_library[i].id)
							questionnaire_library = _this.personal_library[i].questionnaire_libraries[index];
				}

				_this.selected = true;

				axios.post(_this.select_questionnaire_url, {
					questionnaire_id: questionnaire_library.questionnaire_id
				})
					.then(function (response){
						_this.selected = false;
						_this.$emit('selected', response.data.questionnaire);
					})
			}
		},

		computed: {
			folder_name(){
				if (this.template_library_folder_selected){
					for(let i=0; i<this.template_library.length; i++){
						if (this.template_library[i].id === this.template_library_folder_selected)
							return this.template_library[i].name;
					}
				}
				else if (this.company_library_folder_selected){
					for(let i=0; i<this.company_library.length; i++){
						if (this.company_library[i].id === this.company_library_folder_selected)
							return this.company_library[i].name;
					}
				}
				else if (this.personal_library_folder_selected){
					for(let i=0; i<this.personal_library.length; i++){
						if (this.personal_library[i].id === this.personal_library_folder_selected)
							return this.personal_library[i].name;
					}
				}
				else {
					return this.__('translation.select_folder');
				}
			}
		}
	}
</script>