<template>
	<div class="container">
		<div class="row campaign-page--table-header">
			<div class="col-sm-4 col-xl-5 my-auto">
				<h4 class="font-weight-bold mb-0">{{__('translation.all_campaigns')}}</h4>
			</div>
            <div class="col-sm-8 col-xl-7 d-flex justify-content-sm-end mt-4 mt-sm-0">
                <ul class="pagination">
                    <li class="page-item">
                        <button class="page-link" v-bind:disabled="loading || page_data.prev_page_url == null" v-on:click="loadPage(page_data.current_page - 1)">{{__('buttons.previous')}}</button>
                    </li>
                    <li class="page-item active">
                        <span class="page-link">{{page_data.current_page || 1}} / {{ page_data.last_page || 1 }}</span>
                    </li>
                    <li class="page-item">
                        <button class="page-link" v-bind:disabled="loading || page_data.next_page_url == null" v-on:click="loadPage(page_data.current_page + 1)">{{__('buttons.next')}}</button>
                    </li>
                </ul>
            </div>
			<div class="col-12 d-flex justify-content-end align-items-center">
				<search-input-component
					class="small mr-4 w-auto"
					v-model="search"
					:placeholder="__('place_holders.campaign_title')"
					:input_only="true"
				></search-input-component>

				<select class="form-control mr-4" v-model="filter_by" style="max-width: 175px">
					<option hidden :value="null">{{__('place_holders.filter_by')}}</option>
					<option :value="null">{{__('place_holders.all')}}</option>
					<option :value="type.id" v-for="type in campaign_types">{{type.name}}</option>
				</select>
				<div v-if="can_see_others" class="text-md-right">
					<div class="d-inline-flex h-100 my-auto">
						<span class="d-block my-auto font-weight-bold">{{__('place_holders.show_only_my_campaigns')}}</span>
						<label class="switch mr-2 my-auto ml-2">
							<input type="checkbox" v-model="show_only_my">
							<span class="slider round"></span>
						</label>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-4">
			<div v-if="loading" class="loaders--standard mt-4"></div>

			<div v-else-if="campaigns.length" class="col-12 questionnaire_edit_table">

				<div class="row mx-0 questionnaire_edit_table--header">
					<div class="col-3">{{__('translation.name_title')}}</div>
					<div class="col-3">{{__('translation.campaign_date')}}</div>
					<div class="col-2">{{__('translation.self_assessment')}}</div>
					<div class="col-2">{{__('translation.respondents')}}</div>
				</div>


				<div class="questionnaire_edit_table--body">
					<div
						class="row mx-0 questionnaire_edit_table--body-row"
						v-for="(campaign, index) in campaigns"
						v-on:mouseover="mouseOverTable = index"
						v-on:click="selectRow(index)"
					>
						<div class="col-3 questionnaire_edit_table--cell pt-0 d-flex">
							<img :src="logo(campaign.is_tna ? 3 : campaign.campaign_type_id)" alt="Logo" class="my-auto p-0">
							<span class="d-block ml-3 my-auto font-weight-bold">{{campaign.name}}</span>
						</div>
						<div class="col-3 questionnaire_edit_table--cell pt-0 d-flex">{{campaign.campaign_date}}</div>
						<div class="col-2 questionnaire_edit_table--cell pt-0 d-flex">{{campaign.num_participants}}</div>
						<div class="col-2 questionnaire_edit_table--cell pt-0 d-flex">{{campaign.num_respondents}}</div>
						<div class="col-2 questionnaire_edit_table--cell pt-0 d-flex">
							<a href="javascript:void(0)" class="text-primary pt-0" v-on:click="deleteCampaign($event, campaign.id)">{{__('buttons.delete')}}</a>
							<a href="javascript:void(0)" class="text-primary pt-0 ml-2" v-on:click="duplicateCampaign($event, campaign.id)">{{__('buttons.duplicate')}}</a>
						</div>
					</div>
				</div>
			</div>

			<div v-else class="col-12">
				No results

                <div class="campaign-page--cards-wrapper--empty mt-5">
                    <div class="campaign-page--cards-wrapper--empty-image text-center">
                        <img src="/svg/no_older_campaign.svg" alt="">
                        <h5 class="campaign-page--cards-wrapper--empty-image-description">{{__('translation.campaigns_old_empty')}}</h5>
                    </div>
                </div>
			</div>
		</div>
	</div>
</template>

<script>
	import SearchInputComponent from "../general_components/SearchInputComponent";
	export default {
		name: "CampaignIndexTable",
		components: {SearchInputComponent},
		props: [
			'campaign_types',
			'can_see_others',

			'search_url',
            'duplicate_url',
			'show_url',
		],

		data(){
			return {
				loading: false,
				reload: false,
				campaigns: [],

				dont_click: false,

				mouseOverTable: null,
				search: '',
				show_only_my: false,
				filter_by: null,

                page_data: {}
			}
		},

		beforeMount(){
			this.loadData();
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			logo(type){
				switch(type) {
					case 1:
						return '/svg/360-icon.svg';
					case 2:
						return '/svg/180-icon.svg';
					case 3:
						return '/svg/tna-icon.svg';
					case 4:
						return '/svg/thermostat-icon.svg';
					case 5:
						return '/svg/staff-icon.svg';
					case 6:
						return '/svg/survey-icon.svg';
				}

				return '';
			},

            loadPage(page){
                this.loadData(page);
            },

			loadData(page = 1){
				let _this = this;

				if (_this.loading) {
					_this.reload = true;
					return;
				}

				_this.loading = true;
				axios.post(_this.search_url, {
					search: _this.search,
					show_only_my: _this.show_only_my,
                    page: page,
					filter_by: _this.filter_by
				})
					.then(function (response){
						_this.loading = false;
						if (_this.reload){
							_this.reload = false;
							_this.loadData();
							return;
						}

                        _this.page_data = response.data;
						_this.campaigns = response.data.data;
					})
					.catch(function (){
						_this.reload = false;
						_this.loading = false;
						window.location.reload(); //Something went wrong
					});
			},

			selectRow(index){
				if (this.dont_click){
					this.dont_click = false;
					return ;
				}
				window.location.href = this.show_url.replace('?', this.campaigns[index].id);
			},

			deleteCampaign(e, id){
				e.preventDefault();
				this.dont_click = true;
                requestDeleteCampaign(id);
			},

            duplicateCampaign(e, id){
                e.preventDefault();
                this.dont_click = true;
                requestDuplicateCampaign(id);
            }
		},

		watch: {
			search(){
				this.loadData();
			},

			show_only_my(){
				this.loadData();
			},

			filter_by(){
				this.loadData();
			}
		}
	}
</script>
