export default (function () {
	return {
		appendToUrl(data){
			let url = window.location.href;
			let uri = url.lastIndexOf('?') > 0 ? url.substr(0, url.lastIndexOf('?')) : url;
			let params = url.lastIndexOf('?') > 0 ? url.substr(url.lastIndexOf('?') + 1).split('&') : [];

			for (const prop in data) {
				if (data.hasOwnProperty(prop)) {
					if (data[prop] !== null){
						let _found = false;
						for (let i = 0; i < params.length; i++){
							if (params[i].substr(0, params[i].indexOf('=')) === prop){
								params[i] = prop + '=' + data[prop];
								_found = true;
								break;
							}
						}
						if (!_found){
							params.push(prop + '=' + data[prop]);
						}
					}
					else {
						for (let i = 0; i < params.length; i++){
							if (params[i].substr(0, params[i].indexOf('=')) === prop){
								params.splice(i, 1);
								break;
							}
						}
					}
				}
			}

			params = params.join('&');
			url = uri + '?' + params;
			window.history.pushState(url, null, url);
		},

		getURLWithParams(url, data){
			let params = [];

			for (const prop in data) {
				if (data.hasOwnProperty(prop)) {
					if (data[prop] !== null){
						params.push(prop + '=' + data[prop]);
					}
				}
			}

			return url + '?' + params.join('&');
		}
	}
})();