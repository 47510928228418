(function () {
	let
		object = {},
		lang_json = {},
		locale = document.documentElement.lang;

	//Grab the language data from the data controller
	lang_json = DataController.language;

	object.translate = function (message){
		return message.split('.').reduce(function (obj, index){ return obj[index]; }, lang_json);
	};

	object.exists = function (message, strict = false){
		return strict
			? typeof(this.translate(message)) === 'string'
			: this.translate(message) !== undefined;
	};

	module.exports = object;
	return object;
})();