<template>
	<div class="w-100" style="min-height: 120px">
		<transition name="fade">
			<vue-circle
				v-if="show"
				ref="progress_animation"
				:progress="progress"
				:size="90"
				:fill="{color:'#f0612e'}"
				:animation="{duration: 0}"
				:reverse="false"
				line-cap="square"
				empty-fill="rgba(0, 0, 0, .1)"
				:animation-start-value="0.0"
				:start-angle="4.7"
				insert-mode="append"
				:thickness="8"
				:show-percent="true"

				@vue-circle-end="updateProgress"
			></vue-circle>
		</transition>
	</div>
</template>

<script>
	export default {
		name: "FinalAnimation",

		props: [
			'interval',
            'answered_percentage'
		],

		data(){
			return {
				progress: 0,
				cycle: false,
				interval_stored: 0,
				show: false,
			}
		},

		beforeMount(){
			this.interval_stored = this.interval ? this.interval : 1;
		},

		mounted(){
			let _this = this;

			window.setTimeout(function (){
				_this.show = true;

				window.setTimeout(function (){
					_this.updateProgress();
				}, 250);
			}, 350);
		},

		methods: {
			updateProgress(){
				if (this.cycle || this.progress >= 100 || this.progress >= this.answered_percentage)
					return;

				let _this = this;
				_this.cycle = true;

				window.setTimeout(function (){
					_this.progress++;
					_this.cycle = false;
				}, _this.interval_stored);
			}
		},

		watch: {
			progress(){
				this.$refs.progress_animation.updateProgress(this.progress);
			}
		}
	}
</script>

<style>
	.fade-enter-active, .fade-leave-active {
		transition: all .5s;
	}

	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}

	.percent-text {
		padding-bottom: 5px;
	}
</style>
