<template>
	<div class="reporting-page--section" data-section="open_ended">
		<collapsable :title="__('translation.reporting_section_open_ended')">
			<div v-if="loading" class="loaders--standard override"></div>

			<!--table-->
			<div class="row" v-else>

				<!--<div class="col-12 mt-3" v-for="(messages, term) in data">-->
				<!--	<h5 class="font-weight-bold">{{term}}</h5>-->
				<!--	<hr />-->
				<!--	<p v-for="message in messages" class="pb-2"> - {{message}}</p>-->
				<!--</div>-->
				<div class="col-12 mt-3" v-for="(section, section_index) in data">

					<div class="reporting-page--section-card my-4">
						<a
							class="reporting-page--section-header d-block position-relative"
							data-toggle="collapse"
							:href="'#open-section-' + section.id"
							role="button"
							:aria-expanded="data.length === 1"
							:aria-controls="'#open-section-' + section.id"
						>
							<div class="row mx-0 pt-1">
								<div class="col-12 mt-2">
									<h4 class="font-weight-bold">{{section.name}}</h4>
								</div>
							</div>
							<div class="reporting-page--section-header-status pt-1 position-absolute" style="top: 0px; right: 0px;">
								<div class="circle-plus closed">
									<div class="circle mt-1">
										<div class="horizontal"></div>
										<div class="vertical"></div>
									</div>
								</div>
							</div>
						</a>


						<div class="collapse pb-2" :id="'open-section-' + section.id" v-bind:class="data.length === 1 ? 'show' : ''">
							<div class="row mx-0" v-for="(question, q_index) in section.questions">
								<div class="col-12 pt-3">
									<h4 class="font-weight-bold">{{question.name}}</h4>
								</div>
								<div class="col-12">
									<div class="mt-3" v-for="(term_data, term) in question.terms" :style="{'color' : term_data.answer_count ? '' : '#c3c3c3 !important'}">
										<div class="row" v-if="term_data.comparison_data && term_data.comparison_data.answer_count">
											<!--Comparison Report-->
											<div class="col-12 d-flex">
												<h6 class="my-auto font-weight-bold">{{term}} - </h6>
												<h6 class="my-auto font-weight-bold font-italic">{{__('reporting.feedback.previous_report')}}</h6>
											</div>
											<div class="col-12 my-2" v-for="answer in term_data.comparison_data.answers">
												<sub style="font-size: .9rem; word-wrap: break-word;">"{{answer}}"</sub>
											</div>
											<div class="col-12">
												<hr/>
											</div>

											<!--Current Report-->
											<div class="col-12 d-flex">
												<h6 class="my-auto font-weight-bold" :style="{color: primary_color}">{{term}} - </h6>
												<h6 class="my-auto font-weight-bold font-italic" :style="{color: primary_color}">{{__('reporting.feedback.current_report')}}</h6>
											</div>
											<div class="col-12 my-2" v-for="answer in term_data.answers">
												<sub style="font-size: .9rem; word-wrap: break-word;" :style="{color: primary_color}">"{{answer}}"</sub>
											</div>
											<div class="col-12">
												<hr/>
											</div>
										</div>
										<div class="row" v-else>
											<div class="col-12">
												<h6 class="my-auto font-weight-bold">{{term}}</h6>
											</div>
											<div class="col-12 my-2" v-for="answer in term_data.answers">
												<sub style="font-size: .9rem; word-wrap: break-word;">"{{answer}}"</sub>
											</div>
											<div class="col-12">
												<hr/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</collapsable>
		<hr v-if="!loading" />
	</div>
</template>

<script>
	import _ from "underscore/underscore"
	import Collapsable from "./Collapsable";
	export default {
		name: "OpenEndedQuestions",
		components: {Collapsable},
		props: [
			'load_url',
			'token',

			'primary_color',
			'secondary_color'
		],

		data(){
			return {
				loading: true,
				pre_loading: true,

				data: {},

			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			loadData(){
				let _this = this;

				_this.loading = true;

				axios.post(_this.load_url, {
					token: this.token,
					code: 'open_ended'
				})
					.then(function (response){
						_this.loading = false;

						_this.data = response.data;
						_this.$emit('loaded', {
							sections: _this.data,
							primary_color: _this.primary_color
						});
					})
					.catch(function (){
						//@todo
					})
			}
		},

		watch: {
			token() {
				this.$emit('unloaded');
				if (this.token !== null) {
					this.pre_loading = false;
					this.loading = false;
					this.loadData();
				}
				else {
					this.pre_loading = true;
					this.loading = true;
				}
			}
		}
	}
</script>

<style>
	pre {
		white-space: pre-wrap;       /* Since CSS 2.1 */
		white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
		white-space: -pre-wrap;      /* Opera 4-6 */
		white-space: -o-pre-wrap;    /* Opera 7 */
		word-wrap: break-word;       /* Internet Explorer 5.5+ */

		font-family: 'Source Sans Pro',
		sans-serif; font-size: .8rem;
		margin: auto 0;
	}
</style>