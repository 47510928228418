<template>
	<div class="calendar">
		<div class="calendar--wrapper" v-bind:class="disabled ? 'disabled' : ''">
			<div class="calendar--header">
				<img v-on:click="prevMonth()" src="/svg/month-back.svg" class="calendar--header-chevron" v-bind:class="disabled ? 'disabled' : ''">
				<h5 class="calendar--header-display">{{__('dates.months.' + month)}} {{year}}</h5>
				<img v-on:click="nextMonth()" src="/svg/month-forward.svg" class="calendar--header-chevron" v-bind:class="disabled ? 'disabled' : ''">
			</div>
			<div class="calendar--weekdays">
				<div class="calendar--weekdays-day" v-for="i in [1, 2, 3, 4, 5, 6, 0]">
					{{__('dates.weekdays')[i].substr(0, 1)}}
				</div>
			</div>
			<div class="calendar--body">
				<div
					class="calendar--body-day calendar--cell "
					v-for="(day_data, index) in calendar_data"
					v-bind:class="{'today': day_data.isToday, 'active': day_data.selected, 'disabled': disabled}"
					v-on:click="selectDate(index)"
				>{{day_data.day}}</div>
			</div>
		</div>

		<!--hours-->
		<div class="calendar--hours-wrapper">
			<h5>Time</h5>
			<select class="form-control" v-model="hours" v-bind:disabled="selected_date === null">
				<option v-for="i in 24" v-bind:value="i-1">{{('0' + (i-1)).slice(-2)}}</option>
			</select>
			<span>:</span>
			<select class="form-control" v-model="minutes" v-bind:disabled="selected_date === null">
				<option v-for="i in [0, 15, 30, 45]" v-bind:value="i">{{('0' + i).slice(-2)}}</option>
			</select>
		</div>

		<div class="calendar--footer">
			<div class="text-center mt-2">
				<h6 class="text-primary">{{placeholder}}</h6>
			</div>
			<div v-if="selected_date" class="text-center">
				<h6 class="mb-0">{{__('dates.weekdays.' + getDateOfWeek(selected_date))}}, {{getHours(selected_date)}}</h6>
				<h3>{{getDayMonth(selected_date)}}</h3>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Calendar",
		props: [
			'selected',
			'placeholder',
			'default_now',
			'disabled'
		],

		data(){
			return {
				calendar_data: [],

				month: 0,
				year: 2000,

				hours: 0,
				minutes: 0,

				today: new Date(),

				selected_date: null,
			}
		},

		beforeMount(){
			if (this.selected){
				let _toParseDate = this.selected;
				while(_toParseDate.indexOf('-') !== -1){
					_toParseDate = _toParseDate.replace('-', '/');
				}

				this.selected_date = new Date(_toParseDate);
				this.month = this.selected_date.getMonth();
				this.year = this.selected_date.getFullYear();
				this.hours = this.selected_date.getHours();
				this.minutes = this.selected_date.getMinutes();
			}
			else {
				let date = new Date();
				this.month = date.getMonth();
				this.year = date.getFullYear();
				if (this.default_now){
					this.hours = date.getHours() + 1;
					this.minutes = date.getMinutes() > 30 ? 30 : 0;
				}
			}

			this.loadMonth();

			if (this.disabled){
				this.$emit('change', null);
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			getDateOfWeek(date){
				return new Date(date).getDay();
			},

			getHours(date){
				date = new Date(date);
				return ('0' + date.getHours()).slice(-2)  + ':' + ('0' + date.getMinutes()).slice(-2);
			},

			getDayMonth(date){
				date = new Date(date);
				return date.getDate() + ' ' + this.__('dates.months.' + date.getMonth());
			},

			loadMonth(){
				this.calendar_data = [];

				let date = new Date(this.year, this.month);

				//find the first week day of the month
				if (date.getDay() === 0){
					this.calendar_data = [{day: null}, {day: null}, {day: null}, {day: null}, {day: null}, {day: null}];
				}
				else {
					for (let i = 1; i < date.getDay(); i++){
						this.calendar_data.push({day: null});
					}
				}

				for (let i = 0; i < new Date(this.year, this.month + 1, 0).getDate(); i++){
					let
						_selected = false,
						_isToday = false;

					_isToday =
						i + 1 ===  this.today.getDate() &&
						this.month === this.today.getMonth() &&
						this.year === this.today.getFullYear();

					if (this.selected_date){
						_selected =
							i + 1 === this.selected_date.getDate() &&
							this.month === this.selected_date.getMonth() &&
							this.year === this.selected_date.getFullYear();
					}

					this.calendar_data.push({
						day: i + 1,
						isToday: _isToday,
						selected: _selected,
					});
				}
			},

			prevMonth(){
				if (this.disabled){
					return;
				}
				if (this.month === 0){
					this.month = 11;
					this.year --;
				}
				else {
					this.month --;
				}

				this.loadMonth();
			},

			nextMonth(){
				if (this.disabled){
					return;
				}
				if (this.month === 11){
					this.month = 0;
					this.year ++;
				}
				else {
					this.month ++;
				}

				this.loadMonth();
			},

			selectDate(index){
				if (this.disabled){
					return;
				}
				this.selected_date = new Date(this.year, this.month, this.calendar_data[index].day, this.hours, this.minutes);
				this.$emit('change', this.selected_date);
				this.loadMonth();
			}
		},

		watch: {
			selected(){
				if (this.selected){
					let _toParseDate = this.selected;
					while(_toParseDate.indexOf('-') !== -1){
						_toParseDate = _toParseDate.replace('-', '/');
					}

					this.selected_date = new Date(_toParseDate);
					this.month = this.selected_date.getMonth();
					this.year = this.selected_date.getFullYear();
					this.hours = this.selected_date.getHours();
					this.minutes = this.selected_date.getMinutes();
				}
				else {
					this.selected_date = null;
					this.month = new Date().getMonth();
					this.year = new Date().getFullYear();
				}

				this.loadMonth();
			},
			hours(){
				if (this.selected_date){
					this.selected_date = new Date(this.year, this.month, this.selected_date.getDate(), this.hours, this.minutes);
					this.$emit('change', this.selected_date);
				}
			},
			minutes(){
				if (this.selected_date){
					this.selected_date = new Date(this.year, this.month, this.selected_date.getDate(), this.hours, this.minutes);
					this.$emit('change', this.selected_date);
				}
			},
			disabled(val){
				if (val){
					this.selected_date = null;
					this.month = new Date().getMonth();
					this.year = new Date().getFullYear();
					this.hours = 0;
					this.minutes = 0;
					this.loadMonth();
					this.$emit('change', null);
				}
			}
		}
	}
</script>