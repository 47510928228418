<template>
	<div class="feedo-modal" v-on:click="closeModal($event)">
		<div class="feedo-modal--card" style="width: 580px">
			<div class="feedo-modal--card-header">
				<div class="feedo-modal--card-header-title">
					<h4>{{help_tip ? __('translation.update_tip_title') : __('translation.create_tip_title')}}</h4>
				</div>
				<a href="javascript:void(0)" class="feedo-modal--card-header-close" v-on:click="closeModal($event, true)">
					<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch -->
						<title>Icon/delete-question</title>
						<desc>Created with Sketch.</desc>
						<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="Table/Question" transform="translate(-618.000000, -14.000000)" fill="#b1b2c1">
								<g id="Icon/Delete" transform="translate(618.000000, 14.000000)">
									<path d="M9.75832409,8.34411053 L6.70710678,5.29289322 C6.31658249,4.90236893 5.68341751,4.90236893 5.29289322,5.29289322 C4.90236893,5.68341751 4.90236893,6.31658249 5.29289322,6.70710678 L8.34411053,9.75832409 L5.29289322,12.8095414 C4.90236893,13.2000657 4.90236893,13.8332307 5.29289322,14.223755 C5.68341751,14.6142793 6.31658249,14.6142793 6.70710678,14.223755 L9.75832409,11.1725377 L12.8095414,14.223755 C13.2000657,14.6142793 13.8332307,14.6142793 14.223755,14.223755 C14.6142793,13.8332307 14.6142793,13.2000657 14.223755,12.8095414 L11.1725377,9.75832409 L14.223755,6.70710678 C14.6142793,6.31658249 14.6142793,5.68341751 14.223755,5.29289322 C13.8332307,4.90236893 13.2000657,4.90236893 12.8095414,5.29289322 L9.75832409,8.34411053 Z M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z" id="Combined-Shape-Copy"></path>
								</g>
							</g>
						</g>
					</svg>
				</a>
			</div>
			<div class="feedo-modal--card-body p-3">
				<div class="row mt-4 justify-content-center">
					<div class="col-10">
						<input
							id="tip-name-input"
							type="text"
							class="form-control bg-white shadow"
							v-bind:class="focus ? 'border border-danger' : ''"
							v-model="tip_name"
							:placeholder="__('place_holders.tip_name_title')"
							v-on:keydown.enter="save()"
						>
					</div>
                    <div class="col-10 mt-3">
						<textarea
                            id="tip-text-input"
                            type="text"
                            class="form-control bg-white shadow"
                            v-bind:class="focus ? 'border border-danger' : ''"
                            rows="6"
                            style="resize: none"
                            v-model="tip_help"
                            :placeholder="__('place_holders.tip_text')"
                            v-on:keydown.enter="save()"
                        ></textarea>
                    </div>
					<div class="col-8 mt-3 text-center">
						<button class="btn btn-secondary" v-bind:class="saving ? 'disabled' : ''" v-on:click="save()">{{__('buttons.save_button')}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name: "CrudHelpTipModal",
		props: [
			'create_edit_url',
			'help_tip',
		],

		data(){
			return {
			    tip_id: null,
				tip_name: '',
                tip_help: '',
				focus: false,
				saving: false,
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			closeModal(e, force = false){
				if (!force){
					if ($(e.target).closest('.feedo-modal--card').length){
						return;
					}
				}

				this.focus = false;
                this.tip_id = null;
                this.tip_name = '';
                this.tip_help = '';
                this.$emit('closed');
				window.closeModal();
			},

            resolveSaved(response){
                this.$emit('saved', response.data.help_tip);
                this.saving = false;
                this.closeModal(null, true);
                if (this.help_tip){
                    this.tip_id = response.data.help_tip.id;
                    this.tip_name = response.data.help_tip.name;
                    this.tip_help = response.data.help_tip.text;
                }
                else {
                    this.tip_id = null;
                    this.tip_name = '';
                    this.tip_help = '';
                }
            },

            catchSaved(error){
                for (var i in error.response.data.errors) {
                    if (error.response.data.errors.hasOwnProperty(i)) {
                        toastr.error(error.response.data.errors[i][0]);
                    }
                }

                this.focus = true;
                this.saving = false;
            },

			save(){
				if (this.tip_name.trim() !== ''){
					this.focus = false;

					if (this.saving) return;
					this.saving = true;

                    axios.post(this.create_edit_url, {
                        id: this.tip_id || undefined,
                        name: this.tip_name,
                        help: this.tip_help
                    })
                        .then(this.resolveSaved)
                        .catch(this.catchSaved);
				}
				else {
					this.focus = true;
				}
			}
		},

		watch: {
            help_tip(){
                if (this.help_tip){
                    this.tip_id = this.help_tip.id;
                    this.tip_name = this.help_tip.name;
                    this.tip_help = this.help_tip.text;
                }
                else {
                    this.tip_id = null;
                    this.tip_name = '';
                    this.tip_help = '';
                }
			}
		}
	}
</script>
