<template>
    <div class="container mt-4">
        <div class="row">
            <!--Main Body-->
            <div v-if="help_sections.length" class="col-md-10 order-1 order-md-0">
                <div class="container p-0">
                    <div class="row">
                        <div class="col-lg-4">
                            <ul class="list-group">
                                <li v-for="(section, index) in help_sections" class="list-group-item" :key="section.id">
                                    <a href="javascript:void(0)" v-on:click="selectSection(index)">
                                        <span v-bind:class="index === selected_section ? 'text-primary' : ''">{{section.name}}</span>
                                        <img v-if="index === selected_section" class="d-block my-auto" src="/svg/chevron-active.svg" />
                                        <img v-else class="d-block my-auto" src="/svg/chevron-inactive.svg" />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <!--Table-->
                        <div class="col-lg-8 mt-5 mt-lg-0">
                            <div
                                v-for="(help_tip, index) in help_sections[selected_section].help_tips"
                                class=""
                            >
                                <collapsable :title="help_tip.name">
                                    <p class="m-0">{{help_tip.text}}</p>
                                </collapsable>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import Collapsable from "../reporting/Collapsable";
    export default {
		name: "HelpIndexPage",
        components: {Collapsable},
        props: [
            'help_sections'
        ],
        data() {
		    return {
                selected_section: 0
            }
        },
        methods: {

            selectSection(index){
                this.selected_section = index;
            },

        }
	}
</script>
