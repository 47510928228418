<template>
	<div class="reporting-page--section-card my-4">
		<a v-bind:class="show ? 'd-none' : 'd-block'" class="reporting-page--section-header position-relative" data-toggle="collapse" :href="'#section-breakdown-' + section.id" role="button" :aria-expanded="show" :aria-controls="'section-breakdown-' + section.id">
			<div class="row mx-0 pt-1">
				<div class="col-12 mt-2">
					<h4 class="font-weight-bold">{{index + ' - ' + section.name}}</h4>
				</div>

				<div class="col-12">

					<participants-respondents-bars
						v-if="showSectionBreakdown(section)"
						v-for="(term_data, term, index) in section.terms"

						:key="index"

						:term="term"
						:term_data="term_data"
						:primary_color="primary_color"
						:secondary_color="secondary_color"
						:primary_color_faded="primary_color_faded"
						:secondary_color_faded="secondary_color_faded"
					></participants-respondents-bars>
				</div>
			</div>
			<div class="reporting-page--section-header-status pt-1 position-absolute" style="top: 0; right: 0;">
				<div class="circle-plus closed">
					<div class="circle mt-1">
						<div class="horizontal"></div>
						<div class="vertical"></div>
					</div>
				</div>
			</div>
		</a>
		<div class="collapse" :id="'section-breakdown-' + section.id" v-bind:class="show ? 'show' : ''">
			<div class="row mx-0" v-for="(question, q_index) in section.questions">
				<div class="col-12">
					<hr v-if="show && q_index !== 0"/>
					<div v-else class="pt-3"></div>
				</div>
				<div class="col-12">
					<h4 class="font-weight-bold">{{show ? (q_index + 1) + ' - ' + question.name : index + '.' + (q_index + 1) + ' - ' + question.name}}</h4>
				</div>
				<div class="col-12">
					<participants-respondents-bars
						v-for="(term_data, term, index) in question.terms"

						:key="index"

						:term="term"
						:term_data="term_data"
						:question="question"

						:primary_color="primary_color"
						:secondary_color="secondary_color"
						:primary_color_faded="primary_color_faded"
						:secondary_color_faded="secondary_color_faded"
					></participants-respondents-bars>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import _ from "underscore/underscore"
	import ParticipantsRespondentsBars from "./ParticipantsRespondentsBars";
	export default {
		name: "CompetencyBreakdownCard",
		components: {ParticipantsRespondentsBars},
		props: [
			'section',
			'index',
			'primary_color',
			'secondary_color',
			'primary_color_faded',
			'secondary_color_faded',
			'show'
		],

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			showSectionBreakdown(section){
				return _.filter(section.questions, function (question) { return question.open_ended || question.yes_no_question}).length !== section.questions.length;
			},

			getColorForScoreBasedOnTermData(term_data){
				if (!term_data.answer_count){
					return '#c3c3c3';
				}
				return term_data.is_self ? this.primary_color : this.secondary_color;
			},

			compareValues(a, b, toFixed = null){
				const val = a - b;
				if (val > 0)
					return {
						class: 'text-success',
						different: true,
						label: '+' + (toFixed ? val.toFixed(toFixed) : val)
					};
				else if (val < 0)
					return {
						class: 'text-danger',
						different: true,
						label: (toFixed ? val.toFixed(toFixed) : val)
					};
				else
					return {
						class: 'text-grey',
						different: false,
						label: (toFixed ? (0).toFixed(toFixed) : '0')
					};
			}
		}
	}
</script>
