<template>
	<div class="container">
		<div class="row">
			<!--Main Body-->
			<div v-if="folders.length" class="col-md-10 order-1 order-md-0">
				<div class="container p-0">
					<div class="row">
						<div class="col-12">
							<search-input
								:href="search_url"
								:placeholder="search_placeholder"
								:query="search_query"
							></search-input>
						</div>
					</div>
					<div class="row">
						<!--Folder Select-->
						<div class="col-lg-4">
							<ul class="list-group">
								<li v-for="(folder, index) in folders" class="list-group-item">
									<a href="javascript:void(0)" v-on:click="selectFolder(index)">
										<span v-bind:class="index === selected_folder ? 'text-primary' : ''">{{folder.name}} <small>({{library[index].questionnaire_libraries.length}})</small></span>
										<img v-if="index === selected_folder" class="d-block my-auto" src="/svg/chevron-active.svg" />
										<img v-else class="d-block my-auto" src="/svg/chevron-inactive.svg" />
									</a>
								</li>
							</ul>
						</div>

						<!--Table-->
						<div class="col-lg-8">
							<questionnaire-library-table
								:library="library[selected_folder].questionnaire_libraries"
								@select="selectQuestionnaire"
							></questionnaire-library-table>

							<div v-if="!library[selected_folder].questionnaire_libraries.length" class="text-center mt-5">
								<img class="d-inline-block" src="/svg/questionnaire-add.svg" alt="Add Questionnaire">
								<span class="d-block w-100 text-primary mt-2">{{__('translation.no_questionnaires_added')}}</span>

								<div class="d-block mx-auto mt-3" style="max-width: 300px" v-if="checkPermission()">
									<a
										href="javascript:void(0)"
										v-on:click="openUploadQuestionnaire()"
										class="d-block w-100 btn btn-outline"
									>{{__('buttons.upload_questionnaire')}}</a>

									<a
										href="javascript:void(0)"
										v-on:click="openCreateQuestionnaire()"
										class="d-block w-100 mt-2 btn btn-outline"
									>{{__('translation.create_questionnaire_title')}}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!--Side Actions-->
			<div v-if="folders.length" class="col-md-2 order-0 order-md-1 right-side-list">
				<a
					v-on:click="openCreateFolder()"
					href="javascript:void(0)"
					class="right-side-list--item"
					v-if="checkPermission()"
				>{{__('buttons.create_folder_button')}}</a>
				<hr
					v-if="checkPermission() && folders.length"/>

				<a
					:href="download_questionnaire_template_url"
					target="_blank"
					class="right-side-list--item"
					v-if="checkPermission() && folders.length && download_questionnaire_template_url"
				>{{__('buttons.download_questionnaire_template')}}</a>

				<hr
					v-if="checkPermission() && folders.length"/>

				<a
					v-on:click="openCreateQuestionnaire()"
					href="javascript:void(0)"
					class="right-side-list--item"
					v-if="checkPermission() && folders.length"
				>{{__('buttons.create_questionnaire_button')}}</a>

				<a
					v-on:click="openUploadQuestionnaire()"
					href="javascript:void(0)"
					class="right-side-list--item"
					v-if="checkPermission() && folders.length"
				>{{__('buttons.upload_questionnaire')}}</a>

				<hr
					v-if="checkPermission() && folders.length"/>
				<a
					v-on:click="deleteFolder()"
					href="javascript:void(0)"
					class="right-side-list--item"
					v-if="checkPermission('delete_folder') && folders.length"
				>{{__('buttons.delete_folder')}}</a>
				<a
					v-on:click="renameFolder()"
					href="javascript:void(0)"
					class="right-side-list--item"
					v-if="checkPermission('change_folder_name') && folders.length"
				>{{__('buttons.rename_folder')}}</a>
			</div>


			<!--No folders-->
			<div v-if="!folders.length && checkPermission()" class="col-md-5 offset-md-3 order-5 mt-5 pt-5 text-center">
				<img src="/svg/questionnaire_folder.svg" alt="Questionnaire Folder">
				<button
					v-on:click="openCreateFolder()"
					class="d-block px-3 mx-auto btn btn-outline mt-4"
				>{{__('buttons.create_questionnaire_folder')}}</button>
				<h6 class="mt-4 text-primary">{{__('translation.make_questionnaire_folder')}}</h6>
			</div>

			<create-folder-modal
				v-if="checkPermission()"
				id="create-folder"
				:create_url="create_folder_url"
			></create-folder-modal>

			<create-questionnaire-modal
				v-if="checkPermission()"
				id="create-questionnaire"
				:create_url="create_url"
				:folders="folders"
				:selected_index="selected_folder"
				@created="createANewQuestionnaire"
			></create-questionnaire-modal>

			<change-folder-name-modal
				v-if="folders.length && checkPermission('change_folder_name')"
				id="change-folder-name"
				:update_url="update_folder_url"
				:folder="folders[selected_folder]"
				@saved="changedFolderName"
			></change-folder-name-modal>

			<csv-upload-modal
				id="csv-upload-questionnaire-modal"
				prefix="questionnaire"

				:extra_name_field="true"
				:folders="folders"
				:type="type"
				:fields="['Question', 'Section']"
				:upload_url="questionnaire_csv_validate_url"
				:submit_url="questionnaire_csv_submit_url"
				@submitted="submittedCSV"
			></csv-upload-modal>
		</div>
	</div>
</template>

<script>
	import QuestionnairesLibraryTableComponent from './QuestionnairesLibraryTableComponent';
	import CreateQuestionnaireModal from './../modals/CreateQuestionnaireModal'
	import CreateFolderModal from './../modals/CreateFolderModal'
	import CSVUploadModal from './../modals/CSVUploadModal'
	import ChangeFolderNameModal from "../modals/ChangeFolderNameModal";

	export default {
		name: "QuestionnairesLibraryIndexPage",
		components:{
			ChangeFolderNameModal,
			'questionnaire-library-table': QuestionnairesLibraryTableComponent,
			'csv-upload-modal': CSVUploadModal,
			CreateQuestionnaireModal,
			CreateFolderModal,
		},

		props: [
			'library',

			'create_url',
			'edit_url',
			'create_folder_url',
			'update_folder_url',
			'delete_folder_url',

			'questionnaire_csv_validate_url',
			'questionnaire_csv_submit_url',
			'download_questionnaire_template_url',

			'type',

			'search_url',
			'search_placeholder',
			'search_query'
		],

		data() {
			return {
				folders: [],
				selected_folder: 0,
			}
		},

		beforeMount(){
			let _this = this;

			this.PermissionsController = window.PermissionsController;

			_this.library.forEach(function (folder){
				_this.folders.push(folder);
			});
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			selectFolder(folder_index){
				this.selected_folder = folder_index;
			},

			checkPermission(functionaility = ''){
				if (this.type === 'templates')
					return PermissionsController.can('save template questionnaires library');
				else if (this.type === 'my_library')
					return PermissionsController.can('crud self questionnaires library');
				else if (this.type === 'company_library' && (
					functionaility === 'change_folder_name'
				))
					return PermissionsController.can('save company questionnaires library');

				return false;
			},

			openCreateFolder(){
				openModal($('#create-folder'));
			},

			openCreateQuestionnaire(){
				openModal($('#create-questionnaire'));
			},

			openUploadQuestionnaire(){
				openModal($('#csv-upload-questionnaire-modal'));
			},

			submittedCSV(questionnaire){
				window.location.href = this.edit_url.replace('?', questionnaire.questionnaire_library_id);
			},

			createANewQuestionnaire(questionnaire_library){
				window.location.href = this.edit_url.replace('?', questionnaire_library.id);
			},

			selectQuestionnaire(index){
				window.location.href = this.edit_url.replace('?', this.library[this.selected_folder].questionnaire_libraries[index].id);
			},



			deleteFolder(){
				let _this = this;

				confirm('Are you sure you want to delete ' + _this.folders[_this.selected_folder].name + ' folder? <br/>This will also delete any remaining questionnaires', function (response){
					if (!response){
						return;
					}

					axios.delete(_this.delete_folder_url.replace('?', _this.folders[_this.selected_folder].id))
						.then(function (){
							window.location.reload();
						});
				});
			},

			renameFolder(){
				openModal($('#change-folder-name'));
			},

			changedFolderName(name){
				this.folders[this.selected_folder].name = name
			}
		}

	}
</script>
