(function (){
	let
		object = {},
		locked = false;

	object.lockWindow = function(){
		if (locked) return;

		window.onbeforeunload = function (e){
			if(!e) e = window.event;
			//e.cancelBubble is supported by IE - this will kill the bubbling process.
			e.cancelBubble = true;
			e.returnValue = 'Any unsaved changes will be lost, are you sure?'; //This is displayed on the dialog

			//e.stopPropagation works in Firefox.
			if (e.stopPropagation) {
				e.stopPropagation();
				e.preventDefault();
			}
		};
		locked = true;
	};

	object.unlockWindow = function(){
		window.onbeforeunload = undefined;
	};


	module.exports = object;
	return object;
})();