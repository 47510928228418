<template>
	<div>
		<a class="reporting-page--section-header" data-toggle="collapse" :href="'#collapse-' + _uid" role="button" aria-expanded="false" :aria-controls="'collapse-' + _uid">
			<h4 class="reporting-page--section-header-title">{{title}}</h4>
			<div class="reporting-page--section-header-status">
				<div class="circle-plus closed">
					<div class="circle">
						<div class="horizontal"></div>
						<div class="vertical"></div>
					</div>
				</div>
			</div>
		</a>
		<div :id="'collapse-' + _uid" class="collapse">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Collapsable",
		props: [
			'title'
		]
	}
</script>