<template>
	<div class="row mt-4">
		<div class="col-md-4">
			<ul class="list-group">
				<li v-for="(page, index) in pages" class="list-group-item">
					<a href="javascript:void(0)" v-on:click="selectPage(index)">
						<span v-bind:class="index === selected_page ? 'text-primary' : ''">{{page.title}}</span>
						<img v-if="index === selected_page" src="/svg/chevron-active.svg" />
						<img v-else src="/svg/chevron-inactive.svg" />
					</a>
				</li>
			</ul>

			<!--<div class="mt-4">-->
			<!--	<label for="language-select">{{__('place_holders.select_language_label')}}</label>-->
			<!--	<select id="language-select" class="form-control" v-model="language_code_edit" v-on:change="changeLanguageConfirm">-->
			<!--		<option v-for="(language, code) in languages" :value="code">{{language.label}}</option>-->
			<!--	</select>-->
			<!--</div>-->
		</div>
		<div class="col-md-8">
			<div class="row px-0 mb-4">
				<div class="col-md-6 mb-2">
					<label for="from_name_input" class="mb-0">{{__('translation.from_name_title')}}</label>
					<input @keydown="lock_messages = false" @change="changed" id="from_name_input" v-bind:disabled="disabled" v-model="campaign_messages[selected_page].from" type="text" class="form-control" :placeholder="__('place_holders.from_name')">
				</div>
				<div class="col-md-6 mb-2">
					<label for="from_email_input" class="mb-0">{{__('translation.from_email_title')}}</label>
					<input
						@keydown="lock_messages = false"
						@change="changed"
						id="from_email_input"
						v-bind:disabled="disabled"
						v-model="campaign_messages[selected_page].from_address"
						type="text"
						class="form-control"
						:placeholder="__('place_holders.from_email')"
					>
				</div>
				<div class="col-12">
					<div class="checkbox my-auto d-flex">
						<label class="customer_checkbox">
							<span class="">{{ __('place_holders.use_to_all_messages') }}</span>
							<input class="form-check-input" type="checkbox" @change="checkLockDetails" v-model="lock_messages">
							<span class="checkmark"></span>
						</label>
					</div>
				</div>
				<div class="col-12 mt-4">
					<label for="subject_input" class="mb-0">{{__('translation.subject_title')}}</label>
					<input @change="changed" id="subject_input" v-bind:disabled="disabled" v-model="campaign_messages[selected_page].subject" type="text" class="form-control" :placeholder="__('place_holders.subject')">
				</div>
			</div>

			<div v-if="!selecting_page">
				<Redactor
					v-if="!disabled"
					id="redactor-message-board"
					class="message-board"
					v-model="textbox"
					:config="{
						breakline: false,
						focus: true,
						plugins: ['variable'],
						variables: fields,
						stylesClass: 'redactor-box-grey'
					}"
				></Redactor>
				<textarea class="w-100 form-control" rows="10" style="resize: none" v-else disabled="disabled" v-model="textbox"></textarea>
			</div>
		</div>

		<!--Change Language-->
		<change-messages-language-modal
			id="change-messages-language-modal"
			:update_url="change_messages_language_url"
			:language_code="language_code_edit"
			@saved="updateAllMessages"
		></change-messages-language-modal>
	</div>
</template>

<script>
	import ChangeMessagesLanguageModal from "../modals/ChangeMessagesLanguageModal";
	export default {
		name: "CampaignMessages",
		components: {ChangeMessagesLanguageModal},
		props: [
			'campaign',
			'languages',
			'default_subject',
			'messages_update_url',
			'change_messages_language_url',
			'disabled',

			'disable_auto_save',
			'back_url_upon_save'
		],

		data(){
			return {
				campaign_messages: [],

				pages: [],
				fields: [],
				textbox: '',


				lock_messages: false,

				selected_page: 0,
				selecting_page: false,

				resave: false,
				saving: false,
				save_timer: null,
				language_code_edit: '',
				language_code_to_change: '',
			}
		},

		beforeMount(){
			let _this = this;

			_this.campaign_messages = _this.campaign.messages;

			_this.language_code_edit = _this.campaign.language_code;

			_this.campaign_messages.forEach(function (message){
				if(!message.subject) message.subject = _this.default_subject;
				if(!message.body) message.body = '';

				_this.pages.push({
					title: _this.__('emails.' + message.code + '_title'),
					code: message.code,
				})
			});

			_this.getFields();

			_this.textbox = _this.campaign_messages[_this.selected_page].body;

			//Prevent saving an update just because the textbox was loaded
			_this.selecting_page = true;
			_this.$nextTick(function (){
				_this.selecting_page = false;
			})
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			selectPage(index) {
				this.selected_page = index;
				this.selecting_page = true;

				this.$nextTick(function (){
					this.selecting_page = false;
				})
			},

			changed(){
				if (this.disable_auto_save) return;
				if (this.selecting_page) return;

				//Set a debounce to save
				if (this.save_timer)
					clearTimeout(this.save_timer);

				this.save_timer = setTimeout(this.saveChanges, 1000);
			},

			changeLanguageConfirm(){
				openModal($('#change-messages-language-modal'));
			},

			updateAllMessages(messages){
				this.campaign_messages = messages;

				if (this.campaign_messages.length !== this.pages.length){
					window.location.reload();
					return;
				}
				//selects / reloads 1st page
				this.selectPage(0);
				this.textbox = this.campaign_messages[this.selected_page].body;
			},

			checkLockDetails(){
				if (this.lock_messages){
					let
						from = this.campaign_messages[this.selected_page].from,
						from_address = this.campaign_messages[this.selected_page].from_address;

					this.campaign_messages.forEach(function (message) {
						message.from = from;
						message.from_address = from_address;
					});

					this.saveChanges();
				}
			},

			saveChanges(){
				let _this = this;

				if (_this.saving) {
					_this.resave = true;
				}

				_this.saving = true;

				axios.post(_this.messages_update_url, {
					messages: _this.campaign_messages
				})
					.then(function (response){
						_this.saving = false;

						if (_this.resave){
							_this.saveChanges();
						}
						else if (_this.back_url_upon_save) {
							window.location.href = _this.back_url_upon_save;
							return;
						}
					})
					.catch(function (error) {
						for (var i in error.response.data.errors) {
							if (error.response.data.errors.hasOwnProperty(i)) {
								toastr.error(error.response.data.errors[i][0]);
							}
						}
						_this.saving = false;

						if (_this.resave){
							_this.saveChanges();
						}
					});
				_this.saving = false;
			},

			getFields(){
				this.fields = this.campaign_messages[this.selected_page].fields;
			}
		},

		watch: {
			selected_page() {
				this.getFields();
				this.textbox = this.campaign_messages[this.selected_page].body;
			},

			textbox(){
				this.campaign_messages[this.selected_page].body = this.textbox;
				this.changed();
			}
		}
	}
</script>
