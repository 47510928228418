<template>
	<div class="feedo-modal" v-on:click="closeModal($event)">
		<div class="feedo-modal--card" style="min-width: 600px; max-width: 655px">
			<div class="feedo-modal--card-header">
				<div class="feedo-modal--card-header-title">
					<h4>{{__('translation.add_just_participant_title')}}</h4>
				</div>
				<a href="javascript:void(0)" class="feedo-modal--card-header-close" v-on:click="closeModal($event, true)">
					<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch -->
						<title>Icon/delete-question</title>
						<desc>Created with Sketch.</desc>
						<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="Table/Question" transform="translate(-618.000000, -14.000000)" fill="#b1b2c1">
								<g id="Icon/Delete" transform="translate(618.000000, 14.000000)">
									<path d="M9.75832409,8.34411053 L6.70710678,5.29289322 C6.31658249,4.90236893 5.68341751,4.90236893 5.29289322,5.29289322 C4.90236893,5.68341751 4.90236893,6.31658249 5.29289322,6.70710678 L8.34411053,9.75832409 L5.29289322,12.8095414 C4.90236893,13.2000657 4.90236893,13.8332307 5.29289322,14.223755 C5.68341751,14.6142793 6.31658249,14.6142793 6.70710678,14.223755 L9.75832409,11.1725377 L12.8095414,14.223755 C13.2000657,14.6142793 13.8332307,14.6142793 14.223755,14.223755 C14.6142793,13.8332307 14.6142793,13.2000657 14.223755,12.8095414 L11.1725377,9.75832409 L14.223755,6.70710678 C14.6142793,6.31658249 14.6142793,5.68341751 14.223755,5.29289322 C13.8332307,4.90236893 13.2000657,4.90236893 12.8095414,5.29289322 L9.75832409,8.34411053 Z M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z" id="Combined-Shape-Copy"></path>
								</g>
							</g>
						</g>
					</svg>
				</a>
			</div>
			<div class="feedo-modal--card-body py-3 px-5">
				<div class="row mt-4" v-if="!no_select">
					<div class="col-12 d-flex">
						<img class="d-block my-auto pr-2" src="/svg/profile.svg" alt="">
						<span class="d-block my-auto">{{__('csv.choose_participant')}}</span>
					</div>
					<div class="col-12 mt-3">
						<select2-ajax-search
							:placeholder="__('place_holders.select_person')"

							:url="load_participants_url"

							id_prop="id"
							label_prop="full_name"

							:paginated="true"

							v-model="participant_user_selected"

							:null_option="__('translation.create_new')"
						></select2-ajax-search>
					</div>
				</div>

				<div v-if="participant_user_selected === null" class="row mt-3">
					<div class="col-md-6 mb-3">
						<input type="text" class="form-control bg-white shadow" :placeholder="__('place_holders.first_name')" v-model="participant.first_name">
					</div>
					<div class="col-md-6 mb-3">
						<input type="text" class="form-control bg-white shadow" :placeholder="__('place_holders.last_name')" v-model="participant.last_name">
					</div>
					<div class="col-md-6 mb-3">
						<input type="text" class="form-control bg-white shadow" :placeholder="__('place_holders.email')" v-model="participant.email">
					</div>
					<div class="col-md-6 mb-3" v-if="!no_department">
						<input type="text" class="form-control bg-white shadow" :placeholder="__('place_holders.department')" v-model="participant.department">
					</div>
				</div>


				<div class="row mt-4 justify-content-center">
					<div class="col-8 mt-3 text-center">
						<button class="btn btn-secondary" v-bind:disabled="saving" v-on:click="save()">{{__('buttons.add')}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Select2AjaxSearch from "../general_components/Select2AjaxSearch";
	export default {
		name: "AddJustParticipantModal",
		components: {Select2AjaxSearch},
		props: [
			'create_url',
			'no_department',
            'no_select',
			'load_participants_url',
			'reload_after'
		],

		data(){
			return {
				participant_user_selected: null,
				participant: {
					first_name: '',
					last_name: '',
					email: '',
					department: '',
				},

				saving: false,
			}
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},

			closeModal(e, force = false){
				if (!force){
					if ($(e.target).closest('.feedo-modal--card').length){
						return;
					}
				}

				window.closeModal();
			},

			save(){
				let _this = this;

				if (_this.saving) return;
				_this.saving = true;

				let data = {};

				if (_this.participant_user_selected !== null){
					data['participant_user_id'] = _this.participant_user_selected
				}
				else {
					data['participant'] = _this.participant;
				}

				axios.post(_this.create_url, data)
					.then(function (response){
						_this.saving = false;
						_this.participant = {
							first_name: '',
							last_name: '',
							email: '',
							department: ''
						};

						_this.$emit('created', response.data);
						_this.closeModal(null, true);

						if (_this.reload_after){
							window.location.reload();
						}
					})
					.catch(function (error){
						for (var i in error.response.data.errors) {
							if (error.response.data.errors.hasOwnProperty(i)) {
								toastr.error(error.response.data.errors[i][0]);
							}
						}
						_this.focus = true;
						_this.saving = false;
					});
			}
		},
	}
</script>
