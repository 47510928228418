<template>
	<div class="row">
		<div class="col-12 d-flex">
			<div class="mt-2 py-2 pr-4 border-bottom">
				<div class="checkbox w-auto my-auto pl-3">
					<label class="customer_checkbox">
						<input name="self_assessors_view_report" class="form-check-input" type="checkbox" value="1" v-model="self_assessors_view_report">
						<span class="checkmark"></span>
						<span class="pl-2">{{__('translation.campaign_permission_self_assessors_view_report')}}</span>
					</label>
				</div>
			</div>
		</div>

		<div class="col-12 d-flex">
			<div class="mt-2 py-2 pr-4 border-bottom">
				<div class="checkbox w-auto my-auto pl-3">
					<label class="customer_checkbox">
						<input v-bind:disabled="!self_assessors_view_report" name="allow_self_assessor_view_feedback_before_ended" class="form-check-input" type="checkbox" value="1" v-model="allow_self_assessor_view_feedback_before_ended">
						<span class="checkmark"></span>
						<span class="pl-2">{{__('translation.campaign_permission_allow_self_assessor_view_feedback_before_ended')}}</span>
					</label>
				</div>
			</div>
		</div>

		<div class="col-12 d-flex">
			<div class="mt-2 py-2 pr-4 border-bottom">
				<div class="checkbox w-auto my-auto pl-3">
					<label class="customer_checkbox">
						<input v-bind:disabled="!self_assessors_view_report" name="self_assessor_download_pdf" class="form-check-input" type="checkbox" value="1" v-model="self_assessor_download_pdf">
						<span class="checkmark"></span>
						<span class="pl-2">{{__('translation.campaign_permission_self_assessor_download_pdf')}}</span>
					</label>
				</div>
			</div>
		</div>

		<div class="col-12 d-flex">
			<div class="mt-2 py-2 pr-4 border-bottom">
				<div class="checkbox w-auto my-auto pl-3">
					<label class="customer_checkbox">
						<input name="feedback_anonymous" class="form-check-input" type="checkbox" value="1" v-model="feedback_anonymous">
						<span class="checkmark"></span>
						<span class="pl-2">{{__('translation.campaign_permission_feedback_anonymous')}}</span>
					</label>
				</div>
			</div>
		</div>

		<div class="col-12 d-flex">
			<div class="mt-2 py-2 pr-4 border-bottom">
				<div class="checkbox w-auto my-auto pl-3">
					<label class="customer_checkbox">
						<input name="campaign_messages" class="form-check-input" type="checkbox" value="1" v-model="campaign_messages">
						<span class="checkmark"></span>
						<span class="pl-2">{{__('translation.campaign_permission_campaign_messages')}}</span>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CompanyCampaignPermissions",
		props: [
			'permissions'
		],

		data (){
			return {
				allow_self_assessor_view_feedback_before_ended: true,
				feedback_anonymous: true,
				self_assessor_download_pdf: true,
				campaign_messages: true,
				self_assessors_view_report: true,
			}
		},

		beforeMount(){
			this.allow_self_assessor_view_feedback_before_ended = this.permissions.allow_self_assessor_view_feedback_before_ended;
			this.feedback_anonymous = this.permissions.feedback_anonymous;
			this.self_assessor_download_pdf = this.permissions.self_assessor_download_pdf;
			this.campaign_messages = this.permissions.campaign_messages;
			this.self_assessors_view_report = this.permissions.self_assessors_view_report;
		},

		methods: {
			'__': function(val){
				return window.Lang.exists(val) ? window.Lang.translate(val) : val;
			},
		},

		watch: {
			self_assessors_view_report(){
				if (!this.self_assessors_view_report){
					this.allow_self_assessor_view_feedback_before_ended = false;
					this.self_assessor_download_pdf = false;
				}
				else {
					this.allow_self_assessor_view_feedback_before_ended = this.permissions.allow_self_assessor_view_feedback_before_ended;
					this.self_assessor_download_pdf = this.permissions.self_assessor_download_pdf;
				}
			}
		}
	}
</script>